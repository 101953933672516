import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import type {ReactNode} from 'react'
import React, {useId} from 'react'
import styled, {css} from 'styled-components'

import {tokens} from '../../tokens'
import {focusRing} from '../focus-ring'
import {useFormControlContext} from '../form-control'
import {Inline} from '../inline'
import {Skeleton} from '../skeleton'

interface RadioButtonProps {
    /**
     * The value of the radio button.
     */
    value: string
    /**
     * The label of the radio button.
     */
    label: string
    /**
     * Option to disable the radio button. When true, prevents the user from interacting with the radio item.
     */
    disabled?: boolean
    /**
     * When true, indicates that the user must check the radio item before the owning form can be submitted.
     */
    required?: boolean
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

export interface RadioGroupProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'dir'> {
    /**
     * Radio Buttons must be wrapped inside a Radio Group to encapsulate logic. Inside can be used anything like a Stack or Inline to style the radios
     */
    children: ReactNode
    /**
     * DefaultValue of the radio buttons. It must be one of the radio values. If else leave it undefined
     */
    defaultValue?: string
    /**
     * OnValueChange is the the passing function that handles the result.
     */
    onValueChange: (value: any) => void
    /**
     * The controlled value of the radio item to check. Should be used in conjunction with `onValueChange`.
     */
    value?: string
}

export const RadioGroup = ({
    children,
    defaultValue,
    onValueChange,
    value,
    ...props
}: RadioGroupProps) => {
    const {labelId, hintTextId} = useFormControlContext()
    const ariaProps = {
        'aria-labelledby': labelId,
        'aria-describedby': hintTextId
    }

    return (
        <RadioGroupPrimitive.Root
            onValueChange={onValueChange}
            defaultValue={defaultValue}
            value={value}
            data-telescope="radio-group"
            {...ariaProps}
            {...props}
        >
            {children}
        </RadioGroupPrimitive.Root>
    )
}

RadioGroup.displayName = 'RadioGroup'

export const RadioButton = ({
    value,
    disabled,
    required,
    label,
    skeleton = false
}: RadioButtonProps) => {
    const id = useId()

    return (
        <RadioButtonWrapper alignY={'center'} data-telescope="radio-button">
            <Skeleton loading={skeleton} width={sizeRadio} height={sizeRadio}>
                <RadioGroupRadio disabled={disabled} required={required} value={value} id={id}>
                    <RadioGroupIndicator />
                </RadioGroupRadio>
            </Skeleton>

            <Label
                $disabled={disabled || false}
                htmlFor={id}
                css={skeleton ? {pointerEvents: 'none'} : {}}
            >
                <Skeleton loading={skeleton} borderRadius={4}>
                    {label}
                </Skeleton>
            </Label>
        </RadioButtonWrapper>
    )
}

RadioButton.displayName = 'RadioButton'

const RadioButtonWrapper = styled(Inline)``

const RadioGroupIndicator = styled(RadioGroupPrimitive.Indicator)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: ${tokens.circle};

    &::after {
        display: block;
        width: 8px;
        height: 8px;
        background-color: ${tokens.colorContentInteractiveSelected};
        border-radius: ${tokens.circle};
        content: '';
    }
`

const sizeRadio = '20px'

const RadioGroupRadio = styled(RadioGroupPrimitive.Item)`
    all: unset;
    flex-shrink: 0;
    box-sizing: border-box;
    width: ${sizeRadio};
    height: ${sizeRadio};
    background-color: ${tokens.colorBackgroundInteractive};
    border: ${tokens.sizeBorderThick} solid ${tokens.colorBorderInteractive};
    border-radius: ${tokens.circle};
    cursor: pointer;
    content: '';

    &:hover:enabled {
        border-color: ${tokens.colorBorderInteractiveHover};
    }

    &[aria-checked='true'] {
        border-color: transparent;
        background-color: ${tokens.colorBackgroundInteractiveSelected};
        transition: ${tokens.motionWithinSmallShort};
        transition-property: border-color, background-color;
        ${focusRing('offset')}
    }

    &[disabled] {
        background-color: ${tokens.colorBackgroundInteractiveDisabled};
        border: ${tokens.borderInteractiveDisabled};
        cursor: not-allowed;

        &[aria-checked='true'] {
            ${RadioGroupIndicator}::after {
                background-color: ${tokens.colorContentInteractiveDisabled};
            }
        }
    }
`

const Label = styled.label<{$disabled: boolean}>`
    color: ${tokens.colorContentInteractive};
    user-select: none;
    font-size: ${tokens.fontMedium};
    padding-left: ${tokens.spacing12};
    cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
    transition: color ${tokens.motionWithinSmallShort};

    &:hover:enabled {
        color: ${tokens.colorContentInteractiveHover};
    }
    ${(props) =>
        props.$disabled &&
        css`
            color: ${tokens.colorContentInteractiveDisabled};
        `}
`
