import type {FC} from 'react'
import React from 'react'

import type {InputProps} from './base-components'
import {
    ComponentWrapper,
    BaseInput,
    Postfix,
    Prefix,
    InputWrapper,
    handleInputFocus
} from './base-components'

import {useFormControlId, Label, useFormControlContext} from '../form-control/form-control'
import {Skeleton} from '../skeleton'
import {Text} from '../text'

export const Input: FC<InputProps> = ({
    className,
    postfix,
    prefix,
    isInvalid = false,
    isRequired,
    label,
    renderError,
    renderInput,
    variant,
    innerRef,
    skeleton = false,
    id: defaultId,
    ...props
}) => {
    const id = useFormControlId(defaultId)
    const {hintTextId, errorId} = useFormControlContext()
    const ariaProps = {
        id,
        'aria-label': label ? undefined : props['aria-label'],
        'aria-describedby': `${errorId ?? ''} ${hintTextId ?? ''}`.trim(),
        'aria-invalid': isInvalid,
        'aria-required': isRequired
    }
    const alternateRef = React.useRef<HTMLInputElement>(null)
    const inputRef = innerRef ?? alternateRef

    return (
        <ComponentWrapper className={className} data-telescope="input">
            {label && (
                <Label
                    // Will be fixed in https://linear.app/pleo/issue/WEB-789/replace-react-docgen-typescript-with-react-docgen
                    // @ts-expect-error htmlFor prop missing
                    htmlFor={id}
                    as="label"
                    data-input-label
                    skeleton={skeleton}
                >
                    {label}
                </Label>
            )}
            <Skeleton loading={skeleton}>
                <InputWrapper
                    isInvalid={isInvalid}
                    variant={variant}
                    disabled={props.disabled}
                    data-input-wrapper
                    onClick={(e) => handleInputFocus(e, inputRef, !!props.disabled)}
                >
                    <Prefix data-input-prefix>
                        {typeof prefix === 'string' ? (
                            <Text
                                as="span"
                                color={
                                    props.disabled
                                        ? 'colorContentInteractiveDisabled'
                                        : 'colorContentInteractiveQuiet'
                                }
                            >
                                {prefix}
                            </Text>
                        ) : (
                            prefix
                        )}
                    </Prefix>

                    {renderInput ? (
                        renderInput(ariaProps, props)
                    ) : (
                        <BaseInput {...props} {...ariaProps} ref={inputRef} data-input-base />
                    )}
                    <Postfix data-input-postfix>
                        {typeof postfix === 'string' ? (
                            <Text
                                as="span"
                                color={
                                    props.disabled
                                        ? 'colorContentInteractiveDisabled'
                                        : 'colorContentInteractiveQuiet'
                                }
                            >
                                {postfix}
                            </Text>
                        ) : (
                            postfix
                        )}
                    </Postfix>
                </InputWrapper>
            </Skeleton>

            {renderError && renderError()}
        </ComponentWrapper>
    )
}

Input.displayName = 'Input'
