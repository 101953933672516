import {withPrefix} from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'

import {tokens, Tooltip, focusRing} from '@pleo-io/telescope'
import {Bars} from '@pleo-io/telescope-icons'

const overflowWidth = 72
const paddingVertical = 72

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: ${tokens.spacing36};
    padding: ${paddingVertical}px ${tokens.spacing28};
    background-color: ${tokens.colorBackgroundStatic};
    border-radius: ${tokens.arc12};
    box-shadow: ${tokens.shadowRaise};

    @media (width >= 600px) {
        padding: ${paddingVertical}px;
    }

    @media (width >= 1450px) {
        width: calc(100% + ${overflowWidth}px);
        margin-left: -${overflowWidth / 2}px;
    }
`

const Actions = styled.div`
    position: absolute;
    right: ${tokens.spacing18};
    bottom: ${tokens.spacing18};
    display: flex;
`

// TODO: we need to discuss the color property
const Anchor = styled.a`
    display: flex;
    align-items: center;
    padding: ${tokens.spacing4} ${tokens.spacing8};
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontSmall};
    background-color: ${tokens.colorBackgroundInteractive};
    border-top: ${tokens.borderInteractiveQuiet};
    border-bottom: ${tokens.borderInteractiveQuiet};
    border-left: ${tokens.borderInteractiveQuiet};
    ${focusRing('inset')}

    &:last-child {
        border-right: ${tokens.borderInteractiveQuiet};
        border-top-right-radius: ${tokens.arc4};
        border-bottom-right-radius: ${tokens.arc4};
    }

    &:first-child {
        border-top-left-radius: ${tokens.arc4};
        border-bottom-left-radius: ${tokens.arc4};
    }

    &:visited {
        color: ${tokens.colorContentInteractive};
    }

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }

    svg:not(:only-child) {
        margin-left: ${tokens.spacing6};
    }
`

const AnchorButton = styled(Anchor).attrs({as: 'button'})<{disabled: boolean}>`
    &:disabled {
        fill: ${tokens.colorContentInteractiveDisabled};
    }
`

const StoryBookIcon = () => (
    <svg
        role="img"
        aria-hidden="true"
        aria-labelledby="6959"
        viewBox="0 0 256 319"
        width="16"
        height="16"
        preserveAspectRatio="xMidYMid"
    >
        <title id="6959">Storybook Icon</title>
        <defs>
            <path
                d="M9.872 293.324.012 30.574C-.315 21.895 6.338 14.54 15.005 14L238.494.032c8.822-.552 16.42 6.153 16.972 14.975.02.332.031.665.031.998v286.314c0 8.839-7.165 16.004-16.004 16.004-.24 0-.48-.005-.718-.016l-213.627-9.595c-8.32-.373-14.963-7.065-15.276-15.388Z"
                id="a"
            ></path>
        </defs>
        <use fill="currentColor" xlinkHref="#a"></use>
        <path
            d="m188.665 39.127 1.527-36.716L220.884 0l1.322 37.863a2.387 2.387 0 0 1-3.864 1.96l-11.835-9.325-14.013 10.63a2.387 2.387 0 0 1-3.829-2.001Zm-39.251 80.853c0 6.227 41.942 3.243 47.572-1.131 0-42.402-22.752-64.684-64.415-64.684-41.662 0-65.005 22.628-65.005 56.57 0 59.117 79.78 60.249 79.78 92.494 0 9.052-4.433 14.426-14.184 14.426-12.705 0-17.729-6.49-17.138-28.552 0-4.786-48.458-6.278-49.936 0-3.762 53.466 29.548 68.887 67.665 68.887 36.935 0 65.892-19.687 65.892-55.326 0-63.36-80.961-61.663-80.961-93.06 0-12.728 9.455-14.425 15.07-14.425 5.909 0 16.546 1.042 15.66 24.801Z"
            fill={tokens.colorContentInteractiveInverse}
        ></path>
    </svg>
)

const GithubIcon = () => (
    <svg
        role="img"
        aria-hidden="true"
        aria-labelledby="6958"
        viewBox="0 0 24 24"
        width="16"
        height="16"
    >
        <title id="6958">Github Icon</title>
        <path
            fill="currentColor"
            d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
        ></path>
    </svg>
)

const FigmaIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        viewBox="0 0 200 300"
        width="16"
        height="16"
    >
        <path
            id="path0_fill"
            d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z"
        />
        <path id="path1_fill" d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z" />
        <path id="path1_fill_1_" d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z" />
        <path id="path2_fill" d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z" />
        <path
            id="path3_fill"
            d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
        />
    </svg>
)

const StoryBookLink = ({name}: {name: string}) => (
    <Tooltip content="Open in Storybook">
        <Anchor
            href={withPrefix(`/storybook/?path=/story/${name}`)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="View storybook in new tab"
        >
            <StoryBookIcon />
        </Anchor>
    </Tooltip>
)

const metricsUrl = 'https://telescope-metrics.netlify.app/product-web/components/telescope'
const MetricsLink = ({name}: {name: string}) => (
    <Tooltip content="Show metrics">
        <Anchor
            href={`${metricsUrl}/${name}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="View metrics page in new tab"
        >
            <Bars size={16} />
        </Anchor>
    </Tooltip>
)

const githubUrl = 'https://github.com/pleo-io/telescope/tree/master/packages/web/src/components'
const GithubLink = ({name}: {name: string}) => (
    <Tooltip content="View on Github">
        <Anchor
            href={`${githubUrl}/${name}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="View source code on Github in new tab"
        >
            <GithubIcon />
        </Anchor>
    </Tooltip>
)

const figmaUrl =
    'https://www.figma.com/file/J4pl8gvfmMdbigPMiaCwrr/%E2%9D%96-Telescope-Web?type=design&mode=design&t=PS5c3FixCppIbbiz-0'
const FigmaLink = ({nodeId}: {nodeId: string}) => {
    if (!nodeId) {
        return (
            <Tooltip content="Not available in Figma">
                {/* eslint-disable-next-line */}
                <div tabIndex={0}>
                    <AnchorButton disabled>
                        <FigmaIcon />
                    </AnchorButton>
                </div>
            </Tooltip>
        )
    }

    return (
        <Tooltip content="View in Figma">
            <Anchor
                href={`${figmaUrl}&node-id=${nodeId}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="View design file in Figma in new tab"
            >
                <FigmaIcon />
            </Anchor>
        </Tooltip>
    )
}

type Props = {
    children: React.ReactNode
    figmaNodeId: string
    storyBookComponentName: string
    metricsComponentName: string
    githubComponentName: string
}
export const ComponentExample = ({
    metricsComponentName,
    githubComponentName,
    figmaNodeId,
    children,
    storyBookComponentName
}: Props) => {
    if (!metricsComponentName) {
        throw new Error('Example: `metricsComponentName` not provided')
    } else if (!storyBookComponentName) {
        throw new Error('Example: `storyBookComponentName` not provided')
    } else if (!githubComponentName) {
        throw new Error('Example: `githubComponentName` not provided')
    }

    return (
        <Wrapper>
            {children}
            <Actions>
                <MetricsLink name={metricsComponentName} />
                <StoryBookLink name={storyBookComponentName} />
                <GithubLink name={githubComponentName} />
                <FigmaLink nodeId={figmaNodeId} />
            </Actions>
        </Wrapper>
    )
}
