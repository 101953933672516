import React from 'react'
import styled, {keyframes} from 'styled-components'

import {Close} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {NakedButton} from '../button'
import {Text} from '../text'

const spin = keyframes`
     0% {
         transform: rotate(0);
         animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297)
     }

     100% {
         transform: rotate(360deg)
     }
 `

const Spinner = styled.div`
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 3px solid ${tokens.colorBackgroundStatic};
    border-top: 3px solid ${tokens.colorContentStaticQuiet};
    border-radius: ${tokens.circle};
    animation: ${spin} 1s infinite linear;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: ${tokens.spacing12};
    background-color: ${tokens.colorBackgroundStaticLoudest};
    border-radius: ${tokens.arc8};
`

const Description = styled(Text).attrs({variant: 'medium-default', as: 'p'})``

const ClearButton = styled(NakedButton)`
    display: flex;
    align-items: center;
    padding: ${tokens.spacing4} ${tokens.spacing8} ${tokens.spacing4} ${tokens.spacing12};
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontMedium};
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    border: 1px solid transparent;
    border-radius: ${tokens.arc4};
    transition: ${tokens.motionWithinSmallShort};
    transition-property: background, color, border;

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        border: 1px solid ${tokens.colorBorderInteractiveHover};
    }
`

const Clear = ({children, ...rest}: any) => (
    <ClearButton {...rest}>
        {children}
        {/* eslint-disable-next-line @pleo-io/telescope/icon-size */}
        <Close ml={4} size={20} />
    </ClearButton>
)

type FilterResultsProps = React.HTMLAttributes<HTMLDivElement>
export const FilterResults = ({children, ...rest}: FilterResultsProps) => (
    <Wrapper data-telescope="filter-results" {...rest}>
        {children}
    </Wrapper>
)

FilterResults.Text = Description
FilterResults.Spinner = Spinner
FilterResults.Clear = Clear
