import React from 'react'
import ReactMarkdown from 'react-markdown'

import {Text} from '@pleo-io/telescope'

const featureDescriptions = {
    skeleton:
        'The skeleton property is used to display a loading state for the component while data is being fetched. It provides a visual placeholder, giving users a preview of the UI structure before the actual content is loaded. This enhances the user experience by offering a more accurate representation of the final layout during data retrieval.',
    required:
        'Use the `isRequired` property to programmatically (not [visually](https://telescope.pleo.io/patterns/forms/forms.about#required-and-optional-fields)) mark a checkbox as required. This sets `aria-required="true"` on the checkbox, which communicates to assistive technology (e.g. screen readers) that the checkbox is required.'
}

type FeatureDescriptionProps = {
    feature: keyof typeof featureDescriptions
}

export const FeatureDescription = ({feature}: FeatureDescriptionProps) => (
    <Text>
        <ReactMarkdown>{featureDescriptions[feature]}</ReactMarkdown>
    </Text>
)
