export const isEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b)

export const downloadJSON = (jsonObject: any, filename = 'data.json') => {
    const jsonString = JSON.stringify(jsonObject, null, 2) // Pretty-print with 2 spaces
    const blob = new Blob([jsonString], {type: 'application/json'})
    const link = document.createElement('a')
    link.download = filename
    link.href = URL.createObjectURL(blob)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
    document.body.removeChild(link)
}

async function loadJSZip() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.min.js' // Specify the version as needed
        script.onload = () => {
            console.log('JSZip loaded successfully.')
            // @ts-ignore
            resolve(window.JSZip)
        }
        script.onerror = () => {
            console.error('Failed to load JSZip.')
            reject(new Error('Failed to load JSZip.'))
        }
        document.head.appendChild(script)
    })
}

const endpoint = 'http://localhost:1234'

export async function isServerRunning() {
    try {
        const response = await fetch(endpoint, {method: 'HEAD'})
        return response.ok
    } catch (error) {
        return false
    }
}

export async function downloadTokens(
    files: {filename: string; data: any}[],
    zipFilename = 'tokens.zip'
) {
    try {
        const JSZip = (await loadJSZip()) as any
        const zip = new JSZip()

        files.forEach((file) => {
            const jsonString = JSON.stringify(file.data, null, 2) // Convert JSON to pretty-printed string
            zip.file(`${file.filename}.json`, jsonString) // Add JSON file to zip
        })

        zip.generateAsync({type: 'blob'}).then((blob: any) => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = zipFilename
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href)
            document.body.removeChild(link)
        })
    } catch (error) {
        console.error('Error loading JSZip:', error)
    }
}

export const save = async (fileName: string, data: any) => {
    return fetch('http://localhost:1234', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({fileName, payload: data})
    })
        .then((response) => response.text())
        .then((result) =>
            console.info(`
            Server responded: ${result}
            The file ${fileName} was updated.
        `)
        )
        .catch((error) => {
            alert(`Error: ${error}. The file ${fileName} was not updated. Is the server running?`)
        })
}

export function copyObjectToClipboard(object: any) {
    const str = JSON.stringify(object)
    navigator.clipboard
        .writeText(str)
        .then(() => {
            alert(
                'Tokens copied as JSON! You can now paste the JSON into the desired file or share with team Design Systems ❤️.'
            )
        })
        .catch((err) => {
            console.error('Error copying to clipboard: ', err)
        })
}

export const formatRelationalTokenValue = (value: string) => {
    return value.replace(/[{}]/g, '')
}
