import {createContext, useContext} from 'react'

const defaultLocalisationContextValue = {
    Callout: {
        CloseButton: ''
    },
    FormControl: {
        Error: ''
    },
    Loading: {
        LoadingLabel: ''
    },
    Modal: {
        CloseButton: ''
    },
    ModalSplit: {
        BackButton: '',
        StepButton: ''
    },
    statusLabels: {
        neutral: '',
        info: '',
        positive: '',
        warning: '',
        negative: '',
        discover: ''
    },
    Textarea: {
        MaxCharacters: (_count: number) => '',
        RemainingCharacters: (_count: number) => ''
    }
}

export type LocalisationContextType = typeof defaultLocalisationContextValue

const LocalisationContext = createContext<LocalisationContextType>(defaultLocalisationContextValue)

export const useLocalisation = () => useContext(LocalisationContext)

export const LocalisationProvider = LocalisationContext.Provider
