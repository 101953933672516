import React, {useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import styled from 'styled-components'

import {Close} from '@pleo-io/telescope-icons'

import {AppContainer} from './app-container'
import Logo from './logo'
import {tokens} from './tokens'
import type {FilterType, Mode} from './types'

const OverlayToggle = styled.button<{$isOverlayVisible: boolean; $hasChanges: boolean}>`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: ${tokens.heightInteractiveRegular};
    height: ${tokens.heightInteractiveRegular};
    border: none;
    border-radius: ${tokens.borderRadiusCircle};
    box-shadow: ${tokens.shadowRaise};
    position: fixed;
    top: ${({$isOverlayVisible}) => ($isOverlayVisible ? '40px' : '8px')};
    right: ${({$isOverlayVisible}) => ($isOverlayVisible ? '40px' : '8px')};
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    transition: top 0.5s, right 0.5s, outline 0.1s;
    padding: 0;
    background-color: ${({$hasChanges}) =>
        $hasChanges ? tokens.colorBackgroundInteractiveInverse : tokens.colorBackgroundInteractive};
    color: ${({$hasChanges}) =>
        $hasChanges ? tokens.colorContentInteractiveInverse : tokens.colorContentInteractive};

    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 12345678910;

    &:hover {
        outline: 2px solid ${tokens.colorContentInteractive};
    }
`

const OverlayWrapper = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 123456789;
`

const Overlay = ({
    mode,
    setMode,
    filter,
    setFilter
}: {
    mode: Mode
    setMode: (mode: Mode) => void
    filter: FilterType
    setFilter: (filter: FilterType) => void
}) => {
    const [show, setShow] = useState(false)
    const [changes, setChanges] = useState(0)

    const onToggleVisibility = () => {
        if (show) {
            document.body.style.removeProperty('overflow')
        } else {
            document.body.style.overflow = 'hidden'
        }
        setShow(!show)
    }

    useEffect(() => {
        const handleEscKey = (e: KeyboardEvent) => e.key === 'Escape' && setShow(false)
        document.addEventListener('keydown', handleEscKey)
        return () => document.removeEventListener('keydown', handleEscKey)
    }, [setShow])

    return createPortal(
        <>
            <OverlayToggle
                title={show ? 'Close Stellaris' : 'Open Stellaris'}
                onClick={onToggleVisibility}
                $isOverlayVisible={show}
                $hasChanges={!show && changes > 0}
            >
                {show ? <Close /> : <Logo />}
            </OverlayToggle>
            {show && (
                <OverlayWrapper>
                    <AppContainer
                        onSetChanges={setChanges}
                        mode={mode}
                        filter={filter}
                        setMode={setMode}
                        setFilter={setFilter}
                    />
                </OverlayWrapper>
            )}
        </>,
        document.body
    )
}

export const Stellaris = ({viewMode}: {viewMode?: 'overlay'}) => {
    const [mode, setMode] = useState<Mode>('light')
    const [filter, setFilter] = useState<FilterType>('')

    // In case of server side rendering
    if (typeof window === 'undefined' && typeof document === 'undefined') {
        return null
    }

    if (viewMode === 'overlay') {
        return <Overlay mode={mode} setMode={setMode} filter={filter} setFilter={setFilter} />
    }

    return <AppContainer mode={mode} setMode={setMode} filter={filter} setFilter={setFilter} />
}
