import React from 'react'
import styled from 'styled-components'

import {CoreToken} from './core-token'
import type {CoreTokensAction} from './reducers'
import {tokens} from './tokens'
import type {CoreTokenType} from './types'

const backgroundColor = tokens.colorBackgroundStaticLouder

const Container = styled.div`
    border-radius: ${tokens.borderRadiusXXLarge};
    background-color: ${backgroundColor};
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    padding: ${tokens.spacingXLarge};
    display: flex;
    flex: 0 0 68px;
    align-items: center;
    position: sticky;
    justify-content: space-between;
    top: 0;
    background-color: ${backgroundColor};
`

const Title = styled.h3`
    color: ${tokens.colorContentStatic};
    margin: 0;
    padding: 0;
    font-size: ${tokens.fontSizeLarge};
`

const Content = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 0 36px;
`

const EmptyState = styled.p`
    color: ${tokens.colorContentStaticQuiet};
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CoreTokens = ({
    coreColors,
    onUpdateCoreToken
}: {
    coreColors: CoreTokenType[]
    onUpdateCoreToken: (action: CoreTokensAction) => void
}) => (
    <Container>
        <Header>
            <Title>Core colors</Title>
        </Header>
        <Content>
            {coreColors.length === 0 && <EmptyState>There are no tokens to show</EmptyState>}
            {coreColors.map((token) => (
                <CoreToken
                    key={token.name}
                    token={token}
                    modified={token.value !== token.storedValue}
                    onReset={() => onUpdateCoreToken({type: 'reset_token', name: token.name})}
                    onUpdateValue={(value: string) => {
                        onUpdateCoreToken({
                            type: 'update_token',
                            name: token.name,
                            value
                        })
                    }}
                />
            ))}
        </Content>
        <div style={{flex: '0 0 48px'}}></div>
    </Container>
)
