/**
 * @deprecated You probably want to use borderStaticLoud, borderInteractiveHover or borderInteractiveSelected depending on your use case.
 */
export const borderDark = '1px solid #333333'

/**
 * @deprecated You probably want to use borderInteractiveHover, borderInteractiveSelected or borderInteractiveSelectedHover depending on your use case.
 */
export const borderDarkest = '1px solid #000000'

/**
 * @deprecated Use borderNegative instead.
 */
export const borderError = '1px solid #e91c1c'

/**
 * @deprecated You probably want to use borderStatic or borderInteractiveQuiet instead depending on your use case.
 */
export const borderLight = '1px solid #f5f5f5'

/**
 * @deprecated We don't have a direct replacement for this token. Depending on your use case, you may want to use our static border or a transparent border instead. If no semantic border tokens fit your use case, consider defining a custom token: https://www.notion.so/pleo/Semantic-Tokens-Documentation-a2a18f98142640ccbbe363f718511787?pvs=4#3101dd0b12fc4d8e8163119c06f866f3.
 */
export const borderLightest = '1px solid #ffffff'

/**
 * @deprecated You probably want to use borderStatic or borderInteractiveQuiet instead depending on your use case.
 */
export const borderPrimary = '1px solid #ececec'

/**
 * @deprecated Use borderPositive instead.
 */
export const borderSuccess = '1px solid #85d69e'
export const sizeBorderDefault = '1px'
export const sizeBorderThick = '2px'
export const sizeBorderThickest = '3px'
