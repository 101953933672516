import React, {createContext, useContext, useEffect, useState} from 'react'

import {Checkbox, Modal, Stack, Button} from '@pleo-io/telescope'

import {useLocalStorage} from '@/hooks/use-local-storage'

const magicWord = 'dezops'

type FeatureFlags = {
    'Semantic tokens documentation': boolean
    Stellaris: boolean
}

const featureFlags: FeatureFlags = {
    'Semantic tokens documentation': false,
    Stellaris: false
}

const Context = createContext<{
    features: FeatureFlags
    toggleFeature: (name: keyof FeatureFlags) => void
}>({
    features: featureFlags,
    toggleFeature: () => {}
})
const Provider = Context.Provider
export const useFeatureToggles = () => useContext(Context)

export const FeatureTogglerProvider = ({children}: any) => {
    const [features, setFeatures] = useLocalStorage<FeatureFlags>(
        'telescope-docs-feature-toggles',
        featureFlags
    )

    const toggleFeature = (name: keyof FeatureFlags) => {
        setFeatures({...features, [name]: !features[name]})
    }

    const _features: FeatureFlags = {
        'Semantic tokens documentation': features['Semantic tokens documentation'],
        Stellaris: features['Stellaris']
    }

    return <Provider value={{features: _features, toggleFeature}}>{children}</Provider>
}

export const FeatureTogglerModal = () => {
    const {features, toggleFeature} = useFeatureToggles()
    const [isOpen, setIsOpen] = useState(false)
    const [keys, setKeys] = useState('')

    const onClose = () => {
        setIsOpen(false)
        setKeys('')
    }

    useEffect(() => {
        const handler = (e: any) => {
            const newKeys = `${keys}${e.key}`
            setKeys(newKeys)

            if (!magicWord.match(newKeys)) {
                setKeys('')
            } else if (magicWord === newKeys) {
                setIsOpen((currentIsOpen) => (currentIsOpen ? false : true))
            }
        }

        window.addEventListener('keypress', handler)
        return () => window.removeEventListener('keypress', handler)
    }, [keys])

    return (
        <Modal isOpen={isOpen} onDismiss={onClose}>
            <Modal.Close onClick={onClose} />
            <Modal.Title>Dezops Feature Flags</Modal.Title>
            <Modal.Content>
                <Stack align="center" space={12}>
                    {Object.keys(features).map((feature) => (
                        <Checkbox
                            key={feature}
                            checked={features[feature as keyof FeatureFlags]}
                            onChange={() => toggleFeature(feature as keyof FeatureFlags)}
                        >
                            {feature}
                        </Checkbox>
                    ))}
                </Stack>
            </Modal.Content>
            <Modal.Actions>
                <Button variant="primary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
