import merge from 'lodash.merge'
import React, {useEffect, useReducer} from 'react'

import {__classNameColorSchemeDark} from '@pleo-io/telescope-tokens'
import colorSchemeDark from '@pleo-io/telescope-tokens/dist/tokens/color-scheme-dark.json'
import colorSchemeLight from '@pleo-io/telescope-tokens/dist/tokens/color-scheme-light.json'
import coreColorsJson from '@pleo-io/telescope-tokens/dist/tokens/color.json'

import {App} from './app'
import {getContrastChecks} from './get-contrast-checks'
import type {
    CoreTokensAction,
    CoreTokensState,
    DarkTokensAction,
    DarkTokensReducerState,
    LightTokensAction,
    LightTokensReducerState
} from './reducers'
import {coreTokenReducer, darkTokensReducer, lightTokensReducer} from './reducers'
import type {
    ColorSchemeObject,
    CoreTokenName,
    DarkTokenName,
    FilterType,
    LightTokenName,
    Mode,
    SemanticTokenType
} from './types'
import type {CoreTokenType} from './types'
import {downloadTokens, formatRelationalTokenValue, isEqual, isServerRunning, save} from './utils'

const getInitialStateLightTokens = () => {
    const savedState = localStorage.getItem('color-scheme-light')
    const parsed = savedState ? JSON.parse(savedState) : {}
    return merge({}, colorSchemeLight, parsed)
}

const getInitialStateDarkTokens = () => {
    const savedState = localStorage.getItem('color-scheme-dark')
    const parsed = savedState ? JSON.parse(savedState) : {}
    return merge({}, colorSchemeDark, parsed)
}

const getInitialStateCoreTokens = () => {
    const savedState = localStorage.getItem('core-color-tokens')
    const parsed = savedState ? JSON.parse(savedState) : {}
    return merge({}, coreColorsJson, parsed)
}

const getLightTokensList = (
    currentColorScheme: LightTokensReducerState,
    allCoreTokens: CoreTokenType[]
): SemanticTokenType[] => {
    const keys = Object.keys(currentColorScheme).filter((t) =>
        t.match(/color/i)
    ) as LightTokenName[]
    return keys.map((name) => {
        const stored = colorSchemeLight[name]
        const current = currentColorScheme[name]
        return {
            name,
            stored,
            current,
            checks: getContrastChecks({
                stored,
                current,
                allCoreTokens,
                currentColorScheme
            })
        }
    })
}

// Note: Not happy with the complexity around supporting dark mode. There
// must be a simpler way to handle this. But I imagine we might want to
// merge the color-scheme-light.json and color-scheme-dark.json files into
// one file in the future, so didn't spend too much time on this.
const getDarkTokensList = (
    currentDarkTokens: DarkTokensReducerState,
    currentLightTokens: LightTokensReducerState,
    allCoreTokens: CoreTokenType[]
): SemanticTokenType[] => {
    const keys = Object.keys(currentLightTokens).filter((t) =>
        t.match(/color/i)
    ) as LightTokenName[]

    const storedColorScheme: ColorSchemeObject = {}
    const currentColorScheme: ColorSchemeObject = {}
    // Note, we're using the color-scheme-light.json as the base for the dark mode
    // tokens. This is because the light mode tokens have more properties than the
    // dark mode tokens. The dark mode tokens only have a value property.
    keys.forEach((name) => {
        if (!colorSchemeDark.dark[name as DarkTokenName]) {
            throw new Error(`Unknown dark token: ${name}`)
        }
        storedColorScheme[name] = {
            ...colorSchemeLight[name],
            value: colorSchemeDark.dark[name as DarkTokenName].value
        }
        currentColorScheme[name] = {
            ...currentLightTokens[name],
            value: currentDarkTokens.dark[name as DarkTokenName].value
        }
    })

    return keys.map((name) => ({
        name,
        stored: storedColorScheme[name],
        current: currentColorScheme[name],
        checks: getContrastChecks({
            stored: storedColorScheme[name],
            current: currentColorScheme[name],
            allCoreTokens,
            currentColorScheme
        })
    }))
}

const getCoreTokensList = (coreTokens: CoreTokensState) => {
    return Object.keys(coreTokens).map((name) => ({
        name: name as CoreTokenName,
        storedValue: coreColorsJson[name as CoreTokenName].value,
        ...coreTokens[name as CoreTokenName]
    }))
}

const getElement = (): HTMLElement => {
    return document.querySelector(`.${__classNameColorSchemeDark}`) || document.documentElement
}

const setProperty = (property: string, _value: string) => {
    const value = _value.match('{') ? `var(--${formatRelationalTokenValue(_value)})` : _value
    const element = getElement()
    element.style.setProperty(`--${property}`, value)
}

export const AppContainer = ({
    onSetChanges,
    mode,
    setMode,
    filter,
    setFilter
}: {
    onSetChanges?: (count: number) => void
    mode: Mode
    setMode: (mode: Mode) => void
    filter: FilterType
    setFilter: (filter: FilterType) => void
}) => {
    const [coreTokens, updateCoreTokens] = useReducer(coreTokenReducer, getInitialStateCoreTokens())
    const [lightTokensState, updateLightTokens] = useReducer(
        lightTokensReducer,
        getInitialStateLightTokens()
    )
    const [darkTokensState, updateDarkTokens] = useReducer(
        darkTokensReducer,
        getInitialStateDarkTokens()
    )

    const coreDisplayTokens = getCoreTokensList(coreTokens)
    const lightDisplayTokens = getLightTokensList(lightTokensState, coreDisplayTokens)
    const darkDisplayTokens = getDarkTokensList(
        darkTokensState,
        lightTokensState,
        coreDisplayTokens
    )
    const displayTokens = mode === 'light' ? lightDisplayTokens : darkDisplayTokens

    const lightColorsDiffs = lightDisplayTokens.filter(({name}) => {
        return !isEqual(lightTokensState[name], colorSchemeLight[name])
    })
    const darkColorsDiffs = darkDisplayTokens.filter(({name}) => {
        return !isEqual(
            darkTokensState.dark[name as DarkTokenName],
            colorSchemeDark.dark[name as DarkTokenName]
        )
    })
    const coreColorsDiffs = coreDisplayTokens.filter((t) => t.value !== t.storedValue)

    useEffect(() => {
        localStorage.setItem('core-color-tokens', JSON.stringify(coreTokens))
        localStorage.setItem('color-scheme-light', JSON.stringify(lightTokensState))
        localStorage.setItem('color-scheme-dark', JSON.stringify(darkTokensState))
    }, [coreTokens, lightTokensState, darkTokensState])

    const updateCustomProperties = () => {
        displayTokens.forEach((token) => setProperty(token.name, token.current.value))
        coreDisplayTokens.forEach((token) => setProperty(token.name, token.value))
    }

    useEffect(updateCustomProperties, [displayTokens, coreDisplayTokens, mode])

    const numLightDiffs = lightColorsDiffs.length
    const numDarkDiffs = darkColorsDiffs.length
    const numCoreDiffs = coreColorsDiffs.length
    useEffect(() => {
        onSetChanges?.(numCoreDiffs + numDarkDiffs + numLightDiffs)
    }, [onSetChanges, numCoreDiffs, numDarkDiffs, numLightDiffs])

    const onToggleFilter = (newFilter: FilterType) => {
        setFilter(newFilter === filter ? '' : newFilter)
    }

    const onSetMode = (newMode: Mode) => {
        const element = getElement()
        element.classList.toggle(__classNameColorSchemeDark, newMode === 'dark')
        setMode(newMode)
    }

    const revertChanges = () => {
        setFilter('')
        localStorage.clear()
        updateCoreTokens({type: 'reset'})
        updateLightTokens({type: 'reset'})
        updateDarkTokens({type: 'reset'})
    }

    const updateSemanticToken = (action: LightTokensAction) => {
        // The dark mode token only has a value property
        // For other properties, the light token is the source of truth
        const isUpdatingValueOnly =
            action.type === 'update_token' &&
            Object.keys(action.partialToken).length === 1 &&
            Object.keys(action.partialToken)[0] === 'value'

        if (mode === 'dark' && (isUpdatingValueOnly || action.type === 'reset_token')) {
            updateDarkTokens(action as DarkTokensAction)
        } else {
            updateLightTokens(action as LightTokensAction)
        }
    }

    const updateCoreToken = (action: CoreTokensAction) => {
        updateCoreTokens(action)

        // update css variables/custom properties
        if (action.type === 'update_token') {
            setProperty(action.name, action.value)
        } else if (action.type === 'reset_token') {
            const value = coreDisplayTokens.find((t) => t.name === action.name)?.storedValue
            setProperty(action.name, value as string)
        }
    }

    const saveChanges = async () => {
        const shouldSave = await isServerRunning()
        if (shouldSave) {
            await save('color-scheme-light.json', lightTokensState)
            await save('color-scheme-dark.json', darkTokensState)
            await save('color.json', coreTokens)
            alert('All changes saved to disk')
        } else {
            await downloadTokens([
                {filename: 'color-scheme-light', data: lightTokensState},
                {filename: 'color-scheme-dark', data: darkTokensState},
                {filename: 'color', data: coreTokens}
            ])
        }
        setFilter('')
    }

    return (
        <App
            lightColorsDiffs={lightColorsDiffs}
            coreDisplayTokens={coreDisplayTokens}
            darkColorsDiffs={darkColorsDiffs}
            coreColorsDiffs={coreColorsDiffs}
            displayTokens={displayTokens}
            filter={filter}
            mode={mode}
            onToggleFilter={onToggleFilter}
            revertChanges={revertChanges}
            saveChanges={saveChanges}
            setFilter={setFilter}
            setMode={onSetMode}
            updateCoreToken={updateCoreToken}
            updateSemanticToken={updateSemanticToken}
        />
    )
}
