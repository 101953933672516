import chroma from 'chroma-js'
import React, {useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import styled, {css} from 'styled-components'

import {
    Badge,
    Box,
    Inline,
    Input,
    NakedButton,
    Popover,
    Select,
    Stack,
    Text
} from '@pleo-io/telescope'
import {tokens} from '@pleo-io/telescope'
import {
    ChevronDown,
    ChevronUp,
    Copy,
    Help,
    Search,
    EmotionSlightlySmiling,
    Truck
} from '@pleo-io/telescope-icons'
import border from '@pleo-io/telescope-tokens/dist/border.json'
import colorSchemeDark from '@pleo-io/telescope-tokens/dist/color-scheme-dark.json'
import colorSchemeLight from '@pleo-io/telescope-tokens/dist/color-scheme-light.json'
import fontSize from '@pleo-io/telescope-tokens/dist/font-size.json'
import fontWeight from '@pleo-io/telescope-tokens/dist/font-weight.json'
import lineHeight from '@pleo-io/telescope-tokens/dist/line-height.json'
import motion from '@pleo-io/telescope-tokens/dist/motion.json'
import opacity from '@pleo-io/telescope-tokens/dist/opacity.json'
import radius from '@pleo-io/telescope-tokens/dist/radius.json'
import spacing from '@pleo-io/telescope-tokens/dist/spacing.json'
import zIndex from '@pleo-io/telescope-tokens/dist/z-index.json'

import type {ThemeName} from '@/components/theme-switcher/theme-switcher'
import {useThemeSwitcher} from '@/components/theme-switcher/theme-switcher'
import useFuse from '@/hooks/use-fuse'
import {customColorSchemeTokens} from '@/tokens'

const kebabCaseToTitleCase = (s: string) =>
    s.replace(/-/g, ' ').replace(/^\w/, (c) => c.toUpperCase())
const camelCaseToNormalCase = (string: string) =>
    string.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())

const nonColorSchemeTokens = [
    ...border.props,
    ...fontSize.props,
    ...fontWeight.props,
    ...lineHeight.props,
    // duration and easing are only intended for internal use
    ...motion.props.filter((token) => token.type !== 'duration' && token.type !== 'easing'),
    ...opacity.props,
    ...radius.props,
    ...spacing.props,
    ...zIndex.props
]

const uniqueCategories = [
    ...new Set([...nonColorSchemeTokens, ...colorSchemeLight.props].map((t) => t.category))
]

let categories = uniqueCategories
    .sort()
    .map((category) => ({value: category, label: kebabCaseToTitleCase(category)}))

const formatName = (token: Omit<TokenType, 'title'>) => {
    return camelCaseToNormalCase(token.name).trim()
}

type TokenType = {
    name: string
    title: string
    value: string | number
    rawValue: string | number
    category: string
    description?: string
    type?: string
    exampleBackgroundToken?: string
    experimental?: boolean
    deprecated?: boolean
    deprecatedComment?: string
}

const TokenWrapper = styled.div`
    width: 100%;
    padding: ${tokens.spacing24};
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc12};
`

const TokenInner = styled.div`
    display: flex;
    align-items: flex-start;
`

const StyledInput = styled(Input)`
    min-width: 180px;
`

const Header = styled(Inline).attrs({space: 12})`
    align-items: center;

    > :first-child {
        flex: 1;
    }
`

const getBackgroundToken = (token: TokenType, tokens: TokenType[]) => {
    const backgroundToken = tokens.find((t) => t.name === token.exampleBackgroundToken)

    if (!backgroundToken) {
        throw new Error(`No token with exampleBackgroundToken: ${token.exampleBackgroundToken}`)
    }

    return backgroundToken
}

const exampleBorderColorLoud = customColorSchemeTokens.colorBorderStaticLoudTokenExample
const exampleBorderColor = customColorSchemeTokens.colorBorderStaticTokenExample

const exampleBackground = css`
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    background-color: ${tokens.colorBackgroundStatic};
    /* stylelint-disable number-max-precision */
    background-image: repeating-linear-gradient(
        45deg,
        ${exampleBorderColor} 0,
        ${exampleBorderColor} 0.6px,
        ${tokens.colorBackgroundStatic} 0,
        ${tokens.colorBackgroundStatic} 50%
    );
    background-size: 5px 5px;
`

const tokenExampleSize = '70px'
const tokenExampleInnerSize = '40px'

const TokenExample = styled.div`
    flex: 0 0 ${tokenExampleSize};
    height: ${tokenExampleSize};
    border-radius: ${tokens.arc8};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${tokens.borderStatic};
    font-size: ${tokens.font2XLarge};
    color: ${tokens.colorContentStatic};
`

const TokenSpacingExample = styled.div`
    ${exampleBackground};
    display: flex;

    &::before {
        content: ' ';
        width: 1px;
        height: ${tokenExampleInnerSize};
        background-color: ${exampleBorderColorLoud};
    }

    &::after {
        content: ' ';
        width: 1px;
        height: ${tokenExampleInnerSize};
        background-color: ${exampleBorderColorLoud};
    }
`

const TokenBorderRadiusExample = styled.div`
    ${exampleBackground};
    border: ${tokens.sizeBorderDefault} solid ${exampleBorderColorLoud};
    width: ${tokenExampleInnerSize};
    height: ${tokenExampleInnerSize};
`

const TokenLineHeightExample = styled.div`
    ${exampleBackground};
`

const TokenMotionExample = styled.div`
    display: flex;
    position: relative;
    left: -20%;

    ${TokenExample}:hover & {
        left: 20%;
    }
`

const TokenVisualisation = ({token, allTokens}: {token: TokenType; allTokens: TokenType[]}) => {
    if (token.category === 'spacing') {
        return (
            <TokenExample>
                <TokenSpacingExample style={{gap: token.value.toString()}} />
            </TokenExample>
        )
    } else if (token.category === 'size') {
        if (token.type === 'border') {
            return <TokenExample style={{borderWidth: token.value}} />
        }
    } else if (token.category === 'radius') {
        return (
            <TokenExample>
                <TokenBorderRadiusExample style={{borderRadius: token.value}} />
            </TokenExample>
        )
    } else if (token.category === 'font') {
        if (token.type === 'size') {
            return <TokenExample style={{fontSize: token.value}}>Aa</TokenExample>
        } else if (token.type === 'weight') {
            return <TokenExample style={{fontWeight: token.value}}>Aa</TokenExample>
        } else if (token.type === 'line-height') {
            return (
                <TokenExample>
                    <TokenLineHeightExample style={{lineHeight: token.value}}>
                        Aa
                    </TokenLineHeightExample>
                </TokenExample>
            )
        }
    } else if (token.category === 'opacity') {
        return (
            <TokenExample style={{opacity: token.value}}>
                <EmotionSlightlySmiling size={32} />
            </TokenExample>
        )
    } else if (token.category === 'z-index') {
        return <TokenExample>{token.value}</TokenExample>
    } else if (token.category === 'border') {
        return <TokenExample style={{border: token.value}} />
    } else if (token.category === 'color') {
        if (token.type === 'border') {
            return (
                <TokenExample
                    style={{border: `${tokens.sizeBorderDefault} solid ${token.value}`}}
                />
            )
        } else if (token.type === 'shadow') {
            return <TokenExample style={{boxShadow: `0 0 ${tokens.spacing12} ${token.value}`}} />
        } else if (token.type === 'content') {
            const backgroundToken = getBackgroundToken(token, allTokens)
            return (
                <TokenExample
                    style={{
                        color: token.value,
                        backgroundColor: backgroundToken.value
                    }}
                >
                    Aa
                </TokenExample>
            )
        }
    } else if (token.category === 'shadow') {
        return <TokenExample style={{boxShadow: token.value}} />
    } else if (token.category === 'motion') {
        return (
            <TokenExample>
                <TokenMotionExample style={{transition: token.value}}>
                    <Truck />
                </TokenMotionExample>
            </TokenExample>
        )
    }

    return <TokenExample style={{backgroundColor: token.value}} />
}

const tokenInfoMarginLeft = tokens.spacing24
const TokenInfo = styled.div`
    margin-right: auto;
    margin-left: ${tokenInfoMarginLeft};
`

const TokenName = styled.div`
    color: ${tokens.colorContentStatic};
    font-weight: ${tokens.fontWeightBold};
    font-size: ${tokens.fontLarge};
`

const TokenDescription = styled.div`
    display: -webkit-box;
    max-width: 60ch;
    overflow: hidden;
    color: ${tokens.colorContentStaticQuiet};
    font-size: ${tokens.fontMedium};
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const TokenValues = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: ${tokens.spacing24};

    > *:not(:last-child) {
        margin-bottom: ${tokens.spacing2};
    }
`

const TokenValueButton = styled(NakedButton)`
    display: flex;
    align-items: center;
    color: ${tokens.colorContentInteractiveQuiet};
    font-size: ${tokens.fontMedium};
    white-space: nowrap;

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }

    svg {
        margin-left: ${tokens.spacing4};
    }
`

const TokenValue = ({token}: {token: TokenType}) => {
    const [didCopy, setDidCopy] = useState(false)
    const onCopy = () => {
        setDidCopy(true)
        setTimeout(() => setDidCopy(false), 1000)
    }
    return (
        <CopyToClipboard text={`tokens.${token.name}`} onCopy={onCopy}>
            <TokenValueButton>
                {didCopy ? 'You got it!' : 'Get code'}
                <Copy size={16} />
            </TokenValueButton>
        </CopyToClipboard>
    )
}

const TokenDetailsButton = styled(NakedButton)`
    color: ${tokens.colorContentInteractiveQuiet};
    font-size: ${tokens.fontMedium};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }

    svg {
        margin-left: ${tokens.spacing4};
    }
`

const TokenContrastText = styled(Text)<{$contrastScore: number}>`
    color: ${({$contrastScore}) => {
        if ($contrastScore > 4.5) {
            return tokens.colorContentPositive
        }

        return tokens.colorContentNegative
    }};
`

const TokenContrastButton = styled(NakedButton)`
    display: flex;
    color: ${tokens.colorContentInteractiveQuiet};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }
`

const TokenContrast = ({token, allTokens}: {token: TokenType; allTokens: TokenType[]}) => {
    const contrastToken = getBackgroundToken(token, allTokens)
    const contrastScore = Number(
        chroma.contrast(token.rawValue.toString(), contrastToken?.rawValue.toString()).toFixed(2)
    )

    return (
        <Inline space={4} alignItems="center">
            <TokenContrastText $contrastScore={contrastScore}>AA {contrastScore}</TokenContrastText>
            <Popover>
                <Popover.Trigger>
                    <TokenContrastButton>
                        <Help size={16} />
                    </TokenContrastButton>
                </Popover.Trigger>
                <Popover.Content>
                    <Popover.Arrow />
                    <Box p={12} css={{maxWidth: 400}}>
                        <Text variant="medium-default">
                            <code>{token.name}</code> when rendered on top of{' '}
                            <code>{contrastToken?.name}</code>. A{' '}
                            <a
                                target="_blank"
                                href="https://www.w3.org/TR/WCAG20-TECHS/G18.html"
                                rel="noreferrer"
                            >
                                minimum score of 4.5
                            </a>{' '}
                            is our target.
                        </Text>
                    </Box>
                </Popover.Content>
            </Popover>
        </Inline>
    )
}

const TokenDetails = styled.div`
    margin-top: ${tokens.spacing24};
    padding-top: ${tokens.spacing24};
    padding-left: calc(${tokenExampleSize} + ${tokenInfoMarginLeft});
    border-top: ${tokens.borderStatic};
`

const TokenDetailWrapper = styled(Inline).attrs({space: 4})`
    margin-bottom: ${tokens.spacing4};
`

const TokenDetailLabel = styled(Text)`
    min-width: 200px;
    color: ${tokens.colorContentStatic};
`

const TokenDetailValue = styled(Text)`
    color: ${tokens.colorContentStaticQuiet};
`

const TokenDetail = ({label, value}: {label: string; value: any}) => (
    <TokenDetailWrapper>
        <TokenDetailLabel>{label}:</TokenDetailLabel>
        <TokenDetailValue>{value}</TokenDetailValue>
    </TokenDetailWrapper>
)

const Token = ({token, allTokens}: {token: TokenType; allTokens: TokenType[]}) => {
    const [showDetails, setShowDetails] = useState(false)
    const referees = allTokens.filter(({value}) => !!`${value}`.match(`${token.name}`))

    const toggleShowDetails = () => {
        setShowDetails(!showDetails)
    }

    return (
        <TokenWrapper>
            <TokenInner>
                <TokenVisualisation token={token} allTokens={allTokens} />
                <TokenInfo>
                    <Inline space={8} marginBottom={4}>
                        <TokenName>{token.title}</TokenName>
                        {token.experimental && <Badge variant="discover">Experimental</Badge>}
                        {token.deprecated && <Badge variant="warning">Deprecated</Badge>}
                    </Inline>
                    <TokenDescription>
                        {token.description || 'No description available'}
                    </TokenDescription>
                </TokenInfo>
                <TokenValues>
                    {token.type === 'content' && (
                        <TokenContrast token={token} allTokens={allTokens} />
                    )}
                    <TokenValue token={token} />
                    <TokenDetailsButton onClick={toggleShowDetails}>
                        Details{showDetails ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                    </TokenDetailsButton>
                </TokenValues>
            </TokenInner>
            {showDetails && (
                <TokenDetails>
                    <TokenDetail label="Value" value={token.value} />
                    <TokenDetail label="Raw value" value={token.rawValue} />
                    <TokenDetail
                        label="Referenced by tokens"
                        value={
                            referees.length
                                ? referees.map((referee) => <div>{referee.name}</div>)
                                : 'None'
                        }
                    />
                    {token.deprecated && (
                        <TokenDetail label="Deprecated" value={token.deprecatedComment} />
                    )}
                </TokenDetails>
            )}
        </TokenWrapper>
    )
}

const Tokens = ({
    filteredTokens,
    allTokens
}: {
    filteredTokens: TokenType[]
    allTokens: TokenType[]
}) => (
    <Stack space={18}>
        {filteredTokens.map((token) => (
            <Token key={token.name} token={token} allTokens={allTokens} />
        ))}
    </Stack>
)

const getSchemeColors = (theme: ThemeName) => {
    if (theme === 'light') {
        return colorSchemeLight.props
    }

    return colorSchemeDark.props.map((token) => {
        const lightVersion = colorSchemeLight.props.find((t) => t.name === token.name)
        return {...lightVersion, ...token}
    })
}

export const TokensList = () => {
    const [category, setCategory] = useState<string | undefined>()
    const {theme} = useThemeSwitcher()
    const schemeColors = getSchemeColors(theme)

    const allTokens = [...schemeColors, ...nonColorSchemeTokens].map((token) => ({
        ...token,
        title: formatName(token)
    }))

    const {result, search, term} = useFuse<TokenType>(allTokens, {
        keys: [
            {name: 'name', weight: 0.8},
            {name: 'category', weight: 0.6},
            {name: 'value', weight: 0.4},
            {name: 'rawValue', weight: 0.2},
            {name: 'description', weight: 0.1}
        ]
    })

    const filteredTokens = !category ? result : result.filter((t) => t.category === category)

    return (
        <Stack space={24} stretch>
            <Header>
                <StyledInput
                    // eslint-disable-next-line @pleo-io/telescope/icon-size
                    postfix={<Search size={18} />}
                    name="search"
                    type="search"
                    onChange={(e) => search(e.target.value)}
                    value={term}
                    placeholder="Search tokens..."
                />
                <Select
                    isClearable
                    css={{width: 240}}
                    name="basic"
                    placeholder="Choose category..."
                    value={categories.find((c) => c.value === category)}
                    options={categories}
                    onChange={(value) => setCategory(value?.value)}
                />
            </Header>
            <Tokens filteredTokens={filteredTokens} allTokens={allTokens} />
        </Stack>
    )
}
