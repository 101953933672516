import {useId} from '@reach/auto-id'
import React from 'react'
import styled from 'styled-components'

import {Warning} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {useLocalisation} from '../../utils/localisation'
import type {CheckboxGroupProps} from '../checkbox'
import {CheckboxGroup} from '../checkbox'
import type {HelpPopoverProps} from '../help-popover'
import {HelpPopover, Trigger as HelpPopoverTrigger} from '../help-popover'
import {Inline} from '../inline'
import type {RadioGroupProps} from '../radio-button'
import {RadioGroup} from '../radio-button'
import {Stack} from '../stack'
import {Text} from '../text'
import {VisuallyHidden} from '../visually-hidden'

//#region Components

type FormControlProps = {
    /**
     * Any of the other FormControl components and the form element you want to render
     */
    children: React.ReactNode
    /**
     * Optional class name for extending styles
     */
    className?: string
    /**
     * Define a maximum width of the form control
     */
    maxWidth?: string
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

export const FormControl = ({
    children,
    className,
    maxWidth,
    skeleton = false,
    ...props
}: FormControlProps) => {
    const [labelId, setLabelId] = React.useState<string | undefined>(undefined)
    const [inputId, setInputId] = React.useState<string | undefined>(undefined)
    const [errorId, setErrorId] = React.useState<string | undefined>(undefined)
    const [hintTextId, setHintTextId] = React.useState<string | undefined>(undefined)

    const isGroup = React.Children.toArray(children).some((child) => {
        if (React.isValidElement(child)) {
            return isGroupType(child.type)
        }
        return false
    })

    return (
        <FormControlContext.Provider
            value={{
                labelId,
                setLabelId,
                inputId,
                setInputId,
                hintTextId,
                setHintTextId,
                errorId,
                setErrorId,
                skeleton,
                isGroup
            }}
        >
            <FormControlWrapper className={className} $maxWidth={maxWidth} {...props}>
                {children}
            </FormControlWrapper>
        </FormControlContext.Provider>
    )
}

const isGroupType = (type: any): type is typeof CheckboxGroup | typeof RadioGroup => {
    return (
        type === CheckboxGroup ||
        type === FormControl.CheckboxGroup ||
        type === RadioGroup ||
        type === FormControl.RadioGroup
    )
}

type LabelComponentProps = {
    /**
     * The label text
     */
    children: React.ReactNode
    /**
     * Optional class name for extending styles
     */
    className?: string
    /**
     * Optional ID of the input to associate with the label. Uses automatically generated ID otherwise
     */
    htmlFor?: string
}

const LabelComponent = ({children, className, htmlFor}: LabelComponentProps) => {
    const {skeleton, inputId, isGroup} = useFormControlContext()
    const labelId = __useFormControlLabelId()

    return (
        <Label
            id={labelId}
            // Will be fixed in https://linear.app/pleo/issue/WEB-789/replace-react-docgen-typescript-with-react-docgen
            // @ts-expect-error htmlFor prop missing
            htmlFor={!isGroup ? htmlFor ?? inputId : undefined}
            as={isGroup ? 'span' : 'label'}
            className={className}
            skeleton={skeleton}
        >
            {children}
        </Label>
    )
}

type HelpComponentProps = Omit<HelpPopoverProps, 'aria-label' | 'size'>
const HelpComponent = ({children, ...rest}: HelpComponentProps) => {
    const {labelId, skeleton} = useFormControlContext()

    return (
        <HelpPopover aria-labelledby={labelId} skeleton={skeleton} {...rest}>
            {children}
        </HelpPopover>
    )
}

type HintTextComponentProps = {
    /**
     * The hint text
     */
    children: React.ReactNode
    /**
     * Optional class name for extending styles
     */
    className?: string
    /**
     * Optional ID for the element. Uses automatically generated ID otherwise
     */
    id?: string
}

const HintTextComponent = ({children, className, id}: HintTextComponentProps) => {
    const {skeleton} = useFormControlContext()
    const hintTextId = __useFormControlHintTextId(id)

    return (
        <HintText id={hintTextId} className={className} skeleton={skeleton}>
            {children}
        </HintText>
    )
}

const LabelWrapper = styled.div`
    display: flex;
    flex-flow: wrap;
    gap: ${tokens.spacing8};
    align-items: center;

    ${HelpPopoverTrigger} {
        display: flex;
        border-radius: ${tokens.arc99999};
    }
`

type LabelWrapperComponentProps = {
    /**
     * Necessary if you want to render a `Help` option or other elements next to the label
     */
    children: React.ReactNode
    /**
     * Optional class name for extending styles
     */
    className?: string
}

const LabelWrapperComponent = ({children, className}: LabelWrapperComponentProps) => {
    return <LabelWrapper className={className}>{children}</LabelWrapper>
}

//#endregion Components

//#region Styles

const FormControlWrapper = styled.div<{$maxWidth?: string}>`
    width: 100%;
    max-width: ${({$maxWidth}) => $maxWidth};

    > :not(:first-child) {
        margin-top: ${tokens.spacing6};
    }
`

export const Label = styled(Text).attrs({
    variant: 'medium-default',
    color: 'colorContentStatic'
})`
    ${FormControlWrapper} & {
        align-self: start;
    }
`

const HintText = styled(Text).attrs({
    variant: 'small-subtle',
    color: 'colorContentStaticQuiet'
})`
    &:not(:first-child) {
        margin-top: ${tokens.spacing2};
    }
`

const ErrorText = styled(Text).attrs({
    variant: 'small-subtle',
    color: 'colorContentNegative',
    align: 'left'
})`
    line-height: ${tokens.lineHeight1};
`

type BaseErrorProps = {
    /**
     * The error message
     */
    children?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export const BaseError = ({children, id, ...props}: BaseErrorProps) => {
    const translations = useLocalisation()
    const errorId = __useFormControlErrorId(id)

    return (
        <Inline id={errorId} space={4} alignItems="start" {...props}>
            <Warning aria-hidden="true" size={16} color={tokens.colorContentNegative} />
            <VisuallyHidden>{translations.FormControl.Error}:</VisuallyHidden>
            <ErrorText>{children}</ErrorText>
        </Inline>
    )
}

const GroupWrapper = styled.div`
    &:not(:first-child) {
        margin-top: ${tokens.spacing16};
    }
`

const RadioGroupComponent = ({children, ...rest}: RadioGroupProps) => (
    <GroupWrapper>
        <RadioGroup {...rest}>
            <Stack space={16}>{children}</Stack>
        </RadioGroup>
    </GroupWrapper>
)

const CheckboxGroupComponent = ({children, ...rest}: CheckboxGroupProps) => (
    <GroupWrapper>
        <CheckboxGroup {...rest}>{children}</CheckboxGroup>
    </GroupWrapper>
)

//#endregion Styles

//#region Context

type FormControlContextType = {
    labelId?: string
    setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>
    inputId?: string
    setInputId: React.Dispatch<React.SetStateAction<string | undefined>>
    hintTextId?: string
    setHintTextId: React.Dispatch<React.SetStateAction<string | undefined>>
    errorId?: string
    setErrorId: React.Dispatch<React.SetStateAction<string | undefined>>
    skeleton?: boolean
    isGroup?: boolean
}

export const FormControlContext = React.createContext<FormControlContextType>({
    setLabelId: () => {},
    setInputId: () => {},
    setHintTextId: () => {},
    setErrorId: () => {}
})

export const useFormControlContext = () => React.useContext(FormControlContext)

/**
 * Generates or uses a provided ID for input components and sets it in the context
 *
 * Use only in input components. To consume the input ID in other components in a read-only way
 * (to e.g. populate htmlFor in Label), use useFormControlContext().inputId
 *
 * @param id - Optional ID to use for the input
 */
export const useFormControlId = (id?: string) => {
    const formControlContext = useFormControlContext()
    const newId = useId(id)

    React.useEffect(() => {
        if (newId) {
            formControlContext.setInputId(newId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newId])

    return newId
}

/**
 * Generates or uses a provided ID for label components and sets it in the context
 *
 * Note: This function is intended for internal usage and only exported for testing purposes.
 *
 * Use only in label components. To consume the label ID in other components in a read-only way
 * (to e.g. populate aria-labelledby in RadioGroup), use useFormControlContext().labelId
 *
 * @param id - Optional ID to use for the input
 */
export const __useFormControlLabelId = (labelId?: string) => {
    const formControlContext = useFormControlContext()
    const newLabelId = useId(labelId)

    React.useEffect(() => {
        if (newLabelId) {
            formControlContext.setLabelId(newLabelId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newLabelId])

    return newLabelId
}

/**
 * Generates or uses a provided ID for HintText (description) components and sets it in the context
 *
 * Note: This function is intended for internal usage and only exported for testing purposes.
 *
 * Use only in HintText components. To consume the hint text ID in other components in a read-only way
 * (to e.g. populate aria-describedby in Input), use useFormControlContext().hintTextId
 *
 * @param id - Optional ID to use for the input
 */
export const __useFormControlHintTextId = (hintTextId?: string) => {
    const formControlContext = useFormControlContext()
    const newHintTextId = useId(hintTextId)

    React.useEffect(() => {
        if (newHintTextId) {
            formControlContext.setHintTextId(newHintTextId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newHintTextId])

    return newHintTextId
}

/**
 * Generates or uses a provided ID for Error components and sets it in the context
 *
 * Note: This function is intended for internal usage and only exported for testing purposes.
 *
 * Use only in Error components. To consume the error ID in other components in a read-only way
 * (to e.g. populate aria-describedby/errormessage in Input), use useFormControlContext().errorId
 *
 * @param id - Optional ID to use for the input
 */
export const __useFormControlErrorId = (errorId?: string) => {
    const formControlContext = useFormControlContext()
    const newErrorId = useId(errorId)

    React.useEffect(() => {
        if (newErrorId) {
            formControlContext.setErrorId(newErrorId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newErrorId])

    return newErrorId
}

//#endregion Context

FormControl.Label = LabelComponent
FormControl.Error = BaseError
FormControl.HintText = HintTextComponent
FormControl.Help = HelpComponent
FormControl.LabelWrapper = LabelWrapperComponent
FormControl.RadioGroup = RadioGroupComponent
FormControl.CheckboxGroup = CheckboxGroupComponent
