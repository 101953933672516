import React, {useState} from 'react'
import styled from 'styled-components'

import {ArrowCircleLeft, ArrowRight, Trash} from '@pleo-io/telescope-icons'

import {Anchor, IconButton, Select, TBody, THead, Table, Td, Th, Tr} from './components'
import {statusToTextColorMapping} from './header'
import {tokens} from './tokens'
import type {
    Check,
    ColorSchemeToken,
    ContrastCheck,
    CoreTokenType,
    SemanticTokenType
} from './types'
import {formatRelationalTokenValue} from './utils'

const zIndexHeader = 2
const zIndexTableHead = 1

const Container = styled.div``

const EmptyState = styled.div`
    margin-top: ${tokens.spacingXLarge};
    color: ${tokens.colorContentStaticQuiet};
    padding: ${tokens.spacingXXLarge};
    font-size: ${tokens.fontSizeRegular};
    background-color: ${tokens.colorBackgroundStaticLouder};
    border-radius: ${tokens.borderRadiusLarge};
    text-align: center;
`

const Header = styled.div`
    display: flex;
    gap: ${tokens.spacingMedium};
    align-items: center;
    position: sticky;
    z-index: ${zIndexHeader};
    top: 0;
    background-color: ${tokens.colorBackgroundStatic};
    padding-top: ${tokens.spacingXLarge};
`

const TokenName = styled.h2`
    color: ${tokens.colorContentStatic};
    font-weight: ${tokens.fontWeightBold};
    margin: 0;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: 22px;
    display: inline-block;
    border-radius: ${tokens.borderRadiusMedium};
`

const StoredTokenValue = styled.span`
    color: ${tokens.colorContentStaticQuiet};
    text-decoration: line-through;
    font-size: ${tokens.fontSizeRegular};
`

const ExampleBase = styled.span<{$color: string; $backgroundColor: string}>`
    display: inline-flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    background-color: ${({$backgroundColor}) => $backgroundColor};
    color: ${({$color}) => $color};
    border-radius: ${tokens.borderRadiusSmall};
    font-family: Helvetica, sans-serif;
    margin-right: ${tokens.spacingMedium};
    padding: 8px;
`

const RemoveButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${tokens.colorContentInteractive};
    position: absolute;
    top: calc(50% - 16px);
    right: 0;
    height: ${tokens.heightInteractiveSmall};
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderInteractive};
    border-radius: ${tokens.borderRadiusMedium};
    width: ${tokens.heightInteractiveSmall};
    cursor: pointer;
    transition: ${tokens.transitionSpeedRegular};

    &:hover {
        border: ${tokens.borderInteractiveHover};
    }
`

const Example = ({
    tokenName,
    backgroundTokenName
}: {
    tokenName: string
    backgroundTokenName: string
}) => {
    const color = `var(--${tokenName})`
    const backgroundColor = `var(--${backgroundTokenName})`
    if (tokenName.match('colorContent')) {
        return (
            <ExampleBase $color={color} $backgroundColor={backgroundColor}>
                Aa
            </ExampleBase>
        )
    } else if (tokenName.match('colorBorder')) {
        return (
            <ExampleBase $color={color} $backgroundColor={backgroundColor}>
                ―
            </ExampleBase>
        )
    }

    return null
}

export const ContrastScore = ({
    current,
    stored
}: {
    current: ContrastCheck
    stored: ContrastCheck
}) => (
    <>
        {stored?.score !== current.score && (
            <span
                style={{
                    textDecoration: 'line-through',
                    color: statusToTextColorMapping[stored.status]
                }}
            >
                {stored?.score}
            </span>
        )}
        <span style={{color: statusToTextColorMapping[current.status]}}> {current.score}</span>
    </>
)

const TdText = styled.span`
    color: ${tokens.colorContentStatic};
    background-color: ${tokens.colorBackgroundStatic};
    padding: 3px 0;
`

const TableRow = ({
    apca,
    wcag,
    color,
    backgroundColor,
    backgroundToken,
    status,
    token,
    onRemove
}: Check & {token: SemanticTokenType; onRemove: () => void}) => {
    const [hovered, setHovered] = useState(false)
    const modified = !token.stored?.compatibleBackgroundTokens?.includes(
        `{${backgroundToken.name}}`
    )
    return (
        <Tr
            style={{
                position: 'relative',
                textDecoration: status === 'removed' ? 'line-through' : 'none'
            }}
            $modifed={modified}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Td>
                <Example tokenName={token.name} backgroundTokenName={backgroundToken.name} />
                <TdText>
                    <Anchor href={`#${backgroundToken.name}`}>{backgroundToken.name}</Anchor>
                </TdText>
                <TdText style={{marginLeft: 6, color: tokens.colorContentStaticQuiet}}>
                    <ArrowRight size={16} />{' '}
                    <Anchor href={`#${formatRelationalTokenValue(backgroundToken.value)}`}>
                        {formatRelationalTokenValue(backgroundToken.value)}
                    </Anchor>
                </TdText>
            </Td>
            <Td style={{textAlign: 'right', color: tokens.colorContentStaticQuiet}}>
                <TdText>
                    {color} on {backgroundColor}
                </TdText>
            </Td>
            <Td style={{textAlign: 'right'}}>
                <TdText style={{whiteSpace: 'nowrap'}}>
                    <ContrastScore {...apca} />
                </TdText>
            </Td>
            <Td style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                <TdText>
                    <ContrastScore {...wcag} />
                    {hovered && (
                        <RemoveButton
                            title={
                                status === 'removed'
                                    ? 'Undo removal of background token'
                                    : 'Remove background token'
                            }
                            onClick={onRemove}
                        >
                            {status === 'removed' ? (
                                <ArrowCircleLeft size={16} />
                            ) : (
                                <Trash size={16} />
                            )}
                        </RemoveButton>
                    )}
                </TdText>
            </Td>
        </Tr>
    )
}

const CompatibleBackgroundTokens = ({
    token,
    onRemove,
    onAdd
}: {
    token: SemanticTokenType
    onRemove: (name: string) => void
    onAdd: (name: string) => void
}) => {
    const hasChanges =
        token.stored?.compatibleBackgroundTokens?.length !==
        token.current.compatibleBackgroundTokens?.length

    return (
        <Table style={{paddingTop: tokens.spacingLarge}}>
            <THead
                style={{
                    position: 'sticky',
                    // Sketchy, matching height of Header
                    top: '68px',
                    zIndex: zIndexTableHead,
                    background: tokens.colorBackgroundStatic
                }}
            >
                <Tr>
                    <Th>
                        Compatible background tokens (
                        {hasChanges && (
                            <>
                                <span
                                    style={{
                                        textDecoration: hasChanges ? 'line-through' : 'initial'
                                    }}
                                >
                                    {token.stored?.compatibleBackgroundTokens?.length || 0}
                                </span>{' '}
                            </>
                        )}
                        {token.current.compatibleBackgroundTokens?.length})
                    </Th>
                    <Th style={{textAlign: 'right', width: '25%'}}>Test performed</Th>
                    <Th style={{textAlign: 'right', width: '10%'}}>APCA</Th>
                    <Th style={{textAlign: 'right', width: '10%'}}>WCAG</Th>
                </Tr>
            </THead>
            <TBody>
                {token.checks?.map((check) => {
                    const id = token.name + check.backgroundToken.name
                    return (
                        <TableRow
                            key={id}
                            {...check}
                            token={token}
                            onRemove={() =>
                                check.status === 'removed'
                                    ? onAdd(check.backgroundToken.name)
                                    : onRemove(check.backgroundToken.name)
                            }
                        />
                    )
                })}
            </TBody>
        </Table>
    )
}

const Requirements = styled.div`
    color: ${tokens.colorContentStatic};
    display: flex;
    align-items: center;
    font-size: ${tokens.fontSizeRegular};
    margin-left: auto;
    border-radius: ${tokens.borderRadiusSmall};
`

const RequirementsLabel = styled.div``

const RequirementsInput = styled.input`
    background-color: transparent;
    color: ${tokens.colorContentInteractive};
    width: 30px;
    border: none;
    font-size: ${tokens.fontSizeRegular};

    &:hover:not(:focus) {
        text-decoration: underline;
    }
`

const ContrastRequirements = ({
    token,
    onUpdate
}: {
    token: SemanticTokenType
    onUpdate: (partialContrastRequirements: {apca?: number; wcag?: number}) => void
}) => {
    const current = token.current.contrastRequirements
    const stored = token.stored?.contrastRequirements
    return (
        <Requirements>
            <RequirementsLabel>Requirements - APCA:</RequirementsLabel>
            {current?.apca !== stored?.apca && (
                <span style={{textDecoration: 'line-through'}}>{stored?.apca}</span>
            )}
            <RequirementsInput
                value={current?.apca || 'N/A'}
                onChange={(e) => onUpdate({apca: Number(e.target.value)})}
            />
            | WCAG:{' '}
            {current?.wcag !== stored?.wcag && (
                <span style={{textDecoration: 'line-through'}}>{stored?.wcag}</span>
            )}
            <RequirementsInput
                value={current?.wcag || 'N/A'}
                onChange={(e) => onUpdate({wcag: Number(e.target.value)})}
            />
        </Requirements>
    )
}

export const SemanticToken = ({
    token,
    onUpdate,
    onReset,
    allCoreTokens,
    unusedBackgroundTokens
}: {
    token: SemanticTokenType
    allCoreTokens: CoreTokenType[]
    onReset: () => void
    onUpdate: (partialToken: Partial<ColorSchemeToken>) => void
    unusedBackgroundTokens: SemanticTokenType[]
}) => {
    const coreToken = allCoreTokens.find((t) => `{${t.name}}` === token.current.value)
    const isValueChange = token.current.value !== token.stored?.value
    const sortedUnusedBackgroundTokens = unusedBackgroundTokens.sort((a, b) =>
        a.name.localeCompare(b.name)
    )

    const onRemoveCompatibleBackground = (backgroundTokenName: string) => {
        onUpdate({
            compatibleBackgroundTokens: token.current.compatibleBackgroundTokens?.filter(
                (name) => name !== `{${backgroundTokenName}}`
            )
        })
    }

    const onAddCompatibleBackground = (backgroundTokenName: string) => {
        onUpdate({
            compatibleBackgroundTokens: [
                ...(token.current.compatibleBackgroundTokens || []),
                `{${backgroundTokenName}}`
            ]
        })
    }

    const onUpdateContrastRequirements = (partialContrastRequirements: {
        apca?: number
        wcag?: number
    }) => {
        onUpdate({
            contrastRequirements: {
                ...token.current.contrastRequirements,
                ...partialContrastRequirements
            }
        })
    }

    return (
        <Container>
            <Header>
                <TokenName id={token.name} title={token.current.description}>
                    {token.name}
                </TokenName>
                {isValueChange && (
                    <StoredTokenValue>
                        {token.stored
                            ? formatRelationalTokenValue(token.stored.value)
                            : 'No original token'}
                    </StoredTokenValue>
                )}
                {coreToken ? (
                    <Select
                        title="Change core token mapping"
                        value={coreToken.name}
                        onChange={(e) => onUpdate({value: `{${e.target.value}}`})}
                    >
                        {allCoreTokens.map((token) => (
                            <option key={token.name} value={token.name}>
                                {token.name}
                            </option>
                        ))}
                    </Select>
                ) : (
                    <span>{token.current.value}</span>
                )}
                {isValueChange && (
                    <IconButton title="Revert change" onClick={onReset}>{`⌫`}</IconButton>
                )}
                <ContrastRequirements token={token} onUpdate={onUpdateContrastRequirements} />
            </Header>
            {!!token.checks?.length ? (
                <CompatibleBackgroundTokens
                    token={token}
                    onRemove={onRemoveCompatibleBackground}
                    onAdd={onAddCompatibleBackground}
                />
            ) : (
                <EmptyState>No compatible background tokens has been defined</EmptyState>
            )}
            <Select
                style={{width: 188, marginTop: 24}}
                value="default"
                onChange={(e) => onAddCompatibleBackground(e.target.value)}
            >
                <option value="default" disabled>
                    + Add background token
                </option>
                {sortedUnusedBackgroundTokens.map((token) => (
                    <option key={token.name} value={token.name}>
                        {token.name}
                    </option>
                ))}
            </Select>
        </Container>
    )
}
