import styled from 'styled-components';
import { space } from 'styled-system';
import React from 'react';

const Icon = styled.svg.withConfig({
  shouldForwardProp: prop => !space.propNames.includes(prop)
})`
    flex-shrink: 0;
    vertical-align: middle;
    ${space}
`;
Icon.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const AddApp = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22.75 9.25V13h2.5V9.25H29v-2.5h-3.75V3h-2.5v3.75H19v2.5h3.75zM4.75 11A4.25 4.25 0 019 6.75h7v2.5H9A1.75 1.75 0 007.25 11v12c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0022.75 23v-7h2.5v7A4.25 4.25 0 0121 27.25H9A4.25 4.25 0 014.75 23V11z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.25 3.25H9v1.5h2.25V7h1.5V4.75H15v-1.5h-2.25V1h-1.5v2.25zm-9 2.25A2.25 2.25 0 014.5 3.25H7v1.5H4.5a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 00.75-.75V9h1.5v2.5a2.25 2.25 0 01-2.25 2.25h-6a2.25 2.25 0 01-2.25-2.25v-6z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17 7v3h2V7h3V5h-3V2h-2v3h-3v2h3zM3 8a3 3 0 013-3h6v2H6a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-6h2v6a3 3 0 01-3 3H6a3 3 0 01-3-3V8z"
    }));
  })());
};
AddApp.displayName = 'AddApp';
AddApp.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Archive = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3 4.75h26V14h-1.75v8.75A4.25 4.25 0 0123 27H9a4.25 4.25 0 01-4.25-4.25V14H3V4.75zM7.25 14v8.75c0 .966.784 1.75 1.75 1.75h14a1.75 1.75 0 001.75-1.75V14H7.25zm19.25-2.5h-21V7.25h21v4.25zM20 19.25h-8v-2.5h8v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.25 2.25h13.5v5h-1v4.25a2.25 2.25 0 01-2.25 2.25h-7a2.25 2.25 0 01-2.25-2.25V7.25h-1v-5zm2.5 5v4.25c0 .414.336.75.75.75h7a.75.75 0 00.75-.75V7.25h-8.5zm9.5-1.5H2.75v-2h10.5v2zM10 10.25H6v-1.5h4v1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 3h20v7.5h-1V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-7.5H2V3zm3 7.5V18a1 1 0 001 1h12a1 1 0 001-1v-7.5H5zm-1-2V5h16v3.5H4zM15 15H9v-2h6v2z"
    }));
  })());
};
Archive.displayName = 'Archive';
Archive.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowCircleLeft = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.018 9.25l3.366 3.366-1.768 1.768L10.232 8l6.384-6.384 1.768 1.768-3.366 3.366H16c2.254 0 4.457.676 6.293 1.94 1.836 1.263 3.215 3.054 3.924 5.123a9.8 9.8 0 010 6.374c-.71 2.069-2.088 3.86-3.924 5.124A11.11 11.11 0 0116 27.25a11.11 11.11 0 01-6.293-1.94c-1.836-1.263-3.215-3.054-3.924-5.123a9.8 9.8 0 010-6.374l2.364.811a7.3 7.3 0 000 4.752c.53 1.545 1.568 2.905 2.978 3.875A8.61 8.61 0 0016 24.75a8.61 8.61 0 004.875-1.498c1.41-.97 2.448-2.33 2.978-3.876a7.3 7.3 0 000-4.752c-.53-1.546-1.568-2.905-2.978-3.876A8.61 8.61 0 0016 9.25h-.982z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.81 4.75l1.22 1.22-1.06 1.06L4.94 4 7.97.97l1.06 1.06-1.22 1.22H8a5.25 5.25 0 11-4.993 3.628l1.427.463A3.75 3.75 0 108 4.75h-.19z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11.414 7l2.293 2.293-1.414 1.414L7.586 6l4.707-4.707 1.414 1.414L11.414 5H12c1.69 0 3.335.518 4.702 1.48a7.768 7.768 0 012.906 3.875 7.52 7.52 0 010 4.79 7.768 7.768 0 01-2.906 3.875A8.175 8.175 0 0112 20.5a8.175 8.175 0 01-4.702-1.48 7.768 7.768 0 01-2.906-3.875 7.52 7.52 0 010-4.79l1.896.636a5.52 5.52 0 000 3.518 5.768 5.768 0 002.16 2.875A6.175 6.175 0 0012 18.5a6.175 6.175 0 003.551-1.116 5.768 5.768 0 002.161-2.875 5.52 5.52 0 000-3.518 5.768 5.768 0 00-2.16-2.875A6.175 6.175 0 0012 7h-.586z"
    }));
  })());
};
ArrowCircleLeft.displayName = 'ArrowCircleLeft';
ArrowCircleLeft.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowCircleRight = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.982 9.25l-3.366 3.366 1.768 1.768L21.768 8l-6.384-6.384-1.768 1.768 3.366 3.366H16a11.11 11.11 0 00-6.293 1.94c-1.836 1.263-3.215 3.054-3.924 5.123a9.8 9.8 0 000 6.374c.71 2.069 2.088 3.86 3.924 5.124A11.11 11.11 0 0016 27.25a11.11 11.11 0 006.293-1.94c1.836-1.263 3.215-3.054 3.924-5.123a9.8 9.8 0 000-6.374l-2.364.811a7.3 7.3 0 010 4.752c-.53 1.545-1.568 2.905-2.978 3.875A8.61 8.61 0 0116 24.75a8.61 8.61 0 01-4.875-1.498c-1.41-.97-2.447-2.33-2.978-3.876a7.3 7.3 0 010-4.752c.53-1.546 1.568-2.905 2.978-3.876A8.61 8.61 0 0116 9.25h.982z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.19 4.75L6.97 5.97l1.06 1.06L11.06 4 8.03.97 6.97 2.03l1.22 1.22H8a5.25 5.25 0 104.993 3.628l-1.427.463A3.75 3.75 0 118 4.75h.19z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.586 7l-2.293 2.293 1.414 1.414L16.414 6l-4.707-4.707-1.414 1.414L12.586 5H12c-1.69 0-3.336.518-4.702 1.48a7.768 7.768 0 00-2.906 3.875 7.52 7.52 0 000 4.79 7.768 7.768 0 002.906 3.875A8.175 8.175 0 0012 20.5c1.69 0 3.335-.518 4.702-1.48a7.768 7.768 0 002.906-3.875 7.52 7.52 0 000-4.79l-1.896.636a5.52 5.52 0 010 3.518 5.768 5.768 0 01-2.16 2.875A6.175 6.175 0 0112 18.5a6.175 6.175 0 01-3.551-1.116 5.768 5.768 0 01-2.161-2.875 5.52 5.52 0 010-3.518 5.768 5.768 0 012.16-2.875A6.175 6.175 0 0112 7h.586z"
    }));
  })());
};
ArrowCircleRight.displayName = 'ArrowCircleRight';
ArrowCircleRight.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowDiagonalDown = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M20.982 22.75L7.116 8.884l1.768-1.768L22.75 20.982V14h2.5v11.25H14v-2.5h6.982z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.19 11.25L3.47 4.53l1.06-1.06 6.72 6.72V7h1.5v5.75H7v-1.5h3.19z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 19v-8h-2v4.586L6.707 5.293 5.293 6.707 15.586 17H11v2h8z"
    }));
  })());
};
ArrowDiagonalDown.displayName = 'ArrowDiagonalDown';
ArrowDiagonalDown.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowDiagonalUp = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 6.75h13.25V20h-2.5v-8.982L8.884 24.884l-1.768-1.768L20.982 9.25H12v-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6 3.25h6.75V10h-1.5V5.81l-6.72 6.72-1.06-1.06 6.72-6.72H6v-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 5v8h-2V8.414L6.707 18.707l-1.414-1.414L15.586 7H11V5h8z"
    }));
  })());
};
ArrowDiagonalUp.displayName = 'ArrowDiagonalUp';
ArrowDiagonalUp.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowDown = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17.25 22.982l7.866-7.866 1.768 1.768L16 27.768 5.116 16.884l1.768-1.768 7.866 7.866V6h2.5v16.982z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.75 11.19l3.72-3.72 1.06 1.06L8 14.06 2.47 8.53l1.06-1.06 3.72 3.72V3h1.5v8.19z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13 17.586l6.293-6.293 1.414 1.414L12 21.414l-8.707-8.707 1.414-1.414L11 17.586V4h2v13.586z"
    }));
  })());
};
ArrowDown.displayName = 'ArrowDown';
ArrowDown.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowLeft = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.018 17.25l7.866 7.866-1.768 1.768L4.232 16 15.116 5.116l1.768 1.768-7.866 7.866H26v2.5H9.018z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.81 8.75l3.72 3.72-1.06 1.06L1.94 8l5.53-5.53 1.06 1.06-3.72 3.72H13v1.5H4.81z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6.414 13l6.293 6.293-1.414 1.414L2.586 12l8.707-8.707 1.414 1.414L6.414 11H20v2H6.414z"
    }));
  })());
};
ArrowLeft.displayName = 'ArrowLeft';
ArrowLeft.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowLeftRight = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.232 22l7.884-7.884 1.768 1.768-4.866 4.866H26v2.5H9.018l4.866 4.866-1.768 1.768L4.232 22zm18.75-10.75H6v-2.5h16.982l-4.866-4.866 1.768-1.768L27.768 10l-7.884 7.884-1.768-1.768 4.866-4.866z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.94 11l4.03-4.03 1.06 1.06-2.22 2.22H13v1.5H4.81l2.22 2.22-1.06 1.06L1.94 11zm9.25-5.25H3v-1.5h8.19L8.97 2.03 10.03.97 14.06 5l-4.03 4.03-1.06-1.06 2.22-2.22z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.286 17l5.707-5.707 1.415 1.414L7.115 16H20v2H7.115l3.293 3.293-1.415 1.414L3.286 17zm14.3-9H4V6h13.586l-3.293-3.293 1.414-1.414L21.414 7l-5.707 5.707-1.414-1.414L17.586 8z"
    }));
  })());
};
ArrowLeftRight.displayName = 'ArrowLeftRight';
ArrowLeftRight.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowRight = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.884 5.116L27.768 16 16.884 26.884l-1.768-1.768 7.866-7.866H6v-2.5h16.982l-7.866-7.866 1.768-1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.53 2.47L14.06 8l-5.53 5.53-1.06-1.06 3.72-3.72H3v-1.5h8.19L7.47 3.53l1.06-1.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.707 3.293L21.414 12l-8.707 8.707-1.414-1.414L17.586 13H4v-2h13.586l-6.293-6.293 1.414-1.414z"
    }));
  })());
};
ArrowRight.displayName = 'ArrowRight';
ArrowRight.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowUp = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 9.018V26h2.5V9.018l7.866 7.866 1.768-1.768L16 4.232 5.116 15.116l1.768 1.768 7.866-7.866z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 4.81V13h1.5V4.81l3.72 3.72 1.06-1.06L8 1.94 2.47 7.47l1.06 1.06 3.72-3.72z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 6.414V20h2V6.414l6.293 6.293 1.414-1.414L12 2.586l-8.707 8.707 1.414 1.414L11 6.414z"
    }));
  })());
};
ArrowUp.displayName = 'ArrowUp';
ArrowUp.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ArrowUpDown = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10 4.232l7.884 7.884-1.768 1.768-4.866-4.866V26h-2.5V9.018l-4.866 4.866-1.768-1.768L10 4.232zm10.75 18.75V6h2.5v16.982l4.866-4.866 1.768 1.768L22 27.768l-7.884-7.884 1.768-1.768 4.866 4.866z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 1.94l4.03 4.03-1.06 1.06-2.22-2.22V13h-1.5V4.81L2.03 7.03.97 5.97 5 1.94zm5.25 9.25V3h1.5v8.19l2.22-2.22 1.06 1.06L11 14.06l-4.03-4.03 1.06-1.06 2.22 2.22z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 3.286l5.707 5.707-1.414 1.414L8 7.114V20H6V7.114l-3.293 3.293-1.414-1.414L7 3.286zm9 14.3V4h2v13.586l3.293-3.293 1.414 1.414L17 21.414l-5.707-5.707 1.414-1.414L16 17.586z"
    }));
  })());
};
ArrowUpDown.displayName = 'ArrowUpDown';
ArrowUpDown.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Attention = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M20 3.25A1.75 1.75 0 0018.25 5v6.75h3.5V5A1.75 1.75 0 0020 3.25zm4.25 8.507V5a4.25 4.25 0 00-8.5 0v2.126A4.235 4.235 0 0014 6.75c-1.444 0-2.72.72-3.488 1.821A4.25 4.25 0 003.75 12v6c0 6.765 5.485 12.25 12.25 12.25S28.25 24.765 28.25 18v-2a4.25 4.25 0 00-4-4.243zm-18 6.117V18c0 5.385 4.365 9.75 9.75 9.75s9.75-4.365 9.75-9.75v-2A1.75 1.75 0 0024 14.25h-7.614c.164.739.47 1.424.887 2.025A5.742 5.742 0 0022 18.75v2.5a8.228 8.228 0 01-5.945-2.53 4.245 4.245 0 01-5.544-1.292A4.23 4.23 0 018 18.25a4.234 4.234 0 01-1.75-.376zm6-2.874a1.75 1.75 0 002.348 1.646A8.22 8.22 0 0113.75 13v-1.25h2V11a1.75 1.75 0 10-3.5 0v4zm-2.5-3a1.75 1.75 0 10-3.5 0v2a1.75 1.75 0 103.5 0v-2z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10 1.75a.75.75 0 00-.75.75v3.25h1.5V2.5a.75.75 0 00-.75-.75zm2.25 4.014V2.5a2.25 2.25 0 00-4.5 0v.878a2.246 2.246 0 00-2.523.736A2.25 2.25 0 001.75 6v3a6.25 6.25 0 1012.5 0V8a2.25 2.25 0 00-2-2.236zM3.252 9.123A4.75 4.75 0 0012.75 9V8a.75.75 0 00-.75-.75H8.353a2.746 2.746 0 002.647 2v1.5a4.238 4.238 0 01-2.996-1.236 2.246 2.246 0 01-2.776-.628 2.24 2.24 0 01-1.976.237zM6.25 7.5a.75.75 0 00.872.74A4.237 4.237 0 016.75 6.5v-.75h1V5.5a.75.75 0 00-1.5 0v2zM4.75 6a.75.75 0 00-1.5 0v1a.75.75 0 001.5 0V6z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.5 2a1 1 0 00-1 1v6h2V3a1 1 0 00-1-1zm3 7.401V3a3 3 0 10-6 0v2.764a3 3 0 00-4.667.861A3 3 0 003 9v5.5a8.5 8.5 0 0017 0V12c0-1.11-.603-2.08-1.5-2.599zM5 13.83v.671a6.5 6.5 0 1013 0V12a1 1 0 00-1-1h-4.768c.169.353.428.685.774.979C13.723 12.589 14.783 13 16 13v2c-1.242 0-2.414-.314-3.389-.869a3 3 0 01-4.778-.756A2.987 2.987 0 016 14c-.35 0-.687-.06-1-.17zm6.051-.994C10.402 12.047 10 11.075 10 10V9h1.5V8a1 1 0 10-2 0v4a1 1 0 001.551.835zM5 11a1 1 0 102 0V9a1 1 0 00-2 0v2z"
    }));
  })());
};
Attention.displayName = 'Attention';
Attention.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Bag = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 5.25a.75.75 0 00-.75.75v1.75h9.5V6a.75.75 0 00-.75-.75h-8zm11.25 2.5V6A3.25 3.25 0 0020 2.75h-8A3.25 3.25 0 008.75 6v1.75H6A4.25 4.25 0 001.75 12v12A4.25 4.25 0 006 28.25h20A4.25 4.25 0 0030.25 24V12A4.25 4.25 0 0026 7.75h-2.75zM6 10.25A1.75 1.75 0 004.25 12v12c0 .966.784 1.75 1.75 1.75h20A1.75 1.75 0 0027.75 24V12A1.75 1.75 0 0026 10.25H6z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6 2.5a.25.25 0 00-.25.25v.75h4.5v-.75A.25.25 0 0010 2.5H6zm5.75 1v-.75A1.75 1.75 0 0010 1H6a1.75 1.75 0 00-1.75 1.75v.75H3A2.25 2.25 0 00.75 5.75v6A2.25 2.25 0 003 14h10a2.25 2.25 0 002.25-2.25v-6A2.25 2.25 0 0013 3.5h-1.25zM3 5a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h10a.75.75 0 00.75-.75v-6A.75.75 0 0013 5H3z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9.5 4a.5.5 0 00-.5.5V6h6V4.5a.5.5 0 00-.5-.5h-5zM17 6V4.5A2.5 2.5 0 0014.5 2h-5A2.5 2.5 0 007 4.5V6H5a3 3 0 00-3 3v9a3 3 0 003 3h14a3 3 0 003-3V9a3 3 0 00-3-3h-2zM5 8a1 1 0 00-1 1v9a1 1 0 001 1h14a1 1 0 001-1V9a1 1 0 00-1-1H5z"
    }));
  })());
};
Bag.displayName = 'Bag';
Bag.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Bank = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 3.627l12.25 5.568V28.25H3.75V9.195L16 3.627zm-9.75 7.178v1.945h19.5v-1.945L16 6.373l-9.75 4.432zm19.5 4.445h-2.5v4.5h2.5v-4.5zm0 7H6.25v3.5h19.5v-3.5zm-19.5-2.5h2.5v-4.5h-2.5v4.5zm5-4.5v4.5h3.5v-4.5h-3.5zm6 0v4.5h3.5v-4.5h-3.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 1.676l6.25 2.841v9.733H1.75V4.517L8 1.677zM3.25 5.483v.767h9.5v-.767L8 3.323l-4.75 2.16zm9.5 2.267h-1v2h1v-2zm0 3.5h-9.5v1.5h9.5v-1.5zm-9.5-1.5h1v-2h-1v2zm2.5-2v2h1.5v-2h-1.5zm3 0v2h1.5v-2h-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 2.382l9 4.5V21H3V6.882l9-4.5zM5 8.118V9.5h14V8.118l-7-3.5-7 3.5zM19 11.5h-1.5V15H19v-3.5zm0 5.5H5v2h14v-2zM5 15h1.5v-3.5H5V15zm3.5-3.5V15H11v-3.5H8.5zm4.5 0V15h2.5v-3.5H13z"
    }));
  })());
};
Bank.displayName = 'Bank';
Bank.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const BankPlus = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 3.627l12.25 5.568V19h-2.5v-3.75h-2.5v7h-17v3.5H19v2.5H3.75V9.195L16 3.627zM6.25 19.75h2.5v-4.5h-2.5v4.5zm0-7h19.5v-1.945L16 6.373l-9.75 4.432v1.945zm5 2.5v4.5h3.5v-4.5h-3.5zm6 0v4.5h3.5v-4.5h-3.5zm8.5 13V32h2.5v-3.75H32v-2.5h-3.75V22h-2.5v3.75H22v2.5h3.75z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 1.676l6.25 2.841V9h-1.5V7.75h-1v3.5h-8.5v1.5H9v1.5H1.75V4.517L8 1.677zM3.25 9.75h1v-2h-1v2zm0-3.5h9.5v-.767L8 3.323l-4.75 2.16v.767zm2.5 1.5v2h1.5v-2h-1.5zm3 0v2h1.5v-2h-1.5zm4 6.5V16h1.5v-1.75H16v-1.5h-1.75V11h-1.5v1.75H11v1.5h1.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 2.382l9 4.5V14h-2v-2.5h-1.5V17H5v2h9v2H3V6.882l9-4.5zM5 15h1.5v-3.5H5V15zm0-5.5h14V8.118l-7-3.5-7 3.5V9.5zm3.5 2V15H11v-3.5H8.5zm4.5 0V15h2.5v-3.5H13zm6 9.5v3h2v-3h3v-2h-3v-3h-2v3h-3v2h3z"
    }));
  })());
};
BankPlus.displayName = 'BankPlus';
BankPlus.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Bars = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.25 6v20h-2.5V6h2.5zm12 5v15h-2.5V11h2.5zm-6 5v10h-2.5V16h2.5zm-12 2v8h-2.5v-8h2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.75 3v10h-1.5V3h1.5zm3.5 4v6h-1.5V7h1.5zm-7 2v4h-1.5V9h1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.75 19V5h-2v14h2zm-4.5 0v-5h-2v5h2zm9-8v8h-2v-8h2zm4.5 8V8h-2v11h2z"
    }));
  })());
};
Bars.displayName = 'Bars';
Bars.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Bell = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M16 28.5a4.002 4.002 0 003.874-3h-7.748c.444 1.725 2.01 3 3.874 3z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 5.25A6.75 6.75 0 009.25 12v8.75h13.5V12A6.75 6.75 0 0016 5.25zm9.25 15.5V12a9.25 9.25 0 00-18.5 0v8.75H4v2.5h24v-2.5h-2.75z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M8 14.5A2 2 0 009.937 13H6.063A2 2 0 008 14.5z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 2.75A3.25 3.25 0 004.75 6v4.25h6.5V6A3.25 3.25 0 008 2.75zm4.75 7.5V6a4.75 4.75 0 10-9.5 0v4.25H2v1.5h12v-1.5h-1.25z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 4a5 5 0 00-5 5v6h10V9a5 5 0 00-5-5zm7 11V9A7 7 0 105 9v6H3v2h18v-2h-2z"
    }), React.createElement("path", {
      d: "M9.5 19c.236 1.141 1.266 2 2.5 2s2.264-.859 2.5-2h-5z"
    }));
  })());
};
Bell.displayName = 'Bell';
Bell.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const BellOff = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M25.25 12v7.612l-2.5-2.5V12a6.75 6.75 0 00-10.634-5.521L10.33 4.692A9.21 9.21 0 0116 2.75 9.25 9.25 0 0125.25 12zM3.616 4.884l1.685-1.685 22.5 22.5-1.685 1.685-4.134-4.134H4v-2.5h2.75V12c0-1.183.222-2.315.627-3.355l-3.76-3.761zM19.482 20.75H9.25V12c0-.461.046-.912.135-1.348L19.482 20.75zm.392 4.75a4.002 4.002 0 01-7.748 0h7.748z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.294 4.204l1.41-1.41 16 16-1.41 1.41-2.207-2.208H3.001v-2h2v-6a6.97 6.97 0 01.812-3.274L3.294 4.204zm15.707 5.792v4.252l-2-2V9.996a5 5 0 00-6.884-4.632L8.62 3.866a7 7 0 0110.382 6.13zM7.322 8.231a4.989 4.989 0 00-.32 1.765v6h8.085L7.322 8.231zM12 22c-1.234 0-2.264-.859-2.5-2h5c-.236 1.141-1.266 2-2.5 2z"
    }));
  })());
};
BellOff.displayName = 'BellOff';
BellOff.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const BikeCourier = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.384 3.616L9.5 2.732l-.884.884-4 4-.884.884.884.884 3.5 3.5.884.884.884-.884 4-4L14.768 8l-.884-.884-3.5-3.5zM9 10.232L7.268 8.5 9.5 6.268 11.232 8 9 10.232zM23 7a3 3 0 11-6 0 3 3 0 016 0zm-7 2.232l.884.884 3.414 3.414c.14.141.332.22.53.22H24v2.5h-3.172a3.25 3.25 0 01-2.298-.952L16 12.768 13.768 15l3.116 3.116.366.366V24h-2.5v-4.482l-2.927-2.927a2.25 2.25 0 010-3.182l3.293-3.293.884-.884zM7.5 20.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM2.25 23a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm19.5 0a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zm2.75-5.25a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.03 1.47L4.5.94l-.53.53-2 2-.53.53.53.53 2 2 .53.53.53-.53 2-2L7.56 4l-.53-.53-2-2zM4.5 4.94L3.56 4l.94-.94.94.94-.94.94zm7-1.44a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM8 4.44l.53.53 1.707 1.707a.25.25 0 00.177.073H12v1.5h-1.586a1.75 1.75 0 01-1.237-.513L8 6.561l-.94.939 1.47 1.47.22.22V12h-1.5V9.81L5.823 8.385a1.25 1.25 0 010-1.768L7.47 4.97 8 4.44zm-4.5 5.81a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM.75 11.5a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zm10.5 0a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zm1.25-2.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.5 2.086L11.414 6 7 10.414 3.086 6.5 7.5 2.086zM5.914 6.5L7 7.586 8.586 6 7.5 4.914 5.914 6.5zM13 5a2 2 0 114 0 2 2 0 01-4 0zm-1 1.586l3.121 3.121a1 1 0 00.707.293H18v2h-2.172a3 3 0 01-2.12-.879L12 9.414 10.414 11 13 13.586V18h-2v-3.586l-2-2a2 2 0 010-2.828l3-3zM6 15a2 2 0 100 4 2 2 0 000-4zm-4 2a4 4 0 118 0 4 4 0 01-8 0zm16-2a2 2 0 100 4 2 2 0 000-4zm-4 2a4 4 0 118 0 4 4 0 01-8 0z"
    }));
  })());
};
BikeCourier.displayName = 'BikeCourier';
BikeCourier.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Bold = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M7 28.009V4h9c1.719 0 3.222.274 4.36.82 1.146.54 2.002 1.278 2.567 2.216.573.938.86 2.001.86 3.189 0 .977-.187 1.813-.558 2.509a4.716 4.716 0 01-1.498 1.676 6.31 6.31 0 01-2.102.926v.235c.843.046 1.653.308 2.427.785.782.469 1.42 1.133 1.916 1.993.496.86.743 1.9.743 3.118 0 1.243-.298 2.36-.894 3.353-.596.985-1.494 1.762-2.694 2.333-1.2.57-3.307.856-5.127.856H7zm4.25-3.759H16c1.564 0 2.603-.176 3.292-.778.697-.61 1.045-1.391 1.045-2.345 0-.71-.174-1.352-.523-1.922a3.601 3.601 0 00-1.486-1.36A5.033 5.033 0 0016 17.25h-4.75v7zm0-10h4.25c.743 0 1.404-.176 2-.45.596-.28 1.114-.676 1.455-1.184.348-.515.523-1.125.523-1.828 0-.93-.326-1.686-.976-2.288-.643-.602-1.732-1-3.002-1h-4.25v6.75z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M4 14V2h3.998c.775 0 1.526.137 2.039.41.516.27.902.639 1.157 1.108.258.468.387 1 .387 1.593 0 .489-.084.907-.251 1.254a2.293 2.293 0 01-.676.838c-.282.215-.598.37-.947.463v.117c.38.024.745.154 1.094.393.353.234.64.566.864.996.223.43.335.95.335 1.558a3.46 3.46 0 01-.403 1.676c-.269.492-.674.881-1.215 1.166-.54.285-1.312.428-2.132.428H4zm1.866-1.816h2.132c.705 0 1.246-.15 1.557-.452.314-.304.471-.695.471-1.171 0-.356-.078-.676-.235-.961a1.7 1.7 0 00-.67-.68c-.29-.168-.636-.252-1.037-.252H5.866v3.516zm0-5.08h1.998c.335 0 .637-.069.906-.206.268-.14.48-.337.633-.591.157-.258.236-.563.236-.914 0-.465-.147-.848-.44-1.149-.29-.3-.72-.451-1.293-.451h-2.04v3.31z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M5 21V3h7c1.33 0 2.369.09 3.25.5a4.615 4.615 0 012 1.75c.444.701.75 1.612.75 2.5 0 .73-.113 1.347-.4 1.867-.289.514-.615.932-1.1 1.253-.485.321-1.15.552-1.75.693v.187c.653.035 1.4.143 2 .5.605.35 1.116.857 1.5 1.5.384.643.5 1.588.5 2.5 0 .93-.288 1.758-.75 2.5-.462.736-1.32 1.221-2.25 1.648-.93.427-2.341.602-3.75.602H5zm3.25-2.75l3.684.07c1.211 0 2.084-.224 2.617-.674.54-.456.81-1.04.81-1.753 0-.532-.136-1.011-.405-1.438a2.754 2.754 0 00-1.151-1.017c-.498-.251-1.091-.377-1.78-.377H8.25v5.189zm0-7.529h3.397a3.8 3.8 0 001.555-.307c.462-.21.825-.505 1.088-.885.27-.386.405-.842.405-1.367 0-.696-.252-1.268-.755-1.718-.498-.45-1.238-.675-2.221-.675H8.25v4.952z"
    }));
  })());
};
Bold.displayName = 'Bold';
Bold.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Box = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 2.646l13.25 5.52v14.607L16 29.398 2.75 22.773V8.167L16 2.646zM5.25 10.875l9.5 3.958v11.144l-9.5-4.75V10.875zm12 15.102l9.5-4.75V10.875l-9.5 3.958v11.144zM16 12.646L24.75 9 16 5.354 7.25 9 16 12.646z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 1.188L14.75 4v7.463L8 14.84l-6.75-3.376V4L8 1.187zM2.75 5.625L7.25 7.5v5.287l-4.5-2.25V5.625zm6 7.162l4.5-2.25V5.625L8.75 7.5v5.287zM8 6.187L12.05 4.5 8 2.812 3.95 4.5 8 6.188z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 1.906L22 6.35v11.3l-10 4.444L2 17.65V6.35l10-4.444zM4 8.539v7.811l7 3.111V11.65L4 8.539zm9 3.11v7.812l7-3.11V8.538l-7 3.11zM18.538 7L12 9.906 5.462 7 12 4.094 18.538 7z"
    }));
  })());
};
Box.displayName = 'Box';
Box.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const BurgerMenu = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4 9.25h24v2.5H4v-2.5zm0 11h24v2.5H4v-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2 4.25h12v1.5H2v-1.5zm0 6h12v1.5H2v-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 7h18v2H3V7zm0 8h18v2H3v-2z"
    }));
  })());
};
BurgerMenu.displayName = 'BurgerMenu';
BurgerMenu.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Calculator = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10 5.25A1.75 1.75 0 008.25 7v5.75h15.5V7A1.75 1.75 0 0022 5.25H10zm13.75 10h-3.5v11.5H22A1.75 1.75 0 0023.75 25v-9.75zm-6 11.5v-4.5h-3.5v4.5h3.5zm-6 0v-4.5h-3.5V25c0 .966.784 1.75 1.75 1.75h1.75zm-3.5-7h3.5v-4.5h-3.5v4.5zm6-4.5v4.5h3.5v-4.5h-3.5zM5.75 7A4.25 4.25 0 0110 2.75h12A4.25 4.25 0 0126.25 7v18A4.25 4.25 0 0122 29.25H10A4.25 4.25 0 015.75 25V7z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 2.75a.75.75 0 00-.75.75v2.75h7.5V3.5a.75.75 0 00-.75-.75H5zm6.75 5h-1.5v5.5H11a.75.75 0 00.75-.75V7.75zm-3 5.5v-2h-1.5v2h1.5zm-3 0v-2h-1.5v1.25c0 .414.336.75.75.75h.75zm-1.5-3.5h1.5v-2h-1.5v2zm3-2v2h1.5v-2h-1.5zM2.75 3.5A2.25 2.25 0 015 1.25h6a2.25 2.25 0 012.25 2.25v9A2.25 2.25 0 0111 14.75H5a2.25 2.25 0 01-2.25-2.25v-9z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 5a3 3 0 013-3h10a3 3 0 013 3v14a3 3 0 01-3 3H7a3 3 0 01-3-3V5zm3-1a1 1 0 00-1 1v5h12V5a1 1 0 00-1-1H7zm11 8h-3v8h2a1 1 0 001-1v-7zm-5 8v-3h-2.5v3H13zm-4.5 0v-3H6v2a1 1 0 001 1h1.5zM6 15h2.5v-3H6v3zm4.5-3v3H13v-3h-2.5z"
    }));
  })());
};
Calculator.displayName = 'Calculator';
Calculator.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Calendar = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.75 4.75H8A4.25 4.25 0 003.75 9v15A4.25 4.25 0 008 28.25h16A4.25 4.25 0 0028.25 24V9A4.25 4.25 0 0024 4.75h-.75V2h-2.5v2.75h-9.5V2h-2.5v2.75zM8 7.25A1.75 1.75 0 006.25 9v1.75h19.5V9A1.75 1.75 0 0024 7.25H8zm17.75 6H6.25V24c0 .966.784 1.75 1.75 1.75h16A1.75 1.75 0 0025.75 24V13.25zM17 19.75H9v-2.5h8v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.25 2.25H4A2.25 2.25 0 001.75 4.5V12A2.25 2.25 0 004 14.25h8A2.25 2.25 0 0014.25 12V4.5A2.25 2.25 0 0012 2.25h-.25V1.5h-1.5v.75h-4.5V1.5h-1.5v.75zM4 3.75a.75.75 0 00-.75.75v1.75h9.5V4.5a.75.75 0 00-.75-.75H4zm8.75 4h-9.5V12c0 .414.336.75.75.75h8a.75.75 0 00.75-.75V7.75zm-5.25 3H5v-1.5h2.5v1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 4V2H6v2a3 3 0 00-3 3v11a3 3 0 003 3h12a3 3 0 003-3V7a3 3 0 00-3-3V2h-2v2H8zM6 6a1 1 0 00-1 1v1h14V7a1 1 0 00-1-1H6zm13 4H5v8a1 1 0 001 1h12a1 1 0 001-1v-8zm-8 4H7v-2h4v2z"
    }));
  })());
};
Calendar.displayName = 'Calendar';
Calendar.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CalendarClock = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.75 4.75H8A4.25 4.25 0 003.75 9v15A4.25 4.25 0 008 28.25h6v-2.5H8A1.75 1.75 0 016.25 24V13.25h22V9A4.25 4.25 0 0024 4.75h-.75V2h-2.5v2.75h-9.5V2h-2.5v2.75zm-2.5 6h19.5V9A1.75 1.75 0 0024 7.25H8A1.75 1.75 0 006.25 9v1.75zm9.75 9H9v-2.5h7v2.5zm8.25-1a5 5 0 100 10 5 5 0 000-10zm-7.5 5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zM23 20v4.65l.855.286 3.75 1.25.79-2.372-2.895-.965V20H23z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.75 2.25V1h-1.5v1.25a2.5 2.5 0 00-2.5 2.5v7a2.5 2.5 0 002.5 2.5H6.5v-1.5H4.25a1 1 0 01-1-1v-5h11v-2a2.5 2.5 0 00-2.5-2.5V1h-1.5v1.25h-4.5zm-2.5 3h9.5v-.5a1 1 0 00-1-1h-7.5a1 1 0 00-1 1v.5zM8 10H5V8.5h3V10zm4-.25a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8.25 12a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zm3.1-1.5v1.808l.4.167 1.5.625.5-1.2-1.1-.458V10.5h-1.3z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 2v2h8V2h2v2a3 3 0 013 3v3H5v8a1 1 0 001 1h4v2H6a3 3 0 01-3-3V7a3 3 0 013-3V2h2zm11 6H5V7a1 1 0 011-1h12a1 1 0 011 1v1zM7 14h4v-2H7v2zm11 0a4 4 0 100 8 4 4 0 000-8zm-6 4a6 6 0 1112 0 6 6 0 01-12 0zm5 0v-3h2v2.28l2.316.771-.632 1.898-3-1L17 18.72V18z"
    }));
  })());
};
CalendarClock.displayName = 'CalendarClock';
CalendarClock.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CalendarRange = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.75 4.75H8A4.25 4.25 0 003.75 9v15A4.25 4.25 0 008 28.25h16A4.25 4.25 0 0028.25 24V9A4.25 4.25 0 0024 4.75h-.75V2h-2.5v2.75h-9.5V2h-2.5v2.75zM8 7.25A1.75 1.75 0 006.25 9v1.75h19.5V9A1.75 1.75 0 0024 7.25H8zm17.75 6H6.25V24c0 .966.784 1.75 1.75 1.75h16A1.75 1.75 0 0025.75 24V13.25zM23 19.75H9v-2.5h14v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.25 2.25H4A2.25 2.25 0 001.75 4.5V12A2.25 2.25 0 004 14.25h8A2.25 2.25 0 0014.25 12V4.5A2.25 2.25 0 0012 2.25h-.25V1.5h-1.5v.75h-4.5V1.5h-1.5v.75zM4 3.75a.75.75 0 00-.75.75v1.75h9.5V4.5a.75.75 0 00-.75-.75H4zm8.75 4h-9.5V12c0 .414.336.75.75.75h8a.75.75 0 00.75-.75V7.75zm-1.75 3H5v-1.5h6v1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 4V2H6v2a3 3 0 00-3 3v11a3 3 0 003 3h12a3 3 0 003-3V7a3 3 0 00-3-3V2h-2v2H8zM6 6a1 1 0 00-1 1v1h14V7a1 1 0 00-1-1H6zm13 4H5v8a1 1 0 001 1h12a1 1 0 001-1v-8zm-2 4H7v-2h10v2z"
    }));
  })());
};
CalendarRange.displayName = 'CalendarRange';
CalendarRange.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CalendarToday = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.75 4.75H8A4.25 4.25 0 003.75 9v15A4.25 4.25 0 008 28.25h16A4.25 4.25 0 0028.25 24V9A4.25 4.25 0 0024 4.75h-.75V2h-2.5v2.75h-9.5V2h-2.5v2.75zM8 7.25A1.75 1.75 0 006.25 9v1.75h19.5V9A1.75 1.75 0 0024 7.25H8zm17.75 6H6.25V24c0 .966.784 1.75 1.75 1.75h16A1.75 1.75 0 0025.75 24V13.25zm-2.75 10h-8v-2.5h8v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.25 2.25H4A2.25 2.25 0 001.75 4.5V12A2.25 2.25 0 004 14.25h8A2.25 2.25 0 0014.25 12V4.5A2.25 2.25 0 0012 2.25h-.25V1.5h-1.5v.75h-4.5V1.5h-1.5v.75zM4 3.75a.75.75 0 00-.75.75v1.75h9.5V4.5a.75.75 0 00-.75-.75H4zm8.75 4h-9.5V12c0 .414.336.75.75.75h8a.75.75 0 00.75-.75V7.75zm-1.25 2.5v1.5H9v-1.5h2.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 4V2H6v2a3 3 0 00-3 3v11a3 3 0 003 3h12a3 3 0 003-3V7a3 3 0 00-3-3V2h-2v2H8zM6 6a1 1 0 00-1 1v1h14V7a1 1 0 00-1-1H6zm13 4H5v8a1 1 0 001 1h12a1 1 0 001-1v-8zm-2 7h-4v-2h4v2z"
    }));
  })());
};
CalendarToday.displayName = 'CalendarToday';
CalendarToday.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Camera = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7 5.75A4.25 4.25 0 002.75 10v12A4.25 4.25 0 007 26.25h18A4.25 4.25 0 0029.25 22V10A4.25 4.25 0 0025 5.75H7zM5.25 10c0-.966.784-1.75 1.75-1.75h18c.966 0 1.75.784 1.75 1.75v12A1.75 1.75 0 0125 23.75H7A1.75 1.75 0 015.25 22V10zM10 11.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm6 1.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM10.75 16a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.5 2.75A2.25 2.25 0 001.25 5v6a2.25 2.25 0 002.25 2.25h9A2.25 2.25 0 0014.75 11V5a2.25 2.25 0 00-2.25-2.25h-9zM2.75 5a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v6a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75V5zM5 5.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm3 1a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM5.25 8a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 7a3 3 0 013-3h14a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V7zm3-1a1 1 0 00-1 1v10a1 1 0 001 1h14a1 1 0 001-1V7a1 1 0 00-1-1H5zm7 4a2 2 0 100 4 2 2 0 000-4zm-4 2a4 4 0 118 0 4 4 0 01-8 0zM6.25 9.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }));
  })());
};
Camera.displayName = 'Camera';
Camera.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CameraPlus = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.75 10A4.25 4.25 0 016 5.75h18A4.25 4.25 0 0128.25 10v7h-2.5v-7A1.75 1.75 0 0024 8.25H6A1.75 1.75 0 004.25 10v12c0 .966.784 1.75 1.75 1.75h13v2.5H6A4.25 4.25 0 011.75 22V10zm5.75 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm4.75 3a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zM15 10.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM25.75 20v3.75H22v2.5h3.75V30h2.5v-3.75H32v-2.5h-3.75V20h-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M.75 5A2.25 2.25 0 013 2.75h9A2.25 2.25 0 0114.25 5v3h-1.5V5a.75.75 0 00-.75-.75H3a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h6v1.5H3A2.25 2.25 0 01.75 11V5zm3 1.5a.75.75 0 100-1.5.75.75 0 000 1.5zM6.25 8a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM7.5 5.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM12.75 10v1.75H11v1.5h1.75V15h1.5v-1.75H16v-1.5h-1.75V10h-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 4a3 3 0 00-3 3v10a3 3 0 003 3h10v-2H4a1 1 0 01-1-1V7a1 1 0 011-1h14a1 1 0 011 1v6h2V7a3 3 0 00-3-3H4zm1.25 5.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM9 12a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 100 8 4 4 0 000-8zm8 10v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3z"
    }));
  })());
};
CameraPlus.displayName = 'CameraPlus';
CameraPlus.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Car = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.24 6.25a1.75 1.75 0 00-1.678 1.253L7.007 12.75h17.986l-1.555-5.247A1.75 1.75 0 0021.76 6.25H10.24zm17.36 6.5l-1.765-5.957A4.25 4.25 0 0021.76 3.75H10.24a4.25 4.25 0 00-4.075 3.043L4.4 12.75H2v2.5h2.083V28H8v-2.75h16V28h3.917V15.25H30v-2.5h-2.4zm-2.183 2.5H6.583v7.5h18.834v-7.5zM12.027 19a2 2 0 11-4 0 2 2 0 014 0zM22 21a2 2 0 100-4 2 2 0 000 4z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.12 3.25a.75.75 0 00-.72.537L3.672 6.25h8.658l-.73-2.463a.75.75 0 00-.719-.537H5.12zm8.773 3l-.856-2.89a2.25 2.25 0 00-2.157-1.61H5.12a2.25 2.25 0 00-2.157 1.61l-.856 2.89H1v1.5h.917V14H4v-1.25h8V14h2.083V7.75H15v-1.5h-1.107zm-1.31 1.5H3.417v3.5h9.166v-3.5zM6.013 9.5a1 1 0 11-2 0 1 1 0 012 0zm4.987 1a1 1 0 100-2 1 1 0 000 2z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.509 5a1 1 0 00-.962.725L5.326 10h13.348l-1.221-4.275A1 1 0 0016.49 5H7.51zm13.245 5l-1.378-4.824A3 3 0 0016.491 3H7.51a3 3 0 00-2.885 2.176L3.246 10H1v2h2v9h3v-2h12v2h3v-9h2v-2h-2.246zM19 12H5v5h14v-5zM9 14.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm7.5 1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    }));
  })());
};
Car.displayName = 'Car';
Car.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CashSingle = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.75 5.75h26.5v20.5H2.75V5.75zm2.5 2.5V11A2.75 2.75 0 008 8.25H5.25zm5.25 0c0 2.9-2.35 5.25-5.25 5.25v5c2.9 0 5.25 2.35 5.25 5.25h11c0-2.9 2.35-5.25 5.25-5.25v-5a5.25 5.25 0 01-5.25-5.25h-11zm13.5 0A2.75 2.75 0 0026.75 11V8.25H24zM26.75 21A2.75 2.75 0 0024 23.75h2.75V21zM8 23.75A2.75 2.75 0 005.25 21v2.75H8zm8-11.5A2.75 2.75 0 0013.25 15v2a2.75 2.75 0 105.5 0v-2A2.75 2.75 0 0016 12.25zM10.75 15a5.25 5.25 0 1110.5 0v2a5.25 5.25 0 11-10.5 0v-2z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2 2.75h-.75v10.5h13.5V2.75H2zm.75 2.75v5H4v1.25h8V10.5h1.25v-5H12V4.25H4V5.5H2.75zm4 2.5a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM8 5.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 4H2v16h20V4H3zm1 4v8h2v2h12v-2h2V8h-2V6H6v2H4zm6 3.5a2 2 0 114 0v1a2 2 0 11-4 0v-1zm2-4a4 4 0 00-4 4v1a4 4 0 008 0v-1a4 4 0 00-4-4z"
    }));
  })());
};
CashSingle.displayName = 'CashSingle';
CashSingle.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryEntertainment = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.626 5.636c-3.614 3.624-3.544 9.527.108 13.18l3.151 3.15c1.53 1.53 3.83 2.003 5.82 1.167l.52-.22a1.465 1.465 0 011.598.33l4.336 4.337 6.42-6.421-4.335-4.336a1.465 1.465 0 01-.33-1.598l.219-.52c.837-1.99.363-4.29-1.166-5.82l-3.151-3.151c-3.653-3.652-9.556-3.721-13.18-.108l-.005.005-.005.005zm1.773 1.763c-2.615 2.62-2.602 6.944.103 9.649l3.151 3.151c.826.826 2.05 1.064 3.082.63l.52-.22a3.917 3.917 0 012.286-.23l3.838-3.838a3.917 3.917 0 01.23-2.286l.22-.52c.433-1.032.196-2.256-.63-3.082l-3.151-3.151c-2.705-2.705-7.03-2.718-9.65-.103zm13.76 16.645l-2.374-2.374 2.885-2.885 2.374 2.374-2.885 2.885z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6 21.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zM4.25 26a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.829 2.829a5.5 5.5 0 000 7.778l1.395 1.395a3.271 3.271 0 003.713.643l1.117-.528a.25.25 0 01.283.049l2.684 2.683 2.829-2.828-2.684-2.684a.25.25 0 01-.05-.284l.53-1.116a3.271 3.271 0 00-.644-3.713l-1.395-1.396a5.5 5.5 0 00-7.778 0zm1.06 6.717a4 4 0 015.657-5.657l1.396 1.395c.529.53.668 1.335.348 2.011l-.529 1.116a1.75 1.75 0 00.095 1.673l-.772.772a1.75 1.75 0 00-1.673-.095l-1.116.529a1.771 1.771 0 01-2.01-.349L3.889 9.546zm7.288 2.338l.844.844.707-.707-.844-.844-.707.707z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 18a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5.543 4.043c2.732-2.725 7.167-2.733 9.9 0l2.32 2.321a4 4 0 01.849 4.404l-.167.389a1 1 0 00.212 1.1l3.25 3.25L17 20.414l-3.25-3.25a1 1 0 00-1.1-.212l-.39.167a4 4 0 01-4.403-.848L5.536 13.95c-2.733-2.733-2.725-7.167 0-9.9l.003-.003.004-.004zm1.41 1.418a5.009 5.009 0 017.075-.004l2.321 2.321a2 2 0 01.424 2.202l-.166.389a3 3 0 00.078 2.532l-2.292 2.291a3 3 0 00-2.532-.078l-.388.167a2 2 0 01-2.202-.424L6.95 12.536a5.009 5.009 0 01.003-7.075zM15.914 16.5L17 17.586l2.078-2.079-1.085-1.085-2.079 2.078z"
    }));
  })());
};
CategoryEntertainment.displayName = 'CategoryEntertainment';
CategoryEntertainment.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryEquipment = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M18.5 2.75c-5.937 0-10.75 4.813-10.75 10.75 0 2.518.866 4.834 2.316 6.666l-6.95 6.95 1.768 1.768 6.95-6.95A10.704 10.704 0 0018.5 24.25c5.937 0 10.75-4.813 10.75-10.75 0-1.376-.259-2.695-.733-3.908l-.695-1.782-5.893 5.893-3.182-3.182 5.97-5.97-1.598-.76A10.713 10.713 0 0018.5 2.75zM10.25 13.5a8.25 8.25 0 0110.038-8.056l-5.076 5.077 6.717 6.717 4.759-4.758A8.25 8.25 0 1110.25 13.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.5 1a5.5 5.5 0 00-4.383 8.823L1.47 13.47l1.06 1.06 3.647-3.647a5.5 5.5 0 008.288-6.752l-.457-.957-3.397 3.397-1.06-1.06 3.407-3.408-.902-.474A5.48 5.48 0 009.5 1zm-4 5.5a4 4 0 014.85-3.91L7.428 5.51l3.182 3.182 2.837-2.837A4 4 0 115.5 6.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16.703 2.468c.668.24 1.294.565 1.865.964L14 8l2 2 4.568-4.568A7.978 7.978 0 0122 10a8 8 0 01-12.906 6.32l-5.387 5.387-1.414-1.414 5.387-5.387a8 8 0 019.023-12.437zM16 12.828l3.904-3.904a6 6 0 11-4.828-4.828L11.172 8 16 12.828z"
    }));
  })());
};
CategoryEquipment.displayName = 'CategoryEquipment';
CategoryEquipment.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryIDonTKnow = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M19 24.981a10.721 10.721 0 01-4 .769C9.063 25.75 4.25 20.937 4.25 15S9.063 4.25 15 4.25c5.428 0 9.916 4.023 10.646 9.25h2.52C27.421 6.888 21.811 1.75 15 1.75 7.682 1.75 1.75 7.682 1.75 15S7.682 28.25 15 28.25c1.394 0 2.738-.215 4-.614V24.98z"
      }), React.createElement("path", {
        d: "M19 19.875h-9v2.25h9v-2.25z"
      }), React.createElement("path", {
        d: "M22.5 11.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M12.875 9h2.25v5.875H17.5v2.25h-4.625V9z"
      }), React.createElement("path", {
        d: "M11 11.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M21.5 20.936A4.935 4.935 0 0126.436 16a4.935 4.935 0 014.936 4.936c0 1.583-.974 2.435-1.924 3.265-.9.786-1.778 1.553-1.778 2.905h-2.63c0-2.248 1.153-3.139 2.167-3.922.795-.615 1.505-1.163 1.505-2.248 0-1.358-.918-2.314-2.276-2.314-1.357 0-2.313.956-2.313 2.314H21.5z"
      }), React.createElement("path", {
        d: "M28.056 30.094a1.639 1.639 0 11-3.278 0 1.639 1.639 0 013.278 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M7.5.75A6.75 6.75 0 0114.232 7h-1.505A5.25 5.25 0 109.5 12.356v1.593A6.75 6.75 0 117.5.75z"
      }), React.createElement("path", {
        d: "M7.625 4.5h-1.25v4.125H8.75v-1.25H7.625V4.5z"
      }), React.createElement("path", {
        d: "M12.25 10.75a1 1 0 011-1h.121a.878.878 0 01.488 1.61l-.025.016a2.434 2.434 0 00-1.084 2.025v.349h1.5v-.349c0-.312.156-.604.416-.777l.025-.017a2.378 2.378 0 00-1.32-4.357h-.121a2.5 2.5 0 00-2.5 2.5V11h1.5v-.25z"
      }), React.createElement("path", {
        d: "M14.4 15.125a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M5.5 5.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M11.25 5.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M9.5 9.875v1.25h-4v-1.25h4z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M11 1c5.185 0 9.449 3.947 9.95 9h-2.012A8.001 8.001 0 003 11a8 8 0 0011 7.419v2.123A9.997 9.997 0 0111 21C5.477 21 1 16.523 1 11S5.477 1 11 1z"
    }), React.createElement("path", {
      d: "M10.875 6.5h-1.75v6.375H12.5v-1.75h-1.625V6.5z"
    }), React.createElement("path", {
      d: "M8 8.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M16.5 8.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M14 16.375H7v-1.75h7v1.75z"
    }), React.createElement("path", {
      d: "M19.765 12A3.764 3.764 0 0016 15.765h2c0-1.036.73-1.765 1.765-1.765s1.735.73 1.735 1.765c0 .827-.541 1.245-1.148 1.714-.773.598-1.652 1.277-1.652 2.992h2.006c0-1.031.67-1.616 1.356-2.216.724-.633 1.467-1.283 1.467-2.49A3.764 3.764 0 0019.765 12z"
    }), React.createElement("path", {
      d: "M19.75 24a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }));
  })());
};
CategoryIDonTKnow.displayName = 'CategoryIDonTKnow';
CategoryIDonTKnow.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryMarketing = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M28.814 5.45l-3.5 3-1.627-1.899 3.5-3 1.627 1.898z"
      }), React.createElement("path", {
        d: "M31.012 14.472l-3.5.75-.524-2.444 3.5-.75.524 2.444z"
      }), React.createElement("path", {
        d: "M27.441 23.118l3 1.5 1.118-2.236-3-1.5-1.118 2.236z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.789 5.554l.02-.007c.935-.303 1.87-.104 2.668.306.793.406 1.54 1.057 2.215 1.838 1.352 1.565 2.583 3.844 3.433 6.458.849 2.614 1.192 5.18 1.018 7.242-.087 1.028-.308 1.994-.71 2.789-.406.8-1.045 1.511-1.98 1.815l.002.003a5.604 5.604 0 01-2.5.221l-17.95-2.48-1.303-4.012L14.655 6.863a5.447 5.447 0 012.134-1.309zm.788 2.373l.005-.002c.116-.038.347-.057.755.152.415.213.918.618 1.463 1.249 1.087 1.257 2.175 3.22 2.947 5.595.772 2.376 1.045 4.604.905 6.26-.07.83-.24 1.454-.45 1.869-.207.41-.405.53-.521.567-.116.038-.347.057-.755-.152-.414-.212-.918-.618-1.463-1.248-1.086-1.258-2.175-3.22-2.947-5.596-.772-2.376-1.045-4.603-.905-6.26.07-.83.24-1.453.45-1.869.204-.403.4-.526.516-.565zm.55 15.378L4.899 21.477l-.344-1.06 9.524-9.459c-.038 1.906.318 4.153 1.06 6.435.755 2.325 1.812 4.384 2.988 5.912z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M13.416 3.124l-1.5 1-.832-1.248 1.5-1 .832 1.248z"
      }), React.createElement("path", {
        d: "M15.182 7.228l-2 .5-.364-1.456 2-.5.364 1.456z"
      }), React.createElement("path", {
        d: "M14.763 11.711l-1.5-.5.474-1.422 1.5.5-.474 1.422z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.326 2.918a4.637 4.637 0 00-1.899 1.2L.695 9.07l.895 2.754 6.74 1.225a4.637 4.637 0 002.262-.152c.986-.321 1.362-1.39 1.464-2.327.11-1.015-.043-2.263-.452-3.522-.41-1.259-1.018-2.359-1.704-3.115-.634-.7-1.567-1.342-2.555-1.022a1.7 1.7 0 00-.02.007zm.48 1.421l.005-.001c.078-.024.416-.017.977.602.514.566 1.03 1.467 1.39 2.571.358 1.105.47 2.137.387 2.897-.09.837-.364 1.039-.438 1.063-.075.024-.414.021-.98-.602-.513-.566-1.03-1.467-1.389-2.572s-.47-2.136-.387-2.896c.089-.825.355-1.033.435-1.062zm-.158 7.062c-.526-.712-.986-1.624-1.316-2.64-.33-1.016-.494-2.024-.487-2.91L2.398 9.46l.341 1.05 4.909.892z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M21.625 4.28l-2.5 2-1.25-1.56 2.5-2 1.25 1.56z"
    }), React.createElement("path", {
      d: "M23.196 10.98l-2.5.5-.392-1.96 2.5-.5.392 1.96z"
    }), React.createElement("path", {
      d: "M22.553 18.395l-2-1 .894-1.79 2 1-.894 1.79z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.338 4.176l-.014.004a5.236 5.236 0 00-2.042 1.245L.995 14.572l1 3.078 12.89 1.94a5.237 5.237 0 002.397-.197c.716-.233 1.206-.77 1.52-1.375.31-.6.481-1.325.55-2.091.14-1.537-.109-3.443-.738-5.379-.629-1.935-1.547-3.624-2.564-4.785-.507-.58-1.071-1.065-1.675-1.368-.608-.305-1.322-.452-2.037-.219zm1.141 2.007c-.293-.146-.45-.128-.523-.105l-.004.001c-.073.025-.21.106-.358.392-.153.296-.28.746-.335 1.352-.11 1.208.083 2.838.649 4.58.566 1.74 1.367 3.173 2.166 4.086.401.458.768.747 1.066.896.293.147.452.13.524.106.072-.024.211-.102.362-.393.153-.296.28-.746.335-1.352.11-1.208-.083-2.838-.649-4.58-.566-1.74-1.368-3.173-2.167-4.086-.4-.458-.768-.747-1.066-.897zM3.28 15.13l6.953-6.847c-.03 1.41.23 3.063.774 4.739.544 1.674 1.305 3.165 2.159 4.288l-9.65-1.453-.236-.727z"
    }));
  })());
};
CategoryMarketing.displayName = 'CategoryMarketing';
CategoryMarketing.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryMealsAndDrinks = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M19.248 5.75H22v-2.5H3v2.5h7.741a.86.86 0 01-.243.484 3.352 3.352 0 00-.697 1.016H3v2.5h6.712c.135.374.338.73.61 1.047l.507.591a.602.602 0 01-.072.854 3.092 3.092 0 00-1.045 3.008H3.75v1.25c0 6.765 5.485 12.25 12.25 12.25s12.25-5.485 12.25-12.25v-1.25h-9.747a3.122 3.122 0 00-.173-.188.616.616 0 01.028-.9 3.102 3.102 0 00.37-4.401l-.01-.011H22v-2.5h-3.169a3.36 3.36 0 00.417-1.5zm-2.507 0h-3.493a3.36 3.36 0 01-.416 1.5H15.8c.163-.37.396-.714.697-1.016a.86.86 0 00.243-.484zm-1.029 4h-2.994l.01.011a3.102 3.102 0 01-.37 4.401.616.616 0 00-.028.9c.06.06.118.123.173.188h3.21a3.092 3.092 0 011.045-3.008.602.602 0 00.071-.854l-.506-.59a3.354 3.354 0 01-.61-1.048zM16 26.25c-4.961 0-9.057-3.706-9.67-8.5h19.34c-.613 4.794-4.709 8.5-9.67 8.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.5 3.25h-.758a1.75 1.75 0 01-.123.5h.881v1.5h-.852c.304.667.181 1.481-.368 2.03l-.043.043a.25.25 0 000 .354c.098.098.184.207.255.323H14v1A6 6 0 012 9V8h2.737a1.75 1.75 0 01.44-1.737l.043-.043a.31.31 0 000-.44l-.043-.043a1.747 1.747 0 01-.345-.487H1.5v-1.5h3.332a1.747 1.747 0 01.356-.5H1.5v-1.5h9v1.5zm-3.758 0a1.75 1.75 0 01-.123.5h1.213a1.747 1.747 0 01.356-.5H6.742zm-.094 2c.304.667.181 1.481-.368 2.03l-.043.043a.25.25 0 000 .354c.098.098.184.207.255.323h1.245a1.75 1.75 0 01.44-1.737l.043-.043a.31.31 0 000-.44l-.043-.043a1.747 1.747 0 01-.345-.487H6.648zM8 13.5a4.5 4.5 0 01-4.473-4h8.945A4.5 4.5 0 018 13.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.497 5H16V3H2v2h6.485a.5.5 0 01-.131.232l-.061.06c-.21.212-.375.452-.491.708H2v2h5.802c.116.256.28.496.49.707a.414.414 0 010 .586A2.415 2.415 0 007.803 12H3v1a9 9 0 1018 0v-1h-6.765a2.5 2.5 0 00-.467-.646l-.06-.061a.414.414 0 010-.586A2.415 2.415 0 0014.197 8H16V6h-1.765a2.5 2.5 0 00.262-1zm-2.012 0h-1.988a2.5 2.5 0 01-.262 1h1.567a2.41 2.41 0 01.49-.707l.062-.06A.5.5 0 0012.485 5zm-.683 3h-1.604c.405.89.241 1.975-.49 2.707a.414.414 0 000 .586l.06.06c.19.191.348.41.466.647h1.568a2.415 2.415 0 01.49-2.707.414.414 0 000-.586 2.41 2.41 0 01-.49-.707zM5.07 14a7.002 7.002 0 0013.858 0H5.071z"
    }));
  })());
};
CategoryMealsAndDrinks.displayName = 'CategoryMealsAndDrinks';
CategoryMealsAndDrinks.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryNone = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.5 1.603L23.23 14.25H7.77l7.73-12.647zM12.23 11.75h6.542l-3.27-5.353-3.272 5.353z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.75 16.75h11.5v11.5H2.75v-11.5zm2.5 2.5v6.5h6.5v-6.5h-6.5z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M23 16.25a6.25 6.25 0 100 12.5 6.25 6.25 0 000-12.5zm-3.75 6.25a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.636 2.103L8 1.085l-.636 1.018-2.5 4-.717 1.147H11.853l-.717-1.147-2.5-4zM8 3.915L9.147 5.75H6.853L8 3.915zm3.375 5.835a1.625 1.625 0 100 3.25 1.625 1.625 0 000-3.25zM8.25 11.375a3.125 3.125 0 116.25 0 3.125 3.125 0 01-6.25 0zM1.75 8.75h5.5v5.5h-5.5v-5.5zm1.5 1.5v2.5h2.5v-2.5h-2.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16.741 11l-5.24-8.985L6.258 11h10.482zM13.26 9H9.741l1.76-3.015L13.258 9z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 16.75a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zm4.5-2.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 13h8v8H3v-8zm2 2v4h4v-4H5z"
    }));
  })());
};
CategoryNone.displayName = 'CategoryNone';
CategoryNone.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryOffice = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.363 10.497A7.714 7.714 0 0014.25 14.5c0 .833.132 1.636.376 2.39l-2.947.673-1.906-3.813H2.25v14h27.5V14.5A7.75 7.75 0 0022 6.75c-1.034 0-2.07.178-3.171.414-.501.107-.98.219-1.484.335-.688.16-1.42.329-2.308.513-2.912.602-6.866 1.21-12.784 1.21v2.5c5.989 0 10.07-.605 13.11-1.225zM22 9.25a5.25 5.25 0 00-4.923 7.079l3.244-.742a2 2 0 113.663-.837l3.243-.741A5.251 5.251 0 0022 9.25zm5.25 7.318l-16.929 3.87-2.094-4.188H4.75v9h22.5v-8.682z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1 6.25c2.887 0 4.831-.293 6.251-.649A3.991 3.991 0 007 7c0 .556.114 1.087.32 1.568l-1.272.33L4.625 7H1v7h14V7a4 4 0 00-4-4c-.74 0-1.415.25-2.098.501l-.043.016c-.334.123-.686.253-1.13.392C6.405 4.322 4.43 4.75 1 4.75v1.5zM8.5 7a2.5 2.5 0 011.573-2.323c1.6-.518 3.403.469 3.427 2.29l-1.572.407a1 1 0 10-1.557.403l-1.573.408A2.487 2.487 0 018.5 7zm-3.048 3.602L13.5 8.516V12.5h-11v-4h1.375l1.577 2.102z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.732 8.123a5.993 5.993 0 00-.523 4.439l-1.264.303L7.035 10H2v10h20v-9a6.003 6.003 0 00-6-6h-.074c-.782.009-1.539.153-2.318.34-.362.088-.743.188-1.137.292l-.128.034c-.444.117-.912.24-1.424.361C8.893 6.512 6.162 7 2 7v2c3.915 0 6.649-.417 8.732-.877zm3.857 3.387l-2.434.584A3.993 3.993 0 0115.997 7c1.946 0 3.569 1.39 3.929 3.23l-2.437.584a1.5 1.5 0 10-2.9.696zm-6.534 3.625L20 12.268V18H4v-6h1.965l2.09 3.135z"
    }));
  })());
};
CategoryOffice.displayName = 'CategoryOffice';
CategoryOffice.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryOther = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M28 21.25v-2.5h-6.636l.579-5.5H28v-2.5h-5.795l.71-6.75H20.4l-.71 6.75h-6.486l.71-6.75H11.4l-.71 6.75H4v2.5h6.429l-.578 5.5H4v2.5h5.588L8.878 28h2.514l.71-6.75h6.486l-.71 6.75h2.514l.71-6.75H28zm-15.636-2.5l.579-5.5h6.486l-.578 5.5h-6.486z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.29 14l.515-3.25H6.324L5.809 14H4.29l.515-3.25H2v-1.5h3.043l.396-2.5H2v-1.5h3.676L6.191 2H7.71l-.515 3.25h2.481L10.191 2h1.519l-.515 3.25H14v1.5h-3.043l-.396 2.5H14v1.5h-3.676L9.809 14H8.29zM9.44 6.75l-.396 2.5H6.56l.396-2.5H9.44z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.01 20l.491-4H9.516l-.49 4H7.01l.491-4H4v-2h3.747l.491-4H4V8h4.484l.49-4h2.016l-.491 4h3.985l.49-4h2.016l-.491 4H20v2h-3.747l-.491 4H20v2h-4.484l-.49 4H13.01zm1.228-10l-.491 4H9.762l.491-4h3.985z"
    }));
  })());
};
CategoryOther.displayName = 'CategoryOther';
CategoryOther.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryPhoneInternet = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M6.5 2.25A4.25 4.25 0 002.25 6.5v19a4.25 4.25 0 004.25 4.25H19a4.25 4.25 0 004.25-4.25v-.167h-2.5v.167A1.75 1.75 0 0119 27.25H6.5a1.75 1.75 0 01-1.75-1.75v-19c0-.966.784-1.75 1.75-1.75H19c.966 0 1.75.784 1.75 1.75v.167h2.5V6.5A4.25 4.25 0 0019 2.25H6.5z"
      }), React.createElement("path", {
        d: "M22 9.25a13.212 13.212 0 00-9.616 4.134l1.769 1.769A10.72 10.72 0 0122 11.75a10.72 10.72 0 017.847 3.403l1.769-1.769A13.213 13.213 0 0022 9.25z"
      }), React.createElement("path", {
        d: "M22 14.25c2.405 0 4.57 1.03 6.078 2.672l-1.77 1.77A5.736 5.736 0 0022 16.75c-1.715 0-3.255.75-4.308 1.942l-1.77-1.77A8.228 8.228 0 0122 14.25z"
      }), React.createElement("path", {
        d: "M22 19.75c.887 0 1.676.42 2.178 1.072L22 23l-2.178-2.178A2.746 2.746 0 0122 19.75z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M3.5 1.25A2.25 2.25 0 001.25 3.5v9a2.25 2.25 0 002.25 2.25H9a2.25 2.25 0 002.25-2.25h-1.5a.75.75 0 01-.75.75H3.5a.75.75 0 01-.75-.75v-9a.75.75 0 01.75-.75H9a.75.75 0 01.75.75h1.5A2.25 2.25 0 009 1.25H3.5z"
      }), React.createElement("path", {
        d: "M10.5 4.5c-1.89 0-3.606.75-4.865 1.967l1.042 1.079A5.482 5.482 0 0110.5 6c1.508 0 2.874.607 3.867 1.59l1.055-1.067A6.978 6.978 0 0010.5 4.5z"
      }), React.createElement("path", {
        d: "M10.5 7.25a3.99 3.99 0 012.935 1.282L12.379 9.6a2.494 2.494 0 00-1.879-.85c-.738 0-1.4.32-1.858.828l-1.044-1.08A3.989 3.989 0 0110.5 7.25z"
      }), React.createElement("path", {
        d: "M10.5 10c.406 0 .767.194.995.494L10.5 11.5l-.985-1.02c.228-.292.585-.48.985-.48z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M5 2a3 3 0 00-3 3v14a3 3 0 003 3h9a3 3 0 003-3h-2a1 1 0 01-1 1H5a1 1 0 01-1-1V5a1 1 0 011-1h9a1 1 0 011 1h2a3 3 0 00-3-3H5z"
    }), React.createElement("path", {
      d: "M23.071 9.929A9.969 9.969 0 0016 7a9.969 9.969 0 00-7.071 2.929l1.414 1.414A7.975 7.975 0 0116 9c2.21 0 4.21.895 5.657 2.343L23.07 9.93z"
    }), React.createElement("path", {
      d: "M20.243 12.757A5.981 5.981 0 0016 11a5.981 5.981 0 00-4.243 1.757l1.415 1.415A3.987 3.987 0 0116 13c1.105 0 2.105.448 2.828 1.172l1.415-1.415z"
    }), React.createElement("path", {
      d: "M16 15c.552 0 1.052.224 1.414.586L16 17l-1.414-1.414A1.994 1.994 0 0116 15z"
    }));
  })());
};
CategoryPhoneInternet.displayName = 'CategoryPhoneInternet';
CategoryPhoneInternet.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryService = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M12.75 20.38a5.56 5.56 0 01-2.25-2.702l-2.096.816a7.817 7.817 0 004.346 4.4V20.38z"
      }), React.createElement("path", {
        d: "M12.75 25.514C7.893 24.48 4.25 20.165 4.25 15 4.25 9.063 9.063 4.25 15 4.25c5.254 0 9.627 3.769 10.564 8.75H28.1C27.136 6.631 21.638 1.75 15 1.75 7.682 1.75 1.75 7.682 1.75 15c0 6.55 4.754 11.991 11 13.06v-2.546z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M18.5 16a2.75 2.75 0 00-2.75 2.75v8a2.75 2.75 0 002.75 2.75h9.864L32 32V18.75A2.75 2.75 0 0029.25 16H18.5zm-.25 2.75a.25.25 0 01.25-.25h10.75a.25.25 0 01.25.25V27h-11a.25.25 0 01-.25-.25v-8z"
      }), React.createElement("path", {
        d: "M12.875 8.5V16h2.25V8.5h-2.25z"
      }), React.createElement("path", {
        d: "M11 11.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M20.75 13.5a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M7.5 2.5A5 5 0 0112.475 7h1.506A6.5 6.5 0 107 13.981v-1.506A5 5 0 017.5 2.5z"
      }), React.createElement("path", {
        d: "M4.05 8.614A3.629 3.629 0 007 11.09V9.822A2.38 2.38 0 015.24 8.23l-1.19.384z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 16v-5.75a1.75 1.75 0 00-1.75-1.75h-4a1.75 1.75 0 00-1.75 1.75v2.5c0 .966.784 1.75 1.75 1.75h3.755L16 16zm-5.75-6a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h4.25v-2.75a.25.25 0 00-.25-.25h-4z"
      }), React.createElement("path", {
        d: "M4.75 6.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
      }), React.createElement("path", {
        d: "M11 5.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
      }), React.createElement("path", {
        d: "M6.375 4.5V8h1.25V4.5h-1.25z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M7.155 12.497A4.134 4.134 0 0010 15.003v1.787a5.883 5.883 0 01-4.476-3.658l1.63-.635z"
    }), React.createElement("path", {
      d: "M3 11a8.001 8.001 0 007 7.938v2.013C4.947 20.449 1 16.185 1 11 1 5.477 5.477 1 11 1c5.185 0 9.449 3.947 9.95 9h-2.012A8.001 8.001 0 003 11z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 14.5a2.5 2.5 0 012.5-2.5h7a2.5 2.5 0 012.5 2.5V24l-3-2h-6.5a2.5 2.5 0 01-2.5-2.5v-5zm2.5-.5a.5.5 0 00-.5.5v5a.5.5 0 00.5.5H22v-5.5a.5.5 0 00-.5-.5h-7z"
    }), React.createElement("path", {
      d: "M8 8.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M15.25 9.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }), React.createElement("path", {
      d: "M9.625 6.5V12h1.75V6.5h-1.75z"
    }));
  })());
};
CategoryService.displayName = 'CategoryService';
CategoryService.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategorySoftware = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M8.5 6.25a4.25 4.25 0 00-4.25 4.25v13.75H1v2.5h16v-2.5H6.75V10.5c0-.966.784-1.75 1.75-1.75h15A1.75 1.75 0 0125.177 10h2.544A4.25 4.25 0 0023.5 6.25h-15z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22.75 13A2.75 2.75 0 0020 15.75V24a2.75 2.75 0 002.75 2.75h4.5A2.75 2.75 0 0030 24v-8.25A2.75 2.75 0 0027.25 13h-4.5zm-.25 2.75a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V24a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-8.25z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M2 11.5V5a2 2 0 012-2h8a2 2 0 011.937 1.5H4a.5.5 0 00-.5.5v6.5h5V13H1v-1.5h1z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10 7a1 1 0 011-1h3a1 1 0 011 1v5a1 1 0 01-1 1h-3a1 1 0 01-1-1V7zm1.5 4.5v-4h2v4h-2z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M6 5a3 3 0 00-3 3v10H1v2h13v-2H5V8a1 1 0 011-1h12a1 1 0 011 1h2a3 3 0 00-3-3H6z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18.5 10a2.5 2.5 0 00-2.5 2.5v5a2.5 2.5 0 002.5 2.5h2a2.5 2.5 0 002.5-2.5v-5a2.5 2.5 0 00-2.5-2.5h-2zm-.5 2.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-5z"
    }));
  })());
};
CategorySoftware.displayName = 'CategorySoftware';
CategorySoftware.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategorySupplies = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M11.75 23v-6h2.5v6h-2.5z"
      }), React.createElement("path", {
        d: "M17.75 17v6h2.5v-6h-2.5z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M19.994 12.25h-7.988l2.198-7.915-2.408-.67L9.41 12.25H2v2.5h2.222l2.076 10.57a4.25 4.25 0 004.17 3.43h11.064a4.25 4.25 0 004.17-3.43l2.076-10.57H30v-2.5h-7.411l-2.385-8.585-2.408.67 2.198 7.915zm5.237 2.5H6.769l1.982 10.087a1.75 1.75 0 001.717 1.413h11.064a1.75 1.75 0 001.717-1.413l1.982-10.087z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M5.75 11.5v-3h1.5v3h-1.5z"
      }), React.createElement("path", {
        d: "M8.75 8.5v3h1.5v-3h-1.5z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.211 1.737L6.041 5.25h3.918L8.79 1.737l1.422-.474 1.33 3.987H15.5v1.5h-.915l-1.573 6.296a2.25 2.25 0 01-2.183 1.704H5.17a2.25 2.25 0 01-2.183-1.704L1.414 6.75H.5v-1.5h3.96l1.328-3.987 1.423.474zM13.04 6.75H2.961l1.483 5.932a.75.75 0 00.727.568h5.658a.75.75 0 00.727-.568L13.04 6.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M9 17.5V13h2v4.5H9z"
    }), React.createElement("path", {
      d: "M13 13v4.5h2V13h-2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.78 9H9.22l1.729-5.184-1.898-.632L7.113 9H1v2h1.484l2.373 8.782A3 3 0 007.754 22h8.493a3 3 0 002.896-2.218L21.516 11H23V9h-6.113L14.95 3.184l-1.898.632L14.78 9zm4.664 2H4.556l2.232 8.26a1 1 0 00.966.74h8.493a1 1 0 00.965-.74L19.444 11z"
    }));
  })());
};
CategorySupplies.displayName = 'CategorySupplies';
CategorySupplies.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CategoryTravel = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M11 15.75h10v-2.5H11v2.5z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.25 8.25V3.5A2.75 2.75 0 0114 .75h4a2.75 2.75 0 012.75 2.75v4.75h.75a4.25 4.25 0 014.25 4.25V25a4.252 4.252 0 01-3.25 4.132V30.5h-4v-1.25h-5v1.25h-4v-1.368A4.252 4.252 0 016.25 25V12.5a4.25 4.25 0 014.25-4.25h.75zm2.5-4.75a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v4.75h-4.5V3.5zm-5 9c0-.966.784-1.75 1.75-1.75h11c.966 0 1.75.784 1.75 1.75V25a1.75 1.75 0 01-1.75 1.75h-11A1.75 1.75 0 018.75 25V12.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.75 5V3c0-.966.784-1.75 1.75-1.75h1c.966 0 1.75.784 1.75 1.75v2h.25c.966 0 1.75.784 1.75 1.75v5.5A1.75 1.75 0 0111 13.928V15H9v-1H7v1H5v-1.072a1.75 1.75 0 01-1.25-1.678v-5.5C3.75 5.784 4.534 5 5.5 5h.25zm1.5-2a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v2h-1.5V3zm-2 3.75a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-5.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M9 13h6v-2H9v2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 3.5V7a3 3 0 00-3 3v9c0 1.306.835 2.418 2 2.83V23h3v-1h4v1h3v-1.17A3.001 3.001 0 0019 19v-9a3 3 0 00-3-3V3.5A2.5 2.5 0 0013.5 1h-3A2.5 2.5 0 008 3.5zm2.5-.5a.5.5 0 00-.5.5V7h4V3.5a.5.5 0 00-.5-.5h-3zM8 9a1 1 0 00-1 1v9a1 1 0 001 1h8a1 1 0 001-1v-9a1 1 0 00-1-1H8z"
    }));
  })());
};
CategoryTravel.displayName = 'CategoryTravel';
CategoryTravel.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Check = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M26.884 9.884L12 24.768l-6.884-6.884 1.768-1.768L12 21.232 25.116 8.116l1.768 1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.53 5.03L6 12.56 2.47 9.03l1.06-1.06L6 10.44l6.47-6.47 1.06 1.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20.707 6.707L9 18.414l-5.707-5.707 1.414-1.414L9 15.586 19.293 5.293l1.414 1.414z"
    }));
  })());
};
Check.displayName = 'Check';
Check.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CheckCircleFilled = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 29c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13zm-1.116-8.616l8-8-1.768-1.768L14 17.732l-3.116-3.116-1.768 1.768 4 4 .884.884.884-.884z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 15A7 7 0 108 1a7 7 0 000 14zm3.53-8.97l-1.06-1.06L7 8.44 5.53 6.97 4.47 8.03 7 10.56l4.53-4.53z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-.793-6.793l6-6-1.414-1.414-5.293 5.293-2.293-2.293-1.414 1.414 3 3 .707.707.707-.707z"
    }));
  })());
};
CheckCircleFilled.displayName = 'CheckCircleFilled';
CheckCircleFilled.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CheckSmall = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22.884 11.884L14 20.768l-4.884-4.884 1.768-1.768L14 17.232l7.116-7.116 1.768 1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.596 3.97l1.564 1.54-6.68 6.58-3.73-3.675 1.564-1.54L6.48 9.008l5.115-5.04z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.414 9.414l-6.707 6.707L7 12.414 8.414 11l2.293 2.293L16 8l1.414 1.414z"
    }));
  })());
};
CheckSmall.displayName = 'CheckSmall';
CheckSmall.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ChevronDown = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.116 11.884l1.768-1.768L16 19.232l9.116-9.116 1.768 1.768L16 22.768 5.116 11.884z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.47 6.03l1.06-1.06L8 9.44l4.47-4.47 1.06 1.06L8 11.56 2.47 6.03z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414 3.293 8.707z"
    }));
  })());
};
ChevronDown.displayName = 'ChevronDown';
ChevronDown.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ChevronLeft = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M20.116 5.116l1.768 1.768L12.768 16l9.116 9.116-1.768 1.768L9.232 16 20.116 5.116z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.97 2.47l1.06 1.06L6.56 8l4.47 4.47-1.06 1.06L4.44 8l5.53-5.53z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.293 3.293l1.414 1.414L9.414 12l7.293 7.293-1.414 1.414L6.586 12l8.707-8.707z"
    }));
  })());
};
ChevronLeft.displayName = 'ChevronLeft';
ChevronLeft.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ChevronRight = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M19.232 16l-9.116-9.116 1.768-1.768L22.768 16 11.884 26.884l-1.768-1.768L19.232 16z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.44 8L4.97 3.53l1.06-1.06L11.56 8l-5.53 5.53-1.06-1.06L9.44 8z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.586 12L7.293 4.707l1.414-1.414L17.414 12l-8.707 8.707-1.414-1.414L14.586 12z"
    }));
  })());
};
ChevronRight.displayName = 'ChevronRight';
ChevronRight.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ChevronTripleRight = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.884 5.116L21.768 16 10.884 26.884l-1.768-1.768 7.866-7.866H4v-2.5h12.982L9.116 6.884l1.768-1.768zM26.232 16l-9.116-9.116 1.768-1.768L29.768 16 18.884 26.884l-1.768-1.768L26.232 16z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.53 2.47L11.06 8l-5.53 5.53-1.06-1.06 3.72-3.72H2v-1.5h6.19L4.47 3.53l1.06-1.06zM12.94 8L8.47 3.53l1.06-1.06L15.06 8l-5.53 5.53-1.06-1.06L12.94 8z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.207 4.293L15.914 12l-7.707 7.707-1.414-1.414L12.086 13H3v-2h9.086L6.793 5.707l1.414-1.414zM19.086 12l-6.293-6.293 1.414-1.414L21.914 12l-7.707 7.707-1.414-1.414L19.086 12z"
    }));
  })());
};
ChevronTripleRight.displayName = 'ChevronTripleRight';
ChevronTripleRight.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ChevronUp = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 9.232l10.884 10.884-1.768 1.768L16 12.768l-9.116 9.116-1.768-1.768L16 9.232z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 4.44l5.53 5.53-1.06 1.06L8 6.56l-4.47 4.47-1.06-1.06L8 4.44z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.293 15.293l1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586l-8.707 8.707z"
    }));
  })());
};
ChevronUp.displayName = 'ChevronUp';
ChevronUp.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleArrowDown = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 17.982V10h2.5v7.982l3.866-3.866 1.768 1.768L16 22.768l-6.884-6.884 1.768-1.768 3.866 3.866z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 8.69V5h1.5v3.69l1.72-1.72 1.06 1.06L8 11.56 4.47 8.03l1.06-1.06 1.72 1.72z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 13.586l-3.293-3.293-1.414 1.414L12 17.414l5.707-5.707-1.414-1.414L13 13.586V7h-2v6.586z"
    }));
  })());
};
CircleArrowDown.displayName = 'CircleArrowDown';
CircleArrowDown.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleArrowLeft = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.018 17.25H22v-2.5h-7.982l3.866-3.866-1.768-1.768L9.232 16l6.884 6.884 1.768-1.768-3.866-3.866z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.31 8.75H11v-1.5H7.31l1.72-1.72-1.06-1.06L4.44 8l3.53 3.53 1.06-1.06-1.72-1.72z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.414 13l3.293 3.293-1.414 1.414L6.586 12l5.707-5.707 1.414 1.414L10.414 11H17v2h-6.586z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    }));
  })());
};
CircleArrowLeft.displayName = 'CircleArrowLeft';
CircleArrowLeft.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleArrowRight = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17.982 17.25H10v-2.5h7.982l-3.866-3.866 1.768-1.768L22.768 16l-6.884 6.884-1.768-1.768 3.866-3.866z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.69 8.75H5v-1.5h3.69L6.97 5.53l1.06-1.06L11.56 8l-3.53 3.53-1.06-1.06 1.72-1.72z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.414 12l-5.707-5.707-1.414 1.414L13.586 11H7v2h6.586l-3.293 3.293 1.414 1.414L17.414 12z"
    }));
  })());
};
CircleArrowRight.displayName = 'CircleArrowRight';
CircleArrowRight.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleArrowUp = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 14.018V22h2.5v-7.982l3.866 3.866 1.768-1.768L16 9.232l-6.884 6.884 1.768 1.768 3.866-3.866z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 7.31V11h1.5V7.31l1.72 1.72 1.06-1.06L8 4.44 4.47 7.97l1.06 1.06 1.72-1.72z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 10.414l-3.293 3.293-1.414-1.414L12 6.586l5.707 5.707-1.414 1.414L13 10.414V17h-2v-6.586z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    }));
  })());
};
CircleArrowUp.displayName = 'CircleArrowUp';
CircleArrowUp.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleCheck = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22.884 12.384L14 21.268l-4.884-4.884 1.768-1.768L14 17.732l7.116-7.116 1.768 1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.47 5.47l1.06 1.06L7 11.06 4.47 8.53l1.06-1.06L7 8.94l3.47-3.47z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.207 9.207L10.5 15.914l-3.707-3.707 1.414-1.414 2.293 2.293 5.293-5.293 1.414 1.414z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    }));
  })());
};
CircleCheck.displayName = 'CircleCheck';
CircleCheck.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleCheckInner = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22.884 12.384L14 21.268l-4.884-4.884 1.768-1.768L14 17.732l7.116-7.116 1.768 1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.47 5.47l1.06 1.06L7 11.06 4.47 8.53l1.06-1.06L7 8.94l3.47-3.47z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.207 9.207L10.5 15.914l-3.707-3.707 1.414-1.414 2.293 2.293 5.293-5.293 1.414 1.414z"
    }));
  })());
};
CircleCheckInner.displayName = 'CircleCheckInner';
CircleCheckInner.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleClose = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17.768 16l4.116-4.116-1.768-1.768L16 14.232l-4.116-4.116-1.768 1.768L14.232 16l-4.116 4.116 1.768 1.768L16 17.768l4.116 4.116 1.768-1.768L17.768 16z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.5 8a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zM15 8A7 7 0 111 8a7 7 0 0114 0zm-3.97-1.97L9.06 8l1.97 1.97-1.06 1.06L8 9.06l-1.97 1.97-1.06-1.06L6.94 8 4.97 6.03l1.06-1.06L8 6.94l1.97-1.97 1.06 1.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.586 11.5L7.793 8.707l1.414-1.414L12 10.086l2.793-2.793 1.414 1.414-2.793 2.793 2.793 2.793-1.414 1.414L12 12.914l-2.793 2.793-1.414-1.414 2.793-2.793z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 19.5a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
    }));
  })());
};
CircleClose.displayName = 'CircleClose';
CircleClose.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleCloseInner = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17.768 16l4.116-4.116-1.768-1.768L16 14.232l-4.116-4.116-1.768 1.768L14.232 16l-4.116 4.116 1.768 1.768L16 17.768l4.116 4.116 1.768-1.768L17.768 16z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.06 8l1.97-1.97-1.06-1.06L8 6.94 6.03 4.97 4.97 6.03 6.94 8 4.97 9.97l1.06 1.06L8 9.06l1.97 1.97 1.06-1.06L9.06 8z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.586 11.5L7.793 8.707l1.414-1.414L12 10.086l2.793-2.793 1.414 1.414-2.793 2.793 2.793 2.793-1.414 1.414L12 12.914l-2.793 2.793-1.414-1.414 2.793-2.793z"
    }));
  })());
};
CircleCloseInner.displayName = 'CircleCloseInner';
CircleCloseInner.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CircleEmpty = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    }));
  })());
};
CircleEmpty.displayName = 'CircleEmpty';
CircleEmpty.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CirclePlus = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 14.75H9v2.5h5.75V23h2.5v-5.75H23v-2.5h-5.75V9h-2.5v5.75z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 7.25H4.5v1.5h2.75v2.75h1.5V8.75h2.75v-1.5H8.75V4.5h-1.5v2.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 13v4h2v-4h4v-2h-4V7h-2v4H7v2h4z"
    }));
  })());
};
CirclePlus.displayName = 'CirclePlus';
CirclePlus.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Clock = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 8h2.5v7.176l6.242 2.675-.984 2.298-7.758-3.325V8z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 4h1.5v3.505l3.045 1.306-.59 1.378L7.25 8.495V4z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 6h2v5.28l5.316 1.771-.632 1.898L11 12.72V6z"
    }));
  })());
};
Clock.displayName = 'Clock';
Clock.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Close = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.232 16L7.116 8.884l1.768-1.768L16 14.232l7.116-7.116 1.768 1.768L17.768 16l7.116 7.116-1.768 1.768L16 17.768l-7.116 7.116-1.768-1.768L14.232 16z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.06 8l4.47 4.47-1.06 1.06L8 9.06l-4.47 4.47-1.06-1.06L6.94 8 2.47 3.53l1.06-1.06L8 6.94l4.47-4.47 1.06 1.06L9.06 8z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.586 12l-5.293 5.293 1.414 1.414L12 13.414l5.293 5.293 1.414-1.414L13.414 12l5.293-5.293-1.414-1.414L12 10.586 6.707 5.293 5.293 6.707 10.586 12z"
    }));
  })());
};
Close.displayName = 'Close';
Close.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Coffee = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M21.128 8.727l2.06-6.34-2.377-.773-2.06 6.34 2.377.773zm5.122 3.023H5A3.25 3.25 0 001.75 15v4A3.25 3.25 0 005 22.25h1.75V25A4.25 4.25 0 0011 29.25h11A4.25 4.25 0 0026.25 25V11.75zm-19.5 8H5a.75.75 0 01-.75-.75v-4a.75.75 0 01.75-.75h1.75v5.5zm2.5-5.5h14.5V25A1.75 1.75 0 0122 26.75H11A1.75 1.75 0 019.25 25V14.25zm7.938-11.864l-2.06 6.34-2.377-.772 2.06-6.34 2.377.772z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.604 4.376l1.03-3.17-1.268-.412-1.03 3.17 1.268.412zm2.563 1.457H2.5C1.58 5.833.833 6.58.833 7.5v2c0 .92.747 1.667 1.667 1.667h.833V12.5c0 1.197.97 2.167 2.167 2.167H11c1.197 0 2.167-.97 2.167-2.167V5.833zm-9.834 4H2.5a.333.333 0 01-.333-.333v-2c0-.184.15-.333.333-.333h.833v2.666zm1.334-2.666h7.167V12.5c0 .46-.374.833-.834.833H5.5a.833.833 0 01-.833-.833V7.167zm3.967-5.961l-1.03 3.17-1.268-.412 1.03-3.17 1.268.412z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.906 6.564l1.545-4.755-1.902-.618-1.545 4.755 1.902.618zM20 9H3.5A2.5 2.5 0 001 11.5V14a2.5 2.5 0 002.5 2.5H5V19a3 3 0 003 3h9a3 3 0 003-3V9zM5 14.5H3.5A.5.5 0 013 14v-2.5a.5.5 0 01.5-.5H5v3.5zM7 11h11v8a1 1 0 01-1 1H8a1 1 0 01-1-1v-8zm5.951-9.191l-1.545 4.755-1.902-.618 1.545-4.755 1.902.618z"
    }));
  })());
};
Coffee.displayName = 'Coffee';
Coffee.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CoffeeAlt = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.207 15.25l2.102 6.628 2.383-.756-1.862-5.872h4.645l-2.5 12.5h-7.95l-2.5-12.5h5.682zm1.83-2.5h5.709a6.75 6.75 0 00-7.747-6.426l2.038 6.426zm-4.4-5.604l1.777 5.604h-5.16a6.748 6.748 0 013.383-5.604zm-.768-2.424a9.25 9.25 0 00-5.116 8.028H4v2.5h2.975l3 15h12.05l3-15H28v-2.5h-2.753a9.25 9.25 0 00-11.01-8.832L13.192.622l-2.383.756 1.06 3.344z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.392 7.75l.89 2.966 1.436-.431-.76-2.535h2.127l-1.2 6h-3.77l-1.2-6h2.477zm1.116-1.5h2.733a3.25 3.25 0 00-3.626-2.977l.893 2.977zM6.204 3.79l.738 2.46H4.76a3.248 3.248 0 011.445-2.46zM5.76 2.31a4.75 4.75 0 00-2.504 3.94H2v1.5h1.385l1.5 7.5h6.23l1.5-7.5H14v-1.5h-1.257A4.75 4.75 0 007.18 1.82L6.72.285 5.281.716 5.76 2.31z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11.336 12l1.712 5.307 1.904-.614L13.438 12h3.364l-1.637 9h-6.33l-1.637-9h4.138zm1.457-2H17a5 5 0 00-5.8-4.936L12.793 10zM9.328 5.773L10.69 10H7c0-1.779.929-3.34 2.328-4.227zm-.629-1.948A6.999 6.999 0 005 10H3v2h2.165l2 11h9.67l2-11H21v-2h-2a7 7 0 00-8.419-6.856l-.63-1.951-1.903.614.651 2.018z"
    }));
  })());
};
CoffeeAlt.displayName = 'CoffeeAlt';
CoffeeAlt.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Cog = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 4.894l-9.5 5.542v11.128l9.5 5.542 9.5-5.542V10.436L16 4.894zM28 9L16 2 4 9v14l12 7 12-7V9z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 13.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM10.75 16a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 2.737L3.5 5.362v5.276L8 13.263l4.5-2.625V5.362L8 2.737zM14 4.5L8 1 2 4.5v7L8 15l6-3.5v-7z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8 11a3 3 0 100-6 3 3 0 000 6z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 14a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 100-8 4 4 0 000 8z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 16.101l7 4.084 7-4.084V7.9l-7-4.084L5 7.9V16.1zM3 6.75v10.5l9 5.25 9-5.25V6.75L12 1.5 3 6.75z"
    }));
  })());
};
Cog.displayName = 'Cog';
Cog.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Connect = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.75 7A4.25 4.25 0 0114 2.75h11A4.25 4.25 0 0129.25 7v11A4.25 4.25 0 0125 22.25H14A4.25 4.25 0 019.75 18v-5.75H7A1.75 1.75 0 005.25 14v11c0 .966.784 1.75 1.75 1.75h11A1.75 1.75 0 0019.75 25h2.5A4.25 4.25 0 0118 29.25H7A4.25 4.25 0 012.75 25V14A4.25 4.25 0 017 9.75h2.75V7zM14 5.25A1.75 1.75 0 0012.25 7v11c0 .966.784 1.75 1.75 1.75h5.75V14A1.75 1.75 0 0018 12.25h-3v-2.5h3A4.25 4.25 0 0122.25 14v5.75H25A1.75 1.75 0 0026.75 18V7A1.75 1.75 0 0025 5.25H14z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.75 3.5A2.25 2.25 0 017 1.25h5.5a2.25 2.25 0 012.25 2.25V9a2.25 2.25 0 01-2.25 2.25H7A2.25 2.25 0 014.75 9V6.25H3.5a.75.75 0 00-.75.75v5.5c0 .414.336.75.75.75H9a.75.75 0 00.75-.75h1.5A2.25 2.25 0 019 14.75H3.5a2.25 2.25 0 01-2.25-2.25V7A2.25 2.25 0 013.5 4.75h1.25V3.5zM7 2.75a.75.75 0 00-.75.75V9c0 .414.336.75.75.75h2.75V7A.75.75 0 009 6.25H7.5v-1.5H9A2.25 2.25 0 0111.25 7v2.75h1.25a.75.75 0 00.75-.75V3.5a.75.75 0 00-.75-.75H7z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 5a3 3 0 013-3h9a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3V9H5a1 1 0 00-1 1v9a1 1 0 001 1h9a1 1 0 001-1h2a3 3 0 01-3 3H5a3 3 0 01-3-3v-9a3 3 0 013-3h2V5zm3-1a1 1 0 00-1 1v9a1 1 0 001 1h5v-5a1 1 0 00-1-1h-3V7h3a3 3 0 013 3v5h2a1 1 0 001-1V5a1 1 0 00-1-1h-9z"
    }));
  })());
};
Connect.displayName = 'Connect';
Connect.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Copy = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.5 6.29A4.001 4.001 0 006 10v14a4 4 0 004 4h10a4.001 4.001 0 003.71-2.5H10A1.5 1.5 0 018.5 24V6.29z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22 6.5h-7A1.5 1.5 0 0013.5 8v11a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V8A1.5 1.5 0 0022 6.5zM15 4h7a4 4 0 014 4v11a4 4 0 01-4 4h-7a4 4 0 01-4-4V8a4 4 0 014-4z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.5 3.063A2 2 0 003 5v7a2 2 0 002 2h5a2 2 0 001.937-1.5H5.25a.75.75 0 01-.75-.75V3.063z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11 3.5H8a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V4a.5.5 0 00-.5-.5zM8 2h3a2 2 0 012 2v5a2 2 0 01-2 2H8a2 2 0 01-2-2V4a2 2 0 012-2z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 6.17A3.001 3.001 0 003 9v10a3 3 0 003 3h8a3.001 3.001 0 002.83-2H6a1 1 0 01-1-1V6.17z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 5a3 3 0 013-3h8a3 3 0 013 3v10a3 3 0 01-3 3h-8a3 3 0 01-3-3V5zm3-1a1 1 0 00-1 1v10a1 1 0 001 1h8a1 1 0 001-1V5a1 1 0 00-1-1h-8z"
    }));
  })());
};
Copy.displayName = 'Copy';
Copy.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CreditCard = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.75 10A4.25 4.25 0 017 5.75h18A4.25 4.25 0 0129.25 10v12A4.25 4.25 0 0125 26.25H7A4.25 4.25 0 012.75 22V10zM7 8.25A1.75 1.75 0 005.25 10v2.75h21.5V10A1.75 1.75 0 0025 8.25H7zm19.75 7H5.25V22c0 .966.784 1.75 1.75 1.75h18A1.75 1.75 0 0026.75 22v-6.75z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14 21.25H8v-2.5h6v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.25 5A2.25 2.25 0 013.5 2.75h9A2.25 2.25 0 0114.75 5v6a2.25 2.25 0 01-2.25 2.25h-9A2.25 2.25 0 011.25 11V5zm2.25-.75a.75.75 0 00-.75.75v1.25h10.5V5a.75.75 0 00-.75-.75h-9zm9.75 3.5H2.75V11c0 .414.336.75.75.75h9a.75.75 0 00.75-.75V7.75z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6 10.75H4v-1.5h2v1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 7a3 3 0 013-3h14a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V7zm3-1a1 1 0 00-1 1v2h16V7a1 1 0 00-1-1H5zm15 5H4v6a1 1 0 001 1h14a1 1 0 001-1v-6z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9 16H6v-2h3v2z"
    }));
  })());
};
CreditCard.displayName = 'CreditCard';
CreditCard.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const CreditCardPlus = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.75 10A4.25 4.25 0 016 5.75h18A4.25 4.25 0 0128.25 10v7h-2.5v-1.75H4.25V22c0 .966.784 1.75 1.75 1.75h13v2.5H6A4.25 4.25 0 011.75 22V10zm2.5 2.75h21.5V10A1.75 1.75 0 0024 8.25H6A1.75 1.75 0 004.25 10v2.75z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14 21.25H8v-2.5h6v2.5z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M25.75 23.75V20h2.5v3.75H32v2.5h-3.75V30h-2.5v-3.75H22v-2.5h3.75z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M.75 5A2.25 2.25 0 013 2.75h9A2.25 2.25 0 0114.25 5v2.75h-12V11c0 .414.336.75.75.75h6v1.5H3A2.25 2.25 0 01.75 11V5zm1.5 1.25h10.5V5a.75.75 0 00-.75-.75H3a.75.75 0 00-.75.75v1.25z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.5 10.75h-2v-1.5h2v1.5z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12.75 11.75V10h1.5v1.75H16v1.5h-1.75V15h-1.5v-1.75H11v-1.5h1.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M1 7a3 3 0 013-3h14a3 3 0 013 3v6h-2v-2H3v6a1 1 0 001 1h10v2H4a3 3 0 01-3-3V7zm2 2h16V7a1 1 0 00-1-1H4a1 1 0 00-1 1v2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 16H5v-2h3v2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 18v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3z"
    }));
  })());
};
CreditCardPlus.displayName = 'CreditCardPlus';
CreditCardPlus.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Delete = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.482 5.75H29.25v20.5H11.482l-8.659-8.659a2.25 2.25 0 010-3.182l8.66-8.659zm1.036 2.5L4.768 16l7.75 7.75H26.75V8.25H12.518z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.232 16l-3.116-3.116 1.768-1.768L18 14.232l3.116-3.116 1.768 1.768L19.768 16l3.116 3.116-1.768 1.768L18 17.768l-3.116 3.116-1.768-1.768L16.232 16z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.69 2.75h9.06v10.5H5.69L1.322 8.884a1.25 1.25 0 010-1.768L5.69 2.75zm.62 1.5L2.56 8l3.75 3.75h6.94v-7.5H6.31z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.94 8L6.47 6.53l1.06-1.06L9 6.94l1.47-1.47 1.06 1.06L10.06 8l1.47 1.47-1.06 1.06L9 9.06l-1.47 1.47-1.06-1.06L7.94 8z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.76 6l-5.909 5.253a1 1 0 000 1.494L8.761 18H20V6H8.76zM8 4L1.523 9.758a3 3 0 000 4.484L8 20h14V4H8z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11.586 12L9.293 9.707l1.414-1.414L13 10.586l2.293-2.293 1.414 1.414L14.414 12l2.293 2.293-1.414 1.414L13 13.414l-2.293 2.293-1.414-1.414L11.586 12z"
    }));
  })());
};
Delete.displayName = 'Delete';
Delete.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const DiamondEmpty = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M26.11 15.646L16.355 5.89a.5.5 0 00-.707 0l-9.758 9.757a.5.5 0 000 .707l9.757 9.758a.5.5 0 00.708 0l9.757-9.757a.5.5 0 000-.708zM18.122 4.121a3 3 0 00-4.242 0L4.12 13.88a3 3 0 000 4.242l9.758 9.758a3 3 0 004.242 0l9.758-9.758a3 3 0 000-4.242L18.12 4.12z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12.879 8L8 3.121 3.121 8 8 12.879 12.879 8zM9.06 2.06a1.5 1.5 0 00-2.122 0L2.061 6.94a1.5 1.5 0 000 2.12l4.878 4.88a1.5 1.5 0 002.122 0l4.878-4.88a1.5 1.5 0 000-2.12L9.061 2.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20.172 12L12 3.828 3.828 12 12 20.172 20.172 12zm-6.758-9.586a2 2 0 00-2.828 0l-8.172 8.172a2 2 0 000 2.828l8.172 8.172a2 2 0 002.828 0l8.172-8.172a2 2 0 000-2.828l-8.172-8.172z"
    }));
  })());
};
DiamondEmpty.displayName = 'DiamondEmpty';
DiamondEmpty.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const DiamondFull = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.354 5.89l9.757 9.756a.5.5 0 010 .708l-9.758 9.757a.5.5 0 01-.707 0L5.89 16.354a.5.5 0 010-.707l9.758-9.758a.5.5 0 01.707 0zM13.879 4.12a3 3 0 014.242 0l9.758 9.758a3 3 0 010 4.242L18.12 27.88a3 3 0 01-4.242 0L4.12 18.12a3 3 0 010-4.242L13.88 4.12zM16 23.501l7.5-7.5L16 8.5 8.5 16l7.5 7.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 3.121L12.88 8 8 12.879 3.122 8 8 3.121zm-1.06-1.06a1.5 1.5 0 012.12 0l4.88 4.878a1.5 1.5 0 010 2.122l-4.88 4.878a1.5 1.5 0 01-2.12 0L2.06 9.061a1.5 1.5 0 010-2.122l4.88-4.878zM8 11l3-3-3-3-3 3 3 3z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 3.828L20.172 12 12 20.172 3.83 12 12 3.828zm-1.414-1.414a2 2 0 012.828 0l8.172 8.172a2 2 0 010 2.828l-8.172 8.172a2 2 0 01-2.828 0l-8.172-8.172a2 2 0 010-2.828l8.172-8.172zM12 17.5l5.5-5.5L12 6.5 6.5 12l5.5 5.5z"
    }));
  })());
};
DiamondFull.displayName = 'DiamondFull';
DiamondFull.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const DiamondHalf = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.354 5.89l9.757 9.756a.5.5 0 010 .708l-9.758 9.757a.5.5 0 01-.707 0L5.89 16.354a.5.5 0 010-.707l9.758-9.758a.5.5 0 01.707 0zM13.879 4.12a3 3 0 014.242 0l9.758 9.758a3 3 0 010 4.242L18.12 27.88a3 3 0 01-4.242 0L4.12 18.12a3 3 0 010-4.242L13.88 4.12zM16 23.501l7.5-7.5L16 8.5v15z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 3.121L12.88 8 8 12.879 3.122 8 8 3.121zm-1.06-1.06a1.5 1.5 0 012.12 0l4.88 4.878a1.5 1.5 0 010 2.122l-4.88 4.878a1.5 1.5 0 01-2.12 0L2.06 9.061a1.5 1.5 0 010-2.122l4.88-4.878zM8 11l3-3-3-3v6z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 3.828L20.172 12 12 20.172 3.83 12 12 3.828zm-1.414-1.414a2 2 0 012.828 0l8.172 8.172a2 2 0 010 2.828l-8.172 8.172a2 2 0 01-2.828 0l-8.172-8.172a2 2 0 010-2.828l8.172-8.172zM12 17.5l5.5-5.5L12 6.5v11z"
    }));
  })());
};
DiamondHalf.displayName = 'DiamondHalf';
DiamondHalf.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Download = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 17.982V3h2.5v14.982l7.866-7.866 1.768 1.768L16 22.768 5.116 11.884l1.768-1.768 7.866 7.866z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M26 28.25H6v-2.5h20v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 8.69V1.5h1.5v7.19l3.72-3.72 1.06 1.06L8 11.56 2.47 6.03l1.06-1.06 3.72 3.72z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13 14.25H3v-1.5h10v1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 13.586V4h2v9.586l4.293-4.293 1.414 1.414L12 17.414l-6.707-6.707 1.414-1.414L11 13.586z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20 21H4v-2h16v2z"
    }));
  })());
};
Download.displayName = 'Download';
Download.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Email = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.75 10A4.25 4.25 0 017 5.75h18A4.25 4.25 0 0129.25 10v12A4.25 4.25 0 0125 26.25H7A4.25 4.25 0 012.75 22V10zm2.506-.143L16 14.632l10.744-4.775A1.75 1.75 0 0025 8.25H7a1.75 1.75 0 00-1.744 1.607zM26.75 12.59L16 17.368 5.25 12.59V22c0 .966.784 1.75 1.75 1.75h18A1.75 1.75 0 0026.75 22v-9.41z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.25 5A2.25 2.25 0 013.5 2.75h9A2.25 2.25 0 0114.75 5v6a2.25 2.25 0 01-2.25 2.25h-9A2.25 2.25 0 011.25 11V5zm1.515-.148L8 7.18l5.236-2.327a.75.75 0 00-.736-.602h-9a.75.75 0 00-.735.602zM13.25 6.487L8 8.821 2.75 6.487V11c0 .414.336.75.75.75h9a.75.75 0 00.75-.75V6.487z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 6H5a1 1 0 00-1 1v10a1 1 0 001 1h14a1 1 0 001-1V7a1 1 0 00-1-1zM5 4a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3H5z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 6a1 1 0 00-1 1v.35l8 3.556 8-3.556V7a1 1 0 00-1-1H5zM2 7a3 3 0 013-3h14a3 3 0 013 3v1.65l-10 4.444L2 8.65V7z"
    }));
  })());
};
Email.displayName = 'Email';
Email.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const EmotionDisappointed = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 5.25C10.063 5.25 5.25 10.063 5.25 16S10.063 26.75 16 26.75 26.75 21.937 26.75 16 21.937 5.25 16 5.25zM2.75 16C2.75 8.682 8.682 2.75 16 2.75S29.25 8.682 29.25 16 23.318 29.25 16 29.25 2.75 23.318 2.75 16z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.875 10h2.25v5.875H18.5v2.25h-4.625V10z"
      }), React.createElement("path", {
        d: "M12 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M23.5 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.996 19.875a6.459 6.459 0 015.112 2.482l-1.773 1.385a4.208 4.208 0 00-6.65.024l-1.783-1.373a6.46 6.46 0 015.094-2.518z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 2.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM1.25 8a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6.875 5h1.25v2.875H9.25v1.25H6.875V5z"
      }), React.createElement("path", {
        d: "M6 6.375a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M11.75 6.375a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.998 9.875a3.291 3.291 0 012.605 1.265l-.985.77a2.042 2.042 0 00-3.226.01l-.99-.762a3.292 3.292 0 012.596-1.283z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 4a8 8 0 100 16 8 8 0 000-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.625 7.5h1.75v4.625H14v1.75h-3.375V7.5z"
    }), React.createElement("path", {
      d: "M9 9.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M17.5 9.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11.983 15.125a4.874 4.874 0 013.859 1.874l-1.38 1.077a3.124 3.124 0 00-4.938.017l-1.386-1.067a4.875 4.875 0 013.845-1.901z"
    }));
  })());
};
EmotionDisappointed.displayName = 'EmotionDisappointed';
EmotionDisappointed.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const EmotionExpressionless = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 5.25C10.063 5.25 5.25 10.063 5.25 16S10.063 26.75 16 26.75 26.75 21.937 26.75 16 21.937 5.25 16 5.25zM2.75 16C2.75 8.682 8.682 2.75 16 2.75S29.25 8.682 29.25 16 23.318 29.25 16 29.25 2.75 23.318 2.75 16z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.875 10h2.25v5.875H18.5v2.25h-4.625V10z"
      }), React.createElement("path", {
        d: "M12 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M23.5 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M21 23.125H11v-2.25h10v2.25z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 2.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM1.25 8a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6.875 5h1.25v2.875H9.25v1.25H6.875V5z"
      }), React.createElement("path", {
        d: "M6 6.375a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M11.75 6.375a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.5 11.625h-5v-1.25h5v1.25z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M10.375 7.5h1.75v4.625h1.625v1.75h-3.375V7.5z"
    }), React.createElement("path", {
      d: "M7.75 10.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }), React.createElement("path", {
      d: "M17.5 9.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M8 15.625v1.75h8v-1.75H8z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z"
    }));
  })());
};
EmotionExpressionless.displayName = 'EmotionExpressionless';
EmotionExpressionless.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const EmotionHushed = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 5.25C10.063 5.25 5.25 10.063 5.25 16S10.063 26.75 16 26.75 26.75 21.937 26.75 16 21.937 5.25 16 5.25zM2.75 16C2.75 8.682 8.682 2.75 16 2.75S29.25 8.682 29.25 16 23.318 29.25 16 29.25 2.75 23.318 2.75 16z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.875 10h2.25v5.875H18.5v2.25h-4.625V10z"
      }), React.createElement("path", {
        d: "M12 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M23.5 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M17 22a2 2 0 11-4 0 2 2 0 014 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 2.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM1.25 8a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6.875 5h1.25v2.875H9.25v1.25H6.875V5z"
      }), React.createElement("path", {
        d: "M6 6.375a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M11.75 6.375a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M8.25 11a1 1 0 11-2 0 1 1 0 012 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 4a8 8 0 100 16 8 8 0 000-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.625 7.5h1.75v4.625H14v1.75h-3.375V7.5z"
    }), React.createElement("path", {
      d: "M9 9.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M17.5 9.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M12.5 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
    }));
  })());
};
EmotionHushed.displayName = 'EmotionHushed';
EmotionHushed.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const EmotionSlightlySmiling = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 5.25C10.063 5.25 5.25 10.063 5.25 16S10.063 26.75 16 26.75 26.75 21.937 26.75 16 21.937 5.25 16 5.25zM2.75 16C2.75 8.682 8.682 2.75 16 2.75S29.25 8.682 29.25 16 23.318 29.25 16 29.25 2.75 23.318 2.75 16z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.875 10h2.25v5.875H18.5v2.25h-4.625V10z"
      }), React.createElement("path", {
        d: "M12 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M23.5 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M23.345 19.474A8.125 8.125 0 0116 24.125v-2.25a5.875 5.875 0 005.311-3.363l2.034.962z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 2.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM1.25 8a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6.875 5h1.25v2.875H9.25v1.25H6.875V5z"
      }), React.createElement("path", {
        d: "M6 6.375a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M11.75 6.375a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.729 9.764A4.125 4.125 0 018 12.125v-1.25a2.875 2.875 0 002.599-1.646l1.13.535z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M10.375 7.5h1.75v4.625h1.625v1.75h-3.375V7.5z"
    }), React.createElement("path", {
      d: "M7.75 10.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }), React.createElement("path", {
      d: "M17.5 9.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M17.653 14.26a6 6 0 01-5.668 3.99l.004-1.718a4.282 4.282 0 004.045-2.847l1.62.575z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z"
    }));
  })());
};
EmotionSlightlySmiling.displayName = 'EmotionSlightlySmiling';
EmotionSlightlySmiling.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const EmotionSmiley = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 22.75c1.26 0 2.495-.757 3.186-2h-6.372c.691 1.243 1.925 2 3.186 2zm-5.63-2A6.972 6.972 0 0110 18.5h12c0 .79-.13 1.549-.37 2.25C20.787 23.231 18.585 25 16 25s-4.786-1.769-5.63-4.25z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 5.25C10.063 5.25 5.25 10.063 5.25 16S10.063 26.75 16 26.75 26.75 21.937 26.75 16 21.937 5.25 16 5.25zM2.75 16C2.75 8.682 8.682 2.75 16 2.75S29.25 8.682 29.25 16 23.318 29.25 16 29.25 2.75 23.318 2.75 16z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.875 9.5h2.25v5.375H18v2.25h-4.125V9.5z"
      }), React.createElement("path", {
        d: "M12 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }), React.createElement("path", {
        d: "M23.5 12.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M6 5.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        d: "M11.75 5.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 11c.515 0 1.03-.277 1.367-.75H6.633c.337.473.852.75 1.367.75zm-2.77-.75A3.476 3.476 0 015 9h6c0 .443-.082.865-.23 1.25-.452 1.175-1.522 2-2.77 2s-2.318-.825-2.77-2z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 2.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM1.25 8a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6.875 4.5h1.25v2.375H9.25v1.25H6.875V4.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 4a8 8 0 100 16 8 8 0 000-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.625 7h1.75v3.625H14v1.75h-3.375V7z"
    }), React.createElement("path", {
      d: "M9 9a1.25 1.25 0 11-2.5 0A1.25 1.25 0 019 9z"
    }), React.createElement("path", {
      d: "M17.5 9A1.25 1.25 0 1115 9a1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 16.75c.89 0 1.803-.556 2.32-1.5H9.68c.517.944 1.43 1.5 2.32 1.5zm-4.217-1.5A5.486 5.486 0 017.5 13.5h9c0 .616-.1 1.205-.283 1.75-.639 1.898-2.286 3.25-4.217 3.25-1.931 0-3.578-1.352-4.217-3.25z"
    }));
  })());
};
EmotionSmiley.displayName = 'EmotionSmiley';
EmotionSmiley.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Expenses = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M15.992 2.75h-.126.126z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.867 2.75l-1.232.011.12 13.894 11.382 7.969.62-.887A13.19 13.19 0 0029.25 16c0-7.318-5.932-13.25-13.25-13.25m1.244 12.595l-.087-10.033A10.75 10.75 0 0125.463 21.1l-8.219-5.755z"
      }), React.createElement("path", {
        d: "M16 26.75a10.7 10.7 0 006.152-1.933l2.17 1.495A13.194 13.194 0 0116 29.25C8.682 29.25 2.75 23.318 2.75 16c0-6.015 4.008-11.094 9.5-12.712v2.634c-4.088 1.522-7 5.46-7 10.078 0 5.937 4.813 10.75 10.75 10.75z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M8 13.25c.953 0 1.847-.254 2.617-.698l1.308.94A6.75 6.75 0 115.75 1.634v1.621A5.25 5.25 0 008 13.25z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.475 11.948A6.75 6.75 0 007.933 1.25l-.742.007.062 7.136 5.667 3.968.143.103.002-.002.034.024.376-.538zm-4.729-4.34l-.041-4.81a5.25 5.25 0 013.981 7.568l-3.94-2.759z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 2h-1v.05s.001 0 0 0v10.465l8.56 6.106.513-.719a9.952 9.952 0 001.925-6.108 9.962 9.962 0 00-3.256-7.18A9.962 9.962 0 0012 2zm7.998 9.813a8.319 8.319 0 01-.019.762 8 8 0 01-.936 3.22L13 11.485V4.063a8 8 0 016.998 7.75z"
    }), React.createElement("path", {
      d: "M12 20a7.963 7.963 0 004.314-1.261l1.725 1.232A9.957 9.957 0 0112 22C6.477 22 2 17.523 2 12c0-4.478 2.943-8.268 7-9.542v2.124A8.003 8.003 0 0012 20z"
    }));
  })());
};
Expenses.displayName = 'Expenses';
Expenses.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Export = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M28 8a5.5 5.5 0 01-9.158 4.107L14.6 14.937a5.517 5.517 0 01.26 3.302l3.396 2.264a5.5 5.5 0 11-1.116 2.26l-3.396-2.264a5.5 5.5 0 11-.586-7.606l4.242-2.828A5.5 5.5 0 1128 8zm-2.5 0a3 3 0 11-6 0 3 3 0 016 0zm-16 12a3 3 0 100-6 3 3 0 000 6zm16 4a3 3 0 11-6 0 3 3 0 016 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14 4a2.75 2.75 0 01-4.48 2.138l-2.175 1.45a2.746 2.746 0 01.112 1.399l1.753 1.168a2.75 2.75 0 11-.667 1.358L6.79 10.345a2.75 2.75 0 11-.31-3.982l2.175-1.45A2.75 2.75 0 1114 4zm-1.5 0A1.25 1.25 0 1110 4a1.25 1.25 0 012.5 0zM4.75 9.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM12.5 12a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M21 6a4 4 0 01-6.912 2.743l-3.278 2.035a3.997 3.997 0 010 2.444l3.278 2.035a4 4 0 11-.965 1.755l-3.382-2.099a4 4 0 110-5.826l3.382-2.099A4 4 0 1121 6zm-2 0a2 2 0 11-4 0 2 2 0 014 0zM7 14a2 2 0 100-4 2 2 0 000 4zm12 4a2 2 0 11-4 0 2 2 0 014 0z"
    }));
  })());
};
Export.displayName = 'Export';
Export.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Eye = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M20 16a4 4 0 11-8 0 4 4 0 018 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 6.052V2h2.5v4.052A14.44 14.44 0 0124.3 8.62l2.724-3.404 1.952 1.562-2.76 3.45c.992.994 1.848 2.14 2.53 3.416L30 15.998l-1.255 2.353a14.444 14.444 0 01-25.49 0L2 15.998l1.255-2.353a14.432 14.432 0 012.53-3.415L3.024 6.778l1.952-1.562L7.7 8.62a14.44 14.44 0 017.051-2.57zm-9.917 9.946l.628 1.176a11.944 11.944 0 0021.078 0l.628-1.176-.628-1.177a11.944 11.944 0 00-21.078 0l-.628 1.177z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M10 7.999a2 2 0 11-4 0 2 2 0 014 0z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 3.038V1h1.5v2.038a7.22 7.22 0 013.297 1.201l1.367-1.709 1.172.937-1.39 1.738a7.22 7.22 0 011.177 1.617L15 8l-.627 1.176a7.222 7.222 0 01-12.746 0L1 8l.627-1.177c.32-.6.718-1.142 1.177-1.618l-1.39-1.737 1.172-.937 1.367 1.71A7.22 7.22 0 017.25 3.037zM2.7 7.998l.251.471a5.722 5.722 0 0010.098 0L13.3 8l-.251-.47a5.722 5.722 0 00-10.098 0l-.251.47z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.666 7.36L3.188 5.306l1.624-1.17 1.446 2.01A10.827 10.827 0 0111 4.547V2.5h2v2.046c1.705.158 3.325.715 4.742 1.6l1.447-2.008 1.622 1.169-1.477 2.052a10.822 10.822 0 012.225 2.876L22.5 12l-.941 1.765a10.833 10.833 0 01-19.118 0L1.5 12l.941-1.765A10.823 10.823 0 014.666 7.36zm-.9 4.64l.44.823a8.833 8.833 0 0015.588 0l.44-.823-.44-.824a8.833 8.833 0 00-15.588 0l-.44.824z"
    }));
  })());
};
Eye.displayName = 'Eye';
Eye.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const EyeClosed = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.96 13.2c1.019 1.222 2.662 3.106 4.298 4.057 1.653.96 3.672 1.49 5.744 1.493 2.069.003 4.055-.52 5.715-1.479 1.639-.946 3.305-2.85 4.323-4.071l1.92 1.6a32.828 32.828 0 01-2.185 2.41l3.202 4.01-1.954 1.56-3.145-3.94c-.298.218-.603.419-.911.597-1.713.988-3.676 1.588-5.717 1.761V26h-2.5v-4.805c-2.048-.177-4.035-.781-5.748-1.777a10.444 10.444 0 01-.875-.572L4.976 22.78 3.024 21.22l3.205-4.003c-.91-.896-1.676-1.8-2.189-2.416l1.92-1.6z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.076 7.02c.511.613 1.32 1.538 2.116 2 .806.469 1.793.729 2.809.73a5.595 5.595 0 002.795-.723c.797-.46 1.617-1.394 2.128-2.007l1.152.96c-.239.287-.595.708-1.02 1.134l1.53 1.918-1.172.936-1.499-1.877a5.094 5.094 0 01-.37.236 6.993 6.993 0 01-2.795.886V13.5h-1.5v-2.289c-1-.102-1.97-.405-2.812-.894a5.146 5.146 0 01-.35-.224l-1.502 1.876-1.171-.938 1.532-1.914A16.979 16.979 0 011.924 7.98l1.152-.96z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5.107 10.985c.736 1.223 1.457 2.229 2.618 2.904 1.23.714 2.732 1.11 4.276 1.111 1.542.002 3.02-.388 4.255-1.1 1.164-.673 1.904-1.694 2.637-2.915l1.715 1.03c-.438.729-.937 1.496-1.586 2.195l1.786 2.451-1.616 1.178-1.709-2.344c-.074.046-.15.092-.227.137A10.382 10.382 0 0113 16.956v3.294h-2v-3.296c-1.524-.14-3.002-.594-4.28-1.336a7.339 7.339 0 01-.245-.15l-1.657 2.357-1.636-1.15 1.76-2.502c-.631-.688-1.117-1.44-1.549-2.157l1.714-1.031z"
    }));
  })());
};
EyeClosed.displayName = 'EyeClosed';
EyeClosed.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const FaceId = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 2A5.25 5.25 0 002 7.25v2h2.5v-2A2.75 2.75 0 017.25 4.5h2V2h-2zm0 28A5.25 5.25 0 012 24.75v-2h2.5v2a2.75 2.75 0 002.75 2.75h2.667V30H7.25zM30 24.75c0 2.9-2.35 5.25-5.25 5.25h-2v-2.5h2a2.75 2.75 0 002.75-2.75v-2H30v2zM24.75 2C27.65 2 30 4.35 30 7.25v2.667h-2.5V7.25a2.75 2.75 0 00-2.75-2.75h-2.667V2h2.667zm-10.542 8h2.25v6.208h2.208v2.25h-4.458V10zm-3.958 4.5a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm13.25-1.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zm-3.018 9.919a7.625 7.625 0 002.77-3.813l-2.14-.695a5.375 5.375 0 01-10.224 0l-2.14.695a7.625 7.625 0 0011.734 3.813z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.75 1A2.75 2.75 0 001 3.75v1h1.5v-1c0-.69.56-1.25 1.25-1.25h1V1h-1zm0 14A2.75 2.75 0 011 12.25v-1h1.5v1c0 .69.56 1.25 1.25 1.25h1.333V15H3.75zM15 12.25A2.75 2.75 0 0112.25 15h-1v-1.5h1c.69 0 1.25-.56 1.25-1.25v-1H15v1zM12.25 1A2.75 2.75 0 0115 3.75v1.333h-1.5V3.75c0-.69-.56-1.25-1.25-1.25h-1.333V1h1.333zM7.042 8.667V5h1.25v3.042h1.041v1.25H7.041v-.625zM5.125 7.25a.875.875 0 100-1.75.875.875 0 000 1.75zm6.625-.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0zm-1.472 5.01a3.875 3.875 0 001.407-1.938l-1.189-.386a2.625 2.625 0 01-4.993 0l-1.188.386a3.875 3.875 0 005.963 1.938z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M2 6a4 4 0 014-4h2v2H6a2 2 0 00-2 2v2H2V6z"
    }), React.createElement("path", {
      d: "M2 18a4 4 0 004 4h2v-2H6a2 2 0 01-2-2v-2H2v2z"
    }), React.createElement("path", {
      d: "M18 22a4 4 0 004-4v-2h-2v2a2 2 0 01-2 2h-2v2h2z"
    }), React.createElement("path", {
      d: "M22 6a4 4 0 00-4-4h-2v2h2a2 2 0 012 2v2h2V6z"
    }), React.createElement("path", {
      d: "M10.375 7.5h1.75v4.625h1.625v1.75h-3.375V7.5z"
    }), React.createElement("path", {
      d: "M7.75 10.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }), React.createElement("path", {
      d: "M17.5 9.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M8.154 13.492a4.125 4.125 0 007.67.053l1.623.656a5.875 5.875 0 01-10.924-.077l1.631-.632z"
    }));
  })());
};
FaceId.displayName = 'FaceId';
FaceId.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const FastFood = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.226 7.135a21.5 21.5 0 00-8.961 7.693l2.571 1.712C11.31 11.388 17.198 8 23.88 8c.519 0 1.033.02 1.542.06l.411-2.733a21.5 21.5 0 00-11.606 1.808zm10.821 3.405l-.22 1.467a5.333 5.333 0 00-1.58 10.5l-.588 3.9-4.708-3.133a6 6 0 00-5.165-8.602A16.77 16.77 0 0123.88 10.5c.393 0 .782.014 1.168.04zm-10.87 13.224l2.237 1.49 8.131 5.413 4.121-27.372A24 24 0 001.915 15.602l4.659 3.101 2.485 1.654 5.118 3.407zm-4.005-5.67l5.663 3.77a3.5 3.5 0 00-5.663-3.77zm13.45 1.919a2.834 2.834 0 01.829-5.511l-.83 5.51z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.218 3.794A10.5 10.5 0 002.98 7.346l1.431.952a9.654 9.654 0 018.005-3.996l.213-1.419a10.5 10.5 0 00-5.412.911zm6.694.65l-.223 1.484-.229 1.52-.544 3.613-.396 2.634-.247 1.638-1.379-.918-6.007-3.999-1.259-.838-2.67-1.777a12 12 0 0113.376-6.154l-.421 2.797zM5.662 9.13a8.155 8.155 0 016.53-3.33l-.116.766a2.5 2.5 0 10-.742 4.928l-.193 1.283L5.662 9.13zm5.897.867C11.54 10 11.52 10 11.5 10a1 1 0 11.35-1.937l-.29 1.935z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.31 18.94l-1.797-1.196-3.6-2.397-1.983-1.32-3.494-2.326A18 18 0 0121.5 2.471L18.41 23l-6.1-4.06zM10.722 5.464a16 16 0 00-6.6 5.622l1.826 1.216A14.484 14.484 0 0118.94 6.036l.29-1.932a16 16 0 00-8.509 1.36zm7.92 2.557l-.148.98L18.41 9a4 4 0 00-1.096 7.848l-.413 2.745-3.383-2.253a4.5 4.5 0 00-3.749-6.326 12.451 12.451 0 018.874-2.993zM7.835 13.558l3.974 2.645a2.501 2.501 0 00-3.974-2.645zm9.78 1.278a2 2 0 01.577-3.824l-.576 3.824z"
    }));
  })());
};
FastFood.displayName = 'FastFood';
FastFood.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const File = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.5 25V7a.5.5 0 01.5-.5h5.75v6a2.75 2.75 0 002.75 2.75h6V25a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5zm15-12.25v-.188l-6.25-6.027V12.5c0 .138.112.25.25.25h6zM9 4h9.222L26 11.5V25a3 3 0 01-3 3H9a3 3 0 01-3-3V7a3 3 0 013-3z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.75 3.5H4.5v9h7V7.25H9c-.69 0-1.25-.56-1.25-1.25V3.5zm2.879 2.25L9.25 4.371V5.75h1.379zM4.5 2H9l4 4v6.5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 013 12.5v-9A1.5 1.5 0 014.5 2z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 2a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3V8.511L13.585 2H7zm4.5 2H7a1 1 0 00-1 1v14a1 1 0 001 1h10a1 1 0 001-1v-8.5h-4.5a2 2 0 01-2-2V4zm2 4.5V4.763L17.181 8.5H13.5z"
    }));
  })());
};
File.displayName = 'File';
File.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Filter = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M26.5 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm2.5 0a5 5 0 01-9.944.75H3v-2.5h16.315A5.002 5.002 0 0129 10zM8 25a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm4.842-1.25a5 5 0 110-2.5H29v2.5H12.843z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.5 4.75a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm1.5 0a3 3 0 01-5.905.75H1.5V4h7.595A3.001 3.001 0 0115 4.75zm-11 8a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM6.905 12A3.001 3.001 0 011 11.25a3 3 0 015.905-.75H14.5V12H6.905z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20 7a2 2 0 11-4 0 2 2 0 014 0zm2 0a4 4 0 01-7.874 1H2V6h12.126A4.002 4.002 0 0122 7zM6 19a2 2 0 100-4 2 2 0 000 4zm3.874-1A4.002 4.002 0 012 17a4 4 0 017.874-1H22v2H9.874z"
    }));
  })());
};
Filter.displayName = 'Filter';
Filter.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Fingerprint = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.5 4.01c2.287-.089 4.553.382 6.57 1.356 2.015.975 3.7 2.411 4.904 4.148l2.054-1.425c-1.458-2.102-3.48-3.818-5.871-4.974a16.378 16.378 0 00-7.756-1.602 16.25 16.25 0 00-7.583 2.204C5.54 5.054 3.68 6.92 2.416 9.129l2.169 1.242c1.04-1.817 2.584-3.374 4.499-4.499a13.75 13.75 0 016.415-1.861zm.206 2.49C9.345 6.5 4.34 11.933 4.861 18.273l.155 1.88a9.906 9.906 0 007.123 8.705l.318.092a12.9 12.9 0 0010.737-1.66l-1.387-2.08a10.4 10.4 0 01-8.656 1.338l-.319-.092a7.406 7.406 0 01-5.325-6.508l-.154-1.88C6.95 13.186 10.806 9 15.706 9h.76a7.594 7.594 0 017.59 7.323l.022.614a3.33 3.33 0 01-3.407 3.448l-1.276-.03a2.293 2.293 0 01-2.178-2.819l-2.434-.572c-.697 2.963 1.51 5.817 4.552 5.89l1.277.03a5.83 5.83 0 005.964-6.036l-.022-.614A10.094 10.094 0 0016.467 6.5h-.76zm-.176 5a5.78 5.78 0 00-5.78 5.78v1.47A6.25 6.25 0 0016 25v-2.5a3.75 3.75 0 01-3.75-3.75v-1.47A3.28 3.28 0 0115.53 14h.586a3.135 3.135 0 013.134 3.135v.782h2.5v-.782a5.635 5.635 0 00-5.634-5.635h-.586z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.869 2.005a7.704 7.704 0 013.462.672c1.06.483 1.94 1.191 2.566 2.04l1.207-.889c-.787-1.068-1.874-1.935-3.152-2.516A9.204 9.204 0 007.813.506a9.08 9.08 0 00-4.049 1.109c-1.219.674-2.22 1.618-2.905 2.742l1.282.78c.54-.887 1.343-1.653 2.349-2.21a7.58 7.58 0 013.379-.922zm.045 1.245A6.164 6.164 0 001.75 9.414v.836a5.5 5.5 0 005.5 5.5h.25v-1.5h-.25a4 4 0 01-4-4v-.836A4.664 4.664 0 017.914 4.75h.415a4.261 4.261 0 014.26 4.395l-.008.249a1.916 1.916 0 01-1.915 1.856H10.5A1.75 1.75 0 018.75 9.5h-1.5a3.25 3.25 0 003.25 3.25h.166a3.416 3.416 0 003.414-3.31l.008-.249A5.761 5.761 0 008.33 3.25h-.415zm-.074 3A3.34 3.34 0 004.5 9.59v.06a4.08 4.08 0 001.531 3.186l.938-1.172a2.58 2.58 0 01-.97-2.015V9.59A1.84 1.84 0 017.84 7.75h.327c.874 0 1.583.709 1.583 1.583v.5h1.5v-.5A3.083 3.083 0 008.167 6.25H7.84z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M16.648 4.033a10.914 10.914 0 00-5.074-1.025A10.787 10.787 0 006.62 4.415c-1.478.85-2.668 2.026-3.47 3.397l-1.726-1.01c.986-1.685 2.433-3.105 4.2-4.12a12.787 12.787 0 015.873-1.672c2.084-.08 4.154.339 6.007 1.215 1.853.877 3.424 2.181 4.56 3.784l-1.632 1.156c-.928-1.31-2.227-2.396-3.784-3.132z"
    }), React.createElement("path", {
      d: "M16 18a5 5 0 01-5-5h2a3 3 0 106 0 6 6 0 00-6-6h-1a7 7 0 00-7 7v.5a6.5 6.5 0 006.5 6.5v2A8.5 8.5 0 013 14.5V14a9 9 0 019-9h1a8 8 0 018 8 5 5 0 01-5 5z"
    }), React.createElement("path", {
      d: "M7 14a6 6 0 006 6v-2a4 4 0 01-4-4 3 3 0 013-3h1a2 2 0 012 2v1h2v-1a4 4 0 00-4-4h-1a5 5 0 00-5 5z"
    }));
  })());
};
Fingerprint.displayName = 'Fingerprint';
Fingerprint.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Flag = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.25 5.977v9.468c.879-.357 2.026-.695 3.25-.695 1.188 0 2.027.054 2.806.242.779.188 1.435.496 2.236.881.577.278 1.011.517 1.358.71l.004.001c.358.199.575.32.788.412.345.15.736.254 1.808.254.913 0 1.853-.3 2.61-.64.244-.11.46-.22.64-.318V6.726c-.904.253-2.12.524-3.25.524-2.108 0-3.13-.225-4.321-.542-.537-.143-1.124-.404-1.695-.658a50.149 50.149 0 00-.43-.19 18.355 18.355 0 00-2.864-.996c-.808-.206-1.56.063-2.205.5a5.006 5.006 0 00-.735.613zm16.29-2.14l1.71-.678v14.497l-.54.373-.71-1.03.71 1.03-.003.002-.003.002-.009.006-.027.018a9.555 9.555 0 01-.413.256c-.27.158-.655.368-1.121.577-.913.41-2.223.86-3.634.86-1.252 0-2.023-.122-2.803-.461a10.997 10.997 0 01-.948-.486l-.06-.034c-.337-.187-.72-.397-1.23-.643-.822-.395-1.254-.587-1.74-.704-.483-.117-1.083-.172-2.219-.172-.913 0-1.853.3-2.61.64-.244.11-.46.22-.64.318V29h-2.5V5.076l.258-.337L7 5.5l-.992-.76.001-.002.002-.002.003-.004.01-.012a3.245 3.245 0 01.114-.14 7.512 7.512 0 011.444-1.286c.96-.65 2.458-1.305 4.228-.852 1.376.353 2.415.768 3.239 1.125.215.093.405.177.576.253.526.233.868.385 1.196.472 1.025.272 1.828.458 3.679.458.815 0 1.807-.212 2.65-.452a17.97 17.97 0 001.37-.453l.017-.006.003-.001z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.75 2.854v4.692A2.959 2.959 0 016 7.25c.64 0 1.337.144 1.844.273a12.533 12.533 0 01.832.244l.047.016.052.015a23.959 23.959 0 00.878.221c.552.13 1.091.23 1.347.23.203 0 .466-.076.717-.186l.033-.015V3.562a4.86 4.86 0 01-1.25.188c-1.05 0-1.9-.234-2.757-.545-.175-.064-.338-.127-.497-.188-.252-.096-.492-.188-.75-.274-.406-.134-.736-.208-.996-.208-.17 0-.397.088-.633.24a2.584 2.584 0 00-.117.079zm7.433-1.034l1.067-.497v7.589l-.348.22L12.5 8.5l.402.633-.002.001-.004.003-.011.007a2.96 2.96 0 01-.156.09c-.1.056-.242.13-.41.203-.32.14-.809.313-1.319.313-.458 0-1.169-.149-1.688-.27a27.186 27.186 0 01-.93-.235l-.061-.017-.017-.004-.006-.002L8.5 8.5l-.202.722-.025-.007-.023-.008h-.002l-.01-.004-.04-.014a11.03 11.03 0 00-.726-.213C7.003 8.857 6.45 8.75 6 8.75c-.325 0-.674.155-.984.368-.103.07-.193.142-.266.204V15h-1.5V2.183l.227-.22L4 2.5l-.523-.537.001-.002.001-.001.004-.004.011-.01a2.494 2.494 0 01.154-.135c.098-.082.24-.19.41-.3.323-.207.845-.476 1.442-.476.505 0 1.019.136 1.468.284.283.094.59.212.87.318.15.058.293.112.419.158.771.281 1.437.455 2.243.455.348 0 .76-.097 1.116-.213a6.237 6.237 0 00.534-.202l.028-.013.005-.002z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6 4.12c.147-.066.32-.14.509-.212.634-.243 1.355-.434 1.948-.409.648.028 1.412.239 2.055.469a12.516 12.516 0 011.036.424l.011.006h.002l.024.013.026.01.389-.92-.39.92.004.002.006.002.022.01a7.602 7.602 0 00.34.132c.223.084.538.195.904.307.711.216 1.7.46 2.614.46.866 0 1.8-.219 2.5-.426v6.976c-.127.057-.273.12-.436.182-.593.228-1.338.434-2.064.434s-1.471-.206-2.064-.434a8.005 8.005 0 01-.924-.425l-.007-.004-.004-.003-.007-.004-.02-.012a6.446 6.446 0 00-.322-.17 10.02 10.02 0 00-.87-.382c-.71-.272-1.714-.566-2.782-.566-.934 0-1.819.225-2.5.463V4.121zm0 8.996V22H4V2.915l.51-.287L5 3.5l-.49-.872h.002l.002-.002.007-.004.022-.012a6.103 6.103 0 01.341-.175c.224-.109.539-.253.91-.395.716-.274 1.745-.582 2.75-.539.947.041 1.934.33 2.641.583a14.537 14.537 0 011.274.524c.051.02.127.05.224.086.194.072.468.17.786.266.656.2 1.418.374 2.031.374.613 0 1.375-.174 2.031-.374a13.445 13.445 0 001.065-.375l.013-.006h.002L20 1.99v11.082l-.494.29L19 12.5l.506.863-.005.002-.007.004-.02.012a6.446 6.446 0 01-.322.17c-.211.105-.51.244-.87.382-.71.273-1.714.567-2.782.567s-2.072-.294-2.781-.567a10.016 10.016 0 01-1.192-.552l-.021-.012-.007-.004-.003-.001-.008-.005-.043-.024a8.009 8.009 0 00-.88-.401c-.594-.228-1.34-.434-2.065-.434-.725 0-1.471.206-2.064.434-.163.062-.31.124-.436.182z"
    }));
  })());
};
Flag.displayName = 'Flag';
Flag.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const FlashCircle = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12.165 4L7.83 15.561l-.858 2.288 2.357-.643 8.904-2.428-2.803 10.508-2.454-3.067-1.952 1.562 4 5 .631.79.904-.453 6-3-1.118-2.236-3.518 1.76 3.285-12.32.56-2.1-2.096.572-8.643 2.357L14.835 4h-2.67z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.949 2L3.798 7.737l-.515 1.372 1.414-.385L8.94 7.567l-1.281 4.805-1.072-1.34-1.172.937 2 2.5.38.473.541-.271 3-1.5-.67-1.342-1.511.756 1.57-5.892.337-1.26-1.258.343-4.086 1.115L7.551 2H5.949z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M11.21 3l-2.654 7.58 7.858-1.964-2.895 10.615 2.575-1.145.812 1.828-5.18 2.302-3.485-4.065 1.518-1.302 1.772 2.068 2.055-7.533-8.143 2.036L9.09 3h2.12z"
    }));
  })());
};
FlashCircle.displayName = 'FlashCircle';
FlashCircle.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const FlashOff = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M18.75 2.466V12.75h8.127l-4.42 5.44-1.777-1.778.944-1.162h-2.106l-3.268-3.268V9.534l-1.094 1.354-1.778-1.778 5.372-6.644zm1.002 19.053l6.364 6.365 1.768-1.768-21.5-21.5-1.768 1.768 6.064 6.064L5.382 19h7.868v10.52l6.502-8zm-1.778-1.777l-2.224-2.224v4.961l2.224-2.737zM14.732 16.5l-2.274-2.275-1.84 2.275h4.114z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.5 1.448V6.25h3.852l-2.065 2.477-4.3-4.3L9.5 1.448zm.141 9.254l3.079 3.078 1.06-1.06-10.5-10.5-1.06 1.06 3.11 3.11-2.694 3.193h3.78v4.989l3.225-3.87zM8.576 9.637l-.66.792V8.977l.66.66zM7.023 8.083l-.628-.628-.53.628h1.158z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.25 1.566V9.5h5.25l-2.823 3.763-1.428-1.429.25-.334h-.585L12.25 8.835V7.434l-.61.79L10.212 6.8l4.037-5.233zm.358 14.456l4.685 4.685 1.414-1.414-16-16-1.414 1.414 4.818 4.818-3.645 4.725H9.75v8.25l4.858-6.478zm-1.429-1.428l-1.43-1.43V16.5l1.43-1.906zm-2.343-2.344l-1.3-1.3-1.002 1.3h2.302z"
    }));
  })());
};
FlashOff.displayName = 'FlashOff';
FlashOff.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const FlashOn = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M18.75 2.466V12.75h8.126L13.25 29.52V19H5.382L18.75 2.466zM10.618 16.5h5.132v5.98l5.874-7.23H16.25V9.534L10.618 16.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.5 1.448V6.25h3.851l-6.935 8.322V9.582h-3.78L9.5 1.448zM5.864 8.083h2.052v2.346l2.232-2.679H8V5.552L5.864 8.083z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.25 1.566V9.5h5.25l-9.75 13v-8.25H4.466L14.25 1.566zM8.534 12.25h3.216v4.25l3.75-5h-3.25V7.434L8.534 12.25z"
    }));
  })());
};
FlashOn.displayName = 'FlashOn';
FlashOn.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Folder = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.864 10.5l-2.059-3.295a1.5 1.5 0 00-1.272-.705H7A1.5 1.5 0 005.5 8v14A1.5 1.5 0 007 23.5h18a1.5 1.5 0 001.5-1.5V12a1.5 1.5 0 00-1.5-1.5h-9.136zM17.25 8H25a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V8a4 4 0 014-4h5.533a4 4 0 013.392 1.88L17.25 8z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.919 5.5L6.816 3.735a.5.5 0 00-.424-.235H3a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5H7.919zM8.75 4H13a2 2 0 012 2v5a2 2 0 01-2 2H3a2 2 0 01-2-2V4a2 2 0 012-2h3.392a2 2 0 011.696.94L8.75 4z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11.93 8l-1.704-2.555A1 1 0 009.394 5H5a1 1 0 00-1 1v11a1 1 0 001 1h14a1 1 0 001-1V9a1 1 0 00-1-1h-7.07zM13 6h6a3 3 0 013 3v8a3 3 0 01-3 3H5a3 3 0 01-3-3V6a3 3 0 013-3h4.394a3 3 0 012.497 1.336L13 6z"
    }));
  })());
};
Folder.displayName = 'Folder';
Folder.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Gallery = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M24 6.5H8A1.5 1.5 0 006.5 8v12.519l3.643-3.43.766-.72.84.631 3.176 2.382 6.236-5.642.882-.798.84.84L25.5 16.4V8A1.5 1.5 0 0024 6.5zm1.5 13.333l-.05.05-3.493-3.492-6.119 5.536-.764.691L14.25 22l-3.16-2.37-4.59 4.322V24A1.5 1.5 0 008 25.5h16a1.5 1.5 0 001.5-1.5v-4.167zM8 4a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V8a4 4 0 00-4-4H8zm6 8a2 2 0 11-4 0 2 2 0 014 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 3.5H4a.5.5 0 00-.5.5v5.852l1.486-1.398.46-.433.504.379 1.505 1.129 3.042-2.752.529-.478.504.504.97.97V4a.5.5 0 00-.5-.5zm.5 6.394l-1.526-1.526-2.971 2.688-.459.415-.494-.371-1.495-1.121L3.5 11.912V12a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V9.894zM4 2a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H4zm3 4a1 1 0 11-2 0 1 1 0 012 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18 5H6a1 1 0 00-1 1v9.086l2.793-2.793.707-.707.707.707 2.293 2.293 4.293-4.293.707-.707.707.707L19 12.086V6a1 1 0 00-1-1zm1 9.914l-2.5-2.5-4.293 4.293-.707.707-.707-.707L8.5 14.414l-3.5 3.5V18a1 1 0 001 1h12a1 1 0 001-1v-3.086zM6 3a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3H6zm4 5.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }));
  })());
};
Gallery.displayName = 'Gallery';
Gallery.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const GalleryAlt = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13 6.5h6.316a4.999 4.999 0 006.184 6.52v5.212l-3.116-3.116-.884-.884-.884.884-2.116 2.116-3.116-3.116-.884-.884-.884.884-2.116 2.116V8A1.5 1.5 0 0113 6.5zm8.733.697c.12-.258.283-.494.482-.697H24A1.5 1.5 0 0125.5 8v2.25a2.497 2.497 0 01-3.31-.275 2.5 2.5 0 01-.457-2.778zM11.638 19.63A1.5 1.5 0 0013 20.5h5.232l-.616-.616-3.116-3.116-2.862 2.862zm10.13.87H24c.074 0 .146-.005.217-.015L21.5 17.768 20.268 19l1.5 1.5zM9 8a4 4 0 014-4h11a4 4 0 014 4v11a4 4 0 01-4 4H13a4 4 0 01-4-4V8zm-5 5a4.001 4.001 0 012.498-3.708v9.34c0 .058 0 .115.002.171V24A1.5 1.5 0 008 25.5h5.193c.058.002.116.002.174.002h9.341A4.001 4.001 0 0119 28H8a4 4 0 01-4-4V13z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7 3.5h2.23a2.75 2.75 0 003.27 3.27V9a.5.5 0 01-.5.5h-.25l.03-.03-3-3-.53-.53-.53.53L6.5 7.69V4a.5.5 0 01.5-.5zm3.783.057a1.3 1.3 0 01.028-.057H12a.5.5 0 01.5.5v1.189a1.244 1.244 0 01-1.082.04 1.25 1.25 0 01-.635-1.672zM6.838 9.473A.5.5 0 007 9.5h2.69L8.25 8.06 6.838 9.474zM5 4a2 2 0 012-2h5a2 2 0 012 2v5a2 2 0 01-2 2H7a2 2 0 01-2-2V4zM2 6.75a2 2 0 011.5-1.937V12a.5.5 0 00.5.5H11.187A2 2 0 019.25 14H4a2 2 0 01-2-2V6.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.5 5H18a1 1 0 011 1v2.5a2.498 2.498 0 01-3.31-.275A2.5 2.5 0 0115.5 5zm-2.243 0H10a1 1 0 00-1 1v7.086l2.793-2.793.707-.707.707.707L17.914 15H18a1 1 0 001-1v-3.257A4.5 4.5 0 0113.257 5zm-.757 7.414L15.086 15H10c-.028 0-.055-.001-.082-.003l2.582-2.583zM7 6a3 3 0 013-3h8a3 3 0 013 3v8a3 3 0 01-3 3h-8a3 3 0 01-3-3V6zm-4 4c0-1.306.835-2.418 2-2.83V18a1 1 0 001 1h10.83A3.001 3.001 0 0114 21H6a3 3 0 01-3-3v-8z"
    }));
  })());
};
GalleryAlt.displayName = 'GalleryAlt';
GalleryAlt.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Heart = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.5 6.25c-3.145 0-5.75 2.543-5.75 6.083 0 4.135 2.75 7.48 5.779 9.891a28.889 28.889 0 005.468 3.402l.003.002.003-.002a28.889 28.889 0 005.469-3.402c3.028-2.41 5.778-5.756 5.778-9.89 0-3.541-2.605-6.084-5.75-6.084-.886 0-1.787.33-2.626.83a9.678 9.678 0 00-1.97 1.58L16 9.607l-.904-.947a9.678 9.678 0 00-1.97-1.58c-.839-.5-1.74-.83-2.626-.83zM16 28.355a29.926 29.926 0 01-7.029-4.175C5.75 21.615 2.25 17.627 2.25 12.333c0-4.84 3.645-8.583 8.25-8.583 1.49 0 2.838.545 3.906 1.182.602.359 1.14.761 1.594 1.149.454-.388.992-.79 1.594-1.15C18.662 4.296 20.01 3.75 21.5 3.75c4.605 0 8.25 3.743 8.25 8.583 0 5.294-3.5 9.282-6.721 11.847A29.872 29.872 0 0116 28.355z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.25 3.75C3.718 3.75 2.5 4.923 2.5 6.5c0 1.887 1.318 3.442 2.829 4.59A14.397 14.397 0 008 12.682a14.397 14.397 0 002.671-1.592C12.181 9.942 13.5 8.387 13.5 6.5c0-1.577-1.218-2.75-2.75-2.75-.874 0-1.75.646-2.22 1.115l-.53.53-.53-.53C7 4.396 6.124 3.75 5.25 3.75zM8 14.308c-.451-.18-.891-.38-1.323-.604a15.91 15.91 0 01-2.256-1.42C2.807 11.058 1 9.115 1 6.5c0-2.423 1.907-4.25 4.25-4.25 1.123 0 2.104.573 2.75 1.084.646-.511 1.627-1.084 2.75-1.084C13.093 2.25 15 4.077 15 6.5c0 2.613-1.807 4.557-3.421 5.785a15.91 15.91 0 01-2.256 1.42c-.432.224-.872.422-1.323.603z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 4.528A6 6 0 002 9c0 5 4 9 10 12 6-3 10-7 10-12a6 6 0 00-10-4.472zm0 14.22c5.262-2.826 8-6.15 8-9.748a4 4 0 00-6.666-2.982L12 7.212l-1.334-1.194A4 4 0 004 9c0 3.597 2.738 6.922 8 9.748z"
    }));
  })());
};
Heart.displayName = 'Heart';
Heart.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Help = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13zm-5.25-16c0-2.82 2.356-5.297 5.27-5.25 2.699.044 5.23 2.25 5.23 5.25 0 1.148-.342 2.02-.948 2.69-.496.547-1.131.901-1.572 1.146l-.117.066c-.507.285-.801.478-1.006.738-.17.217-.357.587-.357 1.36h-2.5c0-1.226.314-2.17.893-2.906.545-.693 1.251-1.094 1.744-1.371.562-.316.853-.48 1.061-.71.144-.159.302-.411.302-1.013 0-1.513-1.312-2.727-2.77-2.75-1.454-.024-2.73 1.261-2.73 2.75h-2.5zM16 24.5a2 2 0 100-4 2 2 0 000 4z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14zM5.375 6.25c0-1.41 1.178-2.648 2.635-2.625 1.35.022 2.615 1.125 2.615 2.625 0 .574-.17 1.012-.453 1.36-.235.288-.538.495-.76.646l-.058.04c-.252.174-.417.3-.533.463-.103.143-.196.356-.196.741h-1.25c0-.615.157-1.09.43-1.47.258-.36.593-.594.84-.764l.015-.01c.267-.183.426-.292.543-.436.092-.113.172-.27.172-.57 0-.757-.656-1.363-1.385-1.375-.727-.012-1.365.63-1.365 1.375h-1.25zm2.625 6a1 1 0 100-2 1 1 0 000 2z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-10 8a8 8 0 100-16 8 8 0 000 16zM10.47 5.804A4 4 0 0116 9.5c0 1.721-1.077 2.617-1.805 3.223l-.055.045c-.773.644-1.14.997-1.14 1.732h-2c0-1.721 1.077-2.617 1.805-3.223l.055-.045c.773-.644 1.14-.997 1.14-1.732a1.999 1.999 0 00-3.663-1.111A2 2 0 0010 9.5H8a4 4 0 012.47-3.696zM12 18.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }));
  })());
};
Help.displayName = 'Help';
Help.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const HighFive = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M18.986 4V0h-2.5v4h2.5zM5.632 9.71a1.25 1.25 0 00-.857 1.546l1.437 5.01a5.566 5.566 0 012.28 3.114l.459 1.602-2.403.689-.46-1.602a3.086 3.086 0 00-2.54-2.205l1.605 5.594a7.417 7.417 0 1014.259-4.089l-2.665-9.292a1.083 1.083 0 00-2.083.597l1.562 5.448-2.403.689-2.205-7.69a1.083 1.083 0 10-2.083.597l1.838 6.408-2.403.69-1.792-6.249a1.25 1.25 0 00-1.546-.857zm1.915-2.204a3.75 3.75 0 00-5.175 4.44l.973 3.392a5.578 5.578 0 00-1.76.214l-1.201.344 2.366 8.251c1.51 5.265 7 8.309 12.266 6.8 5.264-1.51 8.308-7.002 6.799-12.267L19.15 9.388a3.583 3.583 0 00-5.558-1.907 3.585 3.585 0 00-6.045.025zM19.54 31.319c4.099.054 7.967-2.457 9.448-6.527l2.508-6.891.428-1.175-1.175-.427a5.578 5.578 0 00-1.74-.336l1.207-3.317a3.75 3.75 0 00-4.852-4.79 3.57 3.57 0 00-1.93-1.67 3.579 3.579 0 00-3.237.402 6.236 6.236 0 011.024 2.052l.088.308a1.084 1.084 0 011.917.976l-.057.157-.859 2.358 1.173 4.088 2.035-5.59a1.25 1.25 0 112.35.854l-1.784 4.898a5.57 5.57 0 00-2.016 1.956c.23 1.02.328 2.042.305 3.048l1 .364.57-1.566a3.087 3.087 0 012.687-2.023l-1.991 5.469a7.4 7.4 0 01-4.352 4.403 12.583 12.583 0 01-2.747 2.979zM10.033.469l1.356 3.046L9.105 4.53 7.75 1.486 10.034.47zM25.29 5.011l1.766-2.827L24.935.86 23.17 3.686l2.12 1.325z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.518 0v2h-1.5V0h1.5zm2.495 8.915c.218-.264.48-.486.77-.656l.876-2.406a.5.5 0 00-.94-.342l-.941 2.585-.704-2.453.235-.645.029-.079a.417.417 0 00-.615-.494 3.232 3.232 0 00-.78-1.28 1.913 1.913 0 012.638.631 2 2 0 012.487 2.59l-.546 1.501c.246.022.494.076.738.165l.705.256-.257.705-1.254 3.446a5.084 5.084 0 01-4.284 3.322 6.438 6.438 0 001.587-2.142 3.567 3.567 0 001.287-1.693l.925-2.54a1.41 1.41 0 00-1.038.902l-.285.783-.307-.111c0-.588-.08-1.185-.25-1.778l-.076-.267zM5.959 1.706L5.28.184l-1.37.61.678 1.523 1.37-.61zm7.45-.548l-.884 1.414-1.272-.795.883-1.413 1.272.794zM2.506 5.594a.5.5 0 01.962-.276l.896 3.124 1.441-.413-.895-3.124-.023-.08a.417.417 0 01.8-.23l.322 1.121.781 2.724 1.442-.414-.78-2.723a.417.417 0 01.8-.23l1.332 4.646a3.583 3.583 0 01-6.889 1.976l-.744-2.598a1.426 1.426 0 01.972.972l.23.8 1.441-.412-.23-.802a2.907 2.907 0 00-1.152-1.6l-.706-2.461zm-.07-2.06a1.991 1.991 0 011.29.063 1.917 1.917 0 013.1-.03A1.918 1.918 0 019.696 4.66l1.332 4.646a5.083 5.083 0 11-9.772 2.802L.244 8.583l-.207-.72.721-.207c.25-.072.5-.108.748-.113l-.44-1.536a2 2 0 011.37-2.474z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("g", {
      clipPath: "url(#clip0_3768_6918)"
    }, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.102 3V0h-2v3h2zM4.04 7.718a.875.875 0 00-.6 1.082l1.071 3.736a4.237 4.237 0 011.716 2.357l.345 1.201-1.923.551-.344-1.201a2.24 2.24 0 00-1.758-1.594l1.176 4.101a5.5 5.5 0 0010.574-3.032L12.3 7.95a.75.75 0 10-1.442.413l1.171 4.086-1.922.551-1.654-5.767a.75.75 0 00-1.442.413l1.378 4.806-1.922.552-1.344-4.687a.875.875 0 00-1.082-.6zm1.395-1.793a2.875 2.875 0 00-3.918 3.426l.707 2.463a4.248 4.248 0 00-1.253.165l-.962.275 1.792 6.249a7.5 7.5 0 1014.419-4.135l-1.998-6.97a2.75 2.75 0 00-4.213-1.5 2.751 2.751 0 00-4.574.027zm12.58 8.076a4.245 4.245 0 011.297-1.152l1.33-3.652a.875.875 0 10-1.645-.599l-1.414 3.886-.938-3.27.473-1.3.042-.117a.75.75 0 00-1.165-.849l-.031-.11a4.735 4.735 0 00-.876-1.684 2.744 2.744 0 012.308-.198 2.739 2.739 0 011.455 1.234 2.875 2.875 0 013.67 3.69l-.876 2.408c.413.021.83.103 1.238.252l.94.342-.342.94L21.6 18.99a7.503 7.503 0 01-6.936 4.936 9.503 9.503 0 002.27-2.544 5.482 5.482 0 002.786-3.076l1.46-4.009a2.26 2.26 0 00-1.865 1.467l-.427 1.175-.56-.204c.029-.9-.07-1.82-.313-2.734zM7.758.743l1.017 2.284-1.827.814L5.93 1.557 7.757.743zM18.632 3.8l1.325-2.12L18.261.62l-1.325 2.12 1.696 1.06z"
    })), React.createElement("defs", null, React.createElement("clipPath", {
      id: "clip0_3768_6918"
    }, React.createElement("rect", {
      width: "24",
      height: "24"
    }))));
  })());
};
HighFive.displayName = 'HighFive';
HighFive.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const History = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M18.415 2.972A13.25 13.25 0 005.25 8.254V3h-2.5v9.25H12v-2.5H7.254a10.75 10.75 0 11-.564 11.625l-2.165 1.25a13.25 13.25 0 1013.89-19.653zM14.75 16V8h2.5v7.1l5.145 1.714-.79 2.372-6-2-.855-.285V16z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.23 1.363a6.75 6.75 0 00-6.48 2.394V1.5h-1.5v4.75H5.5v-1.5H3.877a5.25 5.25 0 11-.424 5.875l-1.299.75A6.75 6.75 0 109.23 1.363zM7.25 8V4h1.5v3.46l2.487.829-.474 1.423-3-1-.513-.171V8z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.66 4.91a7.536 7.536 0 014.993-.778 7.695 7.695 0 014.407 2.574 8.138 8.138 0 011.93 4.873 8.186 8.186 0 01-1.425 5.058 7.764 7.764 0 01-4.127 3.038 7.521 7.521 0 01-5.044-.238A7.827 7.827 0 015.55 16.02L3.806 17a9.827 9.827 0 004.831 4.288 9.52 9.52 0 006.382.3 9.763 9.763 0 005.193-3.817 10.186 10.186 0 001.775-6.294 10.138 10.138 0 00-2.407-6.07 9.695 9.695 0 00-5.553-3.24 9.536 9.536 0 00-6.317.983A9.787 9.787 0 005 5.314V3H3v6h6V7H6.2a7.811 7.811 0 012.46-2.09zM11 12V6h2v5.28l5.316 1.771-.632 1.898-6-2L11 12.72V12z"
    }));
  })());
};
History.displayName = 'History';
History.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Home = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 17.503h8v8h4.835V12.63L16 6.396l-8.833 6.235v12.872H12v-8zm2.5 10.5H4.668V11.336l11.333-8 11.334 8v16.667H17.5v-8h-3v8z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.629 8.852h4.743V13.5h2.713V6.094L8.001 2.505l-5.084 3.59V13.5h2.712V8.852zM7.129 15H1.417V5.317L8 .67l6.584 4.648V15H8.872v-4.648H7.129V15z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9 13h6v6h3.5V9.536L12 4.948 5.5 9.536V19H9v-6zm-5.5 8V8.5l8.5-6 8.5 6V21H13v-6h-2v6H3.5z"
    }));
  })());
};
Home.displayName = 'Home';
Home.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const HomeFilled = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.5 27.667h.334v-7.668H18.5v8h8.833v-.332h.334V11L16.333 3 5 11v.096l-.334.236v16.667H13.5v-.332z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.001.681l-6.574 4.64v9.666H6.26v-4.64h3.48v4.64h4.835V5.32L8 .68z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 2.5l-8.5 6V21H10v-6h4v6h6.5V8.5l-8.5-6z"
    }));
  })());
};
HomeFilled.displayName = 'HomeFilled';
HomeFilled.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Inbox = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M21.828 9.282l-4.94 4.94-.884.883-.884-.884-4.94-4.94 1.768-1.767 2.806 2.806V1.33h2.5v8.99l2.806-2.806 1.768 1.768zM6 16h6.25v1.467a3.75 3.75 0 107.5 0V16h8.167v8c0 2.9-2.35 5.25-5.25 5.25H9.333A5.25 5.25 0 014.084 24v-8H6zm19.417 2.5h-3.252a6.252 6.252 0 01-12.33 0H6.583V24a2.75 2.75 0 002.75 2.75h13.334a2.75 2.75 0 002.75-2.75v-5.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.002 4.73l-3 3-3-3 1.06-1.061 1.19 1.19V.664h1.5v4.193l1.19-1.19 1.06 1.061zM3 8h3.25v.75c0 .95.786 1.733 1.75 1.733s1.75-.782 1.75-1.733V8h4.334v4a2.75 2.75 0 01-2.75 2.75H4.667A2.75 2.75 0 011.917 12V8H3zm9.584 1.5h-1.422A3.256 3.256 0 018 11.983 3.256 3.256 0 014.838 9.5H3.417V12c0 .69.56 1.25 1.25 1.25h6.667c.69 0 1.25-.56 1.25-1.25V9.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16.415 7.005L12.71 10.71l-.707.707-.707-.707L7.59 7.005l1.414-1.414 1.998 1.998V.997h2v6.592L15 5.59l1.415 1.414zM4.5 12h4.75v1.1a2.75 2.75 0 105.5 0V12H21v6a4 4 0 01-4 4H7a4 4 0 01-4-4v-6h1.5zM19 14h-2.335a4.752 4.752 0 01-9.33 0H5v4a2 2 0 002 2h10a2 2 0 002-2v-4z"
    }));
  })());
};
Inbox.displayName = 'Inbox';
Inbox.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Info = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 2.5c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13zm1.5-18.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM14.75 14v9h2.5v-9h-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14zM9 4.75a1 1 0 11-2 0 1 1 0 012 0zM7.25 7v5h1.5V7h-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M13.25 7.75a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M11 10.5V17h2v-6.5h-2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2a8 8 0 100-16 8 8 0 000 16z"
    }));
  })());
};
Info.displayName = 'Info';
Info.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Italic = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M18 28H8l.5-2.5h3.75l5-19H13.5L14 4h10l-.5 2.5h-3.75l-5 19h3.75L18 28z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M3.5 12.5L3 14h6l.5-1.5H7.25l3-9h2.25L13 2H7l-.5 1.5h2.25l-3 9H3.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M6 19l-.5 2h8l.5-2h-3l4-14h3l.5-2h-8L10 5h3L9 19H6z"
    }));
  })());
};
Italic.displayName = 'Italic';
Italic.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Key = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.5 16a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm2.403 1.25a8 8 0 110-2.5H29.75V21h-2.5v-3.75h-2.5V20h-2.5v-2.75h-4.347z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.5 8a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm1.43.75A4.001 4.001 0 011 8a4 4 0 017.93-.75H15v3.25h-1.5V8.75H12v1.75h-1.5V8.75H8.93z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 12a4 4 0 11-8 0 4 4 0 018 0zm1.917 1A6.002 6.002 0 011 12a6 6 0 0111.917-1H23v5h-2v-3h-2v3h-2v-3h-4.083z"
    }));
  })());
};
Key.displayName = 'Key';
Key.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Lightbulb = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17.25 6V2h-2.5v4h2.5zM6.884 5.116l2.5 2.5-1.768 1.768-2.5-2.5 1.768-1.768zm17.5 4.268l2.5-2.5-1.768-1.768-2.5 2.5 1.768 1.768zm-5.636 11.382l-1.248.722V23.5h-3v-2.012l-1.248-.722a5.5 5.5 0 115.496 0zM12 23.5V26h8v-3.07a8 8 0 10-8 0v.57zm6 4.5v2.5h-4V28h4zM2 17h4v-2.5H2V17zm28 0h-4v-2.5h4V17z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.75 3V1h-1.5v2h1.5zm-5.22-.53l1.25 1.25-1.06 1.06-1.25-1.25 1.06-1.06zm8.75 2.31l1.25-1.25-1.06-1.06-1.25 1.25 1.06 1.06zm-2.948 5.53l-.665.386V11.667H7.333v-.972l-.665-.384a2.667 2.667 0 112.665 0zM6 11.668V13h4v-1.535a4 4 0 10-4 0v.202zm3 2V15H7V13.5h2v.167zM1 8.5h2V7H1v1.5zm14 0h-2V7h2v1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M11 1.5h2V4h-2V1.5z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15 17.197V19H9v-1.803a6 6 0 116 0zm-2-1.153l.998-.578a4 4 0 10-3.997 0l.999.578V17h2v-.956z"
    }), React.createElement("path", {
      d: "M22.5 11H20v2h2.5v-2z"
    }), React.createElement("path", {
      d: "M1.5 11H4v2H1.5v-2z"
    }), React.createElement("path", {
      d: "M14 22v-2h-4v2h4z"
    }), React.createElement("path", {
      d: "M20.207 5.207l-1.75 1.75-1.414-1.414 1.75-1.75 1.414 1.414z"
    }), React.createElement("path", {
      d: "M6.957 5.543l-1.75-1.75-1.414 1.414 1.75 1.75 1.414-1.414z"
    }));
  })());
};
Lightbulb.displayName = 'Lightbulb';
Lightbulb.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Link = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.384 8.384a5.114 5.114 0 017.232 7.232l-2 2 1.768 1.768 2-2A7.614 7.614 0 0014.616 6.616l-2 2 1.768 1.768 2-2zm-8 8l2-2-1.768-1.768-2 2a7.614 7.614 0 0010.768 10.768l2-2-1.768-1.768-2 2a5.114 5.114 0 01-7.232-7.232zm4.5 4.5l8-8-1.768-1.768-8 8 1.768 1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.28 4.28a2.432 2.432 0 113.44 3.44l-.75.75 1.06 1.06.75-.75a3.932 3.932 0 00-5.56-5.56l-.75.75 1.06 1.06.75-.75zm-4 4l.75-.75-1.06-1.06-.75.75a3.932 3.932 0 105.56 5.56l.75-.75-1.06-1.06-.75.75a2.432 2.432 0 11-3.44-3.44zm2.25 2.25l4-4-1.06-1.06-4 4 1.06 1.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M12.207 6.207a3.95 3.95 0 015.586 5.586l-1.5 1.5 1.414 1.414 1.5-1.5a5.95 5.95 0 10-8.414-8.414l-1.5 1.5 1.414 1.414 1.5-1.5z"
    }), React.createElement("path", {
      d: "M6.207 12.207l1.5-1.5-1.414-1.414-1.5 1.5a5.95 5.95 0 008.414 8.414l1.5-1.5-1.414-1.414-1.5 1.5a3.95 3.95 0 11-5.586-5.586z"
    }), React.createElement("path", {
      d: "M9.707 15.707l6-6-1.414-1.414-6 6 1.414 1.414z"
    }));
  })());
};
Link.displayName = 'Link';
Link.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const LinkSlash = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.884 4.116l11 11 11 11-1.768 1.768L16 17.768l-3.116 3.116-1.768-1.768L14.232 16 4.116 5.884l1.768-1.768zm17.732 4.268a5.114 5.114 0 00-7.232 0l-1 1-1.768-1.768 1-1a7.614 7.614 0 1110.768 10.768l-1 1-1.768-1.768 1-1a5.114 5.114 0 000-7.232zm-14.232 7l-1 1a5.114 5.114 0 107.232 7.232l1-1 1.768 1.768-1 1A7.614 7.614 0 016.616 14.616l1-1 1.768 1.768zm10.5-1.5l2-2-1.768-1.768-2 2 1.768 1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.03 1.97l5.5 5.5 5.5 5.5-1.06 1.06L8 9.06l-1.47 1.47-1.06-1.06L6.94 8 1.97 3.03l1.06-1.06zm8.69 2.31c-.95-.95-2.49-.95-3.44 0l-.25.25-1.06-1.06.25-.25a3.932 3.932 0 115.56 5.56l-.25.25-1.06-1.06.25-.25c.95-.95.95-2.49 0-3.44zM4.53 8.03l-.25.25a2.432 2.432 0 003.44 3.44l.25-.25 1.06 1.06-.25.25a3.932 3.932 0 01-5.56-5.56l.25-.25 1.06 1.06zm5.75-1.25l1-1-1.06-1.06-1 1 1.06 1.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M12.235 13.179l7.058 7.057 1.414-1.414-16-16-1.414 1.414 7.528 7.529-2.528 2.528 1.414 1.414 2.528-2.528z"
    }), React.createElement("path", {
      d: "M10.15 5.436l1.414 1.414.643-.643a3.95 3.95 0 115.586 5.586l-.643.643 1.414 1.414.643-.643a5.95 5.95 0 10-8.414-8.414l-.643.643z"
    }), React.createElement("path", {
      d: "M13.65 8.936l1.414 1.414 1.143-1.143-1.414-1.414-1.143 1.143z"
    }), React.createElement("path", {
      d: "M7.707 10.707l-1.5 1.5a3.95 3.95 0 005.586 5.586l1.5-1.5 1.414 1.414-1.5 1.5a5.95 5.95 0 01-8.414-8.414l1.5-1.5 1.414 1.414z"
    }));
  })());
};
LinkSlash.displayName = 'LinkSlash';
LinkSlash.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const List = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M4.5 10a2 2 0 100-4 2 2 0 000 4z"
      }), React.createElement("path", {
        d: "M4.5 18a2 2 0 100-4 2 2 0 000 4z"
      }), React.createElement("path", {
        d: "M6.5 24a2 2 0 11-4 0 2 2 0 014 0z"
      }), React.createElement("path", {
        d: "M9.25 17.25h20.167v-2.5H9.25v2.5z"
      }), React.createElement("path", {
        d: "M29.5 25.25H9.25v-2.5H29.5v2.5z"
      }), React.createElement("path", {
        d: "M9.25 9.25h20.167v-2.5H9.25v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M2.25 5a1 1 0 100-2 1 1 0 000 2z"
      }), React.createElement("path", {
        d: "M2.25 9a1 1 0 100-2 1 1 0 000 2z"
      }), React.createElement("path", {
        d: "M3.25 12a1 1 0 11-2 0 1 1 0 012 0z"
      }), React.createElement("path", {
        d: "M4.5 8.75h10.25v-1.5H4.5v1.5z"
      }), React.createElement("path", {
        d: "M14.75 12.75H4.5v-1.5h10.25v1.5z"
      }), React.createElement("path", {
        d: "M4.5 4.75h10.25v-1.5H4.5v1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M3.5 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    }), React.createElement("path", {
      d: "M3.5 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    }), React.createElement("path", {
      d: "M5 18a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
    }), React.createElement("path", {
      d: "M7 13h15v-2H7v2z"
    }), React.createElement("path", {
      d: "M22 19H7v-2h15v2z"
    }), React.createElement("path", {
      d: "M7 7h15V5H7v2z"
    }));
  })());
};
List.displayName = 'List';
List.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ListNumbered = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M6.969 6.75v8H4.797v-6H4.75L3 9.797V7.953L4.969 6.75h2z"
      }), React.createElement("path", {
        d: "M29.5 9.25h-19v-2.5h19v2.5z"
      }), React.createElement("path", {
        d: "M29.5 17.25h-19v-2.5h19v2.5z"
      }), React.createElement("path", {
        d: "M10.5 25.25h19v-2.5h-19v2.5z"
      }), React.createElement("path", {
        d: "M2.654 23.709v1.541h6.058v-1.711H5.629v-.046l.74-.617c.601-.5 1.065-.932 1.391-1.295.326-.362.552-.692.678-.99.126-.3.19-.602.19-.905 0-.491-.128-.919-.382-1.284-.252-.365-.61-.647-1.075-.848-.463-.202-1.01-.304-1.642-.304-.612 0-1.145.108-1.6.324a2.443 2.443 0 00-1.055.925c-.25.4-.374.863-.374 1.418h2c0-.221.076-.393.158-.548a.83.83 0 01.347-.35c.151-.08.326-.12.524-.12s.372.04.524.12a.85.85 0 01.355.331.984.984 0 01.13.52c0 .185-.038.354-.115.505-.075.152-.182.3-.324.443a6.67 6.67 0 01-.5.455L2.653 23.71z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M3.484 3.25v4H2.398v-3h-.023l-.875.523v-.921l.984-.602h1z"
      }), React.createElement("path", {
        d: "M14.75 4.75h-9.5v-1.5h9.5v1.5z"
      }), React.createElement("path", {
        d: "M14.75 8.75h-9.5v-1.5h9.5v1.5z"
      }), React.createElement("path", {
        d: "M5.25 12.75h9.5v-1.5h-9.5v1.5z"
      }), React.createElement("path", {
        d: "M1.327 11.98v.77h3.029v-.855H2.815v-.024l.37-.308c.3-.25.532-.466.695-.647.163-.181.276-.347.34-.495a1.164 1.164 0 00-.097-1.095 1.22 1.22 0 00-.538-.424 2.03 2.03 0 00-.82-.152c-.306 0-.573.054-.8.162a1.221 1.221 0 00-.528.462c-.125.2-.187.432-.187.71h1c0-.111.038-.197.079-.274a.415.415 0 01.173-.176.555.555 0 01.262-.06c.1 0 .187.02.262.06a.425.425 0 01.178.166.492.492 0 01.065.26c0 .093-.019.177-.058.253a.861.861 0 01-.161.221c-.07.07-.153.147-.25.228l-1.473 1.217z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M5.477 5v6h-1.63V6.5h-.034L2.5 7.285V5.902L3.977 5h1.5z"
    }), React.createElement("path", {
      d: "M22 7H8V5h14v2z"
    }), React.createElement("path", {
      d: "M22 13H8v-2h14v2z"
    }), React.createElement("path", {
      d: "M8 19h14v-2H8v2z"
    }), React.createElement("path", {
      d: "M2.116 17.844V19h4.543v-1.283H4.347v-.035l.555-.462c.45-.376.798-.7 1.043-.971.245-.272.414-.52.509-.743.094-.226.141-.452.141-.68a1.64 1.64 0 00-.286-.962 1.83 1.83 0 00-.806-.636C5.156 13.076 4.746 13 4.272 13c-.459 0-.859.08-1.2.243a1.831 1.831 0 00-.792.693C2.093 14.237 2 14.584 2 15h1.5c0-.166.057-.295.119-.41a.623.623 0 01.26-.263.833.833 0 01.393-.09c.148 0 .28.03.393.09.113.057.202.14.266.248a.738.738 0 01.098.39.823.823 0 01-.087.379 1.293 1.293 0 01-.243.332c-.104.106-.229.22-.375.341l-2.208 1.827z"
    }));
  })());
};
ListNumbered.displayName = 'ListNumbered';
ListNumbered.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Lock = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.986 1.417H16c2.012 0 3.651.779 4.793 2.036 1.124 1.236 1.713 2.873 1.789 4.54l.001.028v4.062h4V29.25H5.417V12.083h4V8.05c0-3.737 2.925-6.55 6.555-6.632h.014zm4.097 6.662v4.004h-8.166V8.05c0-2.316 1.774-4.073 4.097-4.132 1.314.003 2.273.495 2.93 1.217.671.74 1.081 1.784 1.14 2.945zM7.917 14.583V26.75h16.166V14.583H7.917zM16 23.42a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 1h-.017c-1.873.042-3.4 1.485-3.4 3.417v1.998H3v8.5h10v-8.5h-1.583V4.4l-.001-.018c-.039-.853-.344-1.695-.929-2.333C9.891 1.399 9.038 1 8 1zm1.917 5.415v-1.98c-.028-.547-.223-1.032-.535-1.372-.303-.33-.75-.56-1.373-.563-1.103.029-1.926.85-1.926 1.917v1.998h3.834zM4.5 7.915v5.5h7v-5.5h-7zm4.75 2.745a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11.989.5H12c1.523 0 2.768.585 3.638 1.533.856.933 1.304 2.166 1.361 3.421l.001.023V8.5h3v13H4v-13h3v-3C7 2.674 9.231.562 11.978.5h.01zM15 5.524V8.5H9v-3c0-1.673 1.293-2.956 3.011-3 .972.003 1.674.362 2.154.885.49.535.792 1.293.835 2.14zM6 10.5v9h12v-9H6zm6 6.5a2 2 0 100-4 2 2 0 000 4z"
    }));
  })());
};
Lock.displayName = 'Lock';
Lock.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const LockOpen = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 1.417h-.028c-2.958.066-5.448 1.947-6.269 4.663h2.694c.68-1.278 2.018-2.123 3.617-2.163 1.314.003 2.273.495 2.93 1.217.671.74 1.081 1.784 1.14 2.945v4.004H5.415V29.25h21.167V12.083h-4V8.021l-.001-.028c-.076-1.667-.665-3.304-1.789-4.54C19.651 2.196 18.012 1.417 16 1.417zM7.916 26.75V14.583h16.167V26.75H7.917zM16 23.42a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.992 1H8c1.038 0 1.891.4 2.487 1.049.585.638.89 1.48.929 2.333V6.42H13v8.5H3v-8.5h6.917V4.435c-.028-.547-.223-1.032-.535-1.372-.303-.33-.75-.56-1.373-.563-.827.021-1.496.488-1.78 1.17H4.664C4.998 2.124 6.36 1.036 7.983 1h.009zM4.5 13.42v-5.5h7v5.5h-7zM8 11.91a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 .5h-.022c-2.412.054-4.426 1.689-4.882 4h2.069c.4-1.16 1.491-1.965 2.846-2 .972.003 1.674.362 2.154.885.49.535.792 1.293.835 2.14V8.5H4v13h16v-13h-3V5.477l-.001-.023c-.057-1.255-.505-2.488-1.36-3.421C14.768 1.085 13.522.5 12 .5zm-6 19v-9h12v9H6zm8-4.5a2 2 0 11-4 0 2 2 0 014 0z"
    }));
  })());
};
LockOpen.displayName = 'LockOpen';
LockOpen.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const LockReset = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M18.415 3.472A13.25 13.25 0 005.5 8.418V5H3v8h8v-2.5H7.08a10.75 10.75 0 11-.39 11.375l-2.165 1.25a13.25 13.25 0 1013.89-19.653zM15.917 11.5c-.783 0-1.417.634-1.417 1.417V15h3v-2.083c0-.783-.634-1.417-1.417-1.417h-.166zM20 15v-2.083A3.917 3.917 0 0016.083 9h-.166A3.917 3.917 0 0012 12.917V15h-2v8h12v-8h-2zm-.5 2.5h-7v3h7v-3z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.23 1.613a6.75 6.75 0 00-6.48 2.394V2h-1.5v4.5H5.5V5H3.877a5.25 5.25 0 11-.424 5.875l-1.299.75A6.75 6.75 0 109.23 1.613zM8 5.75a.5.5 0 00-.5.5V7.5h1V6.25a.5.5 0 00-.5-.5zm2 1.75V6.25a2 2 0 10-4 0V7.5H5v4h6v-4h-1zM9.5 9h-3v1h3V9z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.66 4.91a7.536 7.536 0 014.993-.778 7.695 7.695 0 014.407 2.574 8.138 8.138 0 011.93 4.873 8.186 8.186 0 01-1.425 5.058 7.764 7.764 0 01-4.127 3.038 7.521 7.521 0 01-5.044-.238A7.827 7.827 0 015.55 16.02L3.806 17a9.827 9.827 0 004.831 4.288 9.52 9.52 0 006.382.3 9.763 9.763 0 005.193-3.817 10.186 10.186 0 001.775-6.294 10.138 10.138 0 00-2.407-6.07 9.695 9.695 0 00-5.553-3.24 9.536 9.536 0 00-6.317.983A9.787 9.787 0 005 5.314V3H3v6h6V7H6.2a7.811 7.811 0 012.46-2.09zM12.5 9a1 1 0 00-1 1v1h2v-1a1 1 0 00-1-1zm3 2v-1a3 3 0 10-6 0v1h-1v6h8v-6h-1zm-5 2v2h4v-2h-4z"
    }));
  })());
};
LockReset.displayName = 'LockReset';
LockReset.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Login = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.75 24.083A3.917 3.917 0 007.667 28h16.416A3.917 3.917 0 0028 24.083V7.917A3.917 3.917 0 0024.083 4H7.667A3.917 3.917 0 003.75 7.917v.833h2.5v-.833c0-.783.634-1.417 1.417-1.417h16.416c.783 0 1.417.634 1.417 1.417v16.166c0 .783-.634 1.417-1.417 1.417H7.667a1.417 1.417 0 01-1.417-1.417V22.75h-2.5v1.333zm17.134-8.967l-7-7-1.768 1.768 4.866 4.866H2v2.5h14.982l-4.866 4.866 1.768 1.768 7-7 .884-.884-.884-.884z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.5 12.418c0 1.151.933 2.084 2.083 2.084h8.834c1.15 0 2.083-.933 2.083-2.084V3.583c0-1.15-.933-2.083-2.083-2.083H3.583c-1.15 0-2.083.933-2.083 2.083V5H3V3.583C3 3.261 3.261 3 3.583 3h8.834c.322 0 .583.261.583.583v8.835a.583.583 0 01-.583.584H3.583A.583.583 0 013 12.418V11H1.5v1.418zm9.03-4.948l-3.5-3.5-1.06 1.06 2.22 2.22H.5v1.5h7.69l-2.22 2.22 1.06 1.06 3.5-3.5.53-.53-.53-.53z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M3 18a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3H6a3 3 0 00-3 3v2h2V6a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H6a1 1 0 01-1-1v-2H3v2z"
    }), React.createElement("path", {
      d: "M15.414 12L9.707 6.293 8.293 7.707 11.586 11H1v2h10.586l-3.293 3.293 1.414 1.414L15.414 12z"
    }));
  })());
};
Login.displayName = 'Login';
Login.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Logout = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.75 24.083A3.917 3.917 0 007.667 28h16.416A3.917 3.917 0 0028 24.083V7.917A3.917 3.917 0 0024.084 4H7.666A3.917 3.917 0 003.75 7.917v.833h2.5v-.833c0-.783.634-1.417 1.417-1.417h16.416c.783 0 1.417.634 1.417 1.417v16.166c0 .783-.634 1.417-1.416 1.417H7.666a1.417 1.417 0 01-1.417-1.417V22.75h-2.5v1.333zm9.134-1.967L8.018 17.25H22v-2.5H8.018l4.866-4.866-1.768-1.768-7 7-.884.884.884.884 7 7 1.768-1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.5 12.418c0 1.151.933 2.084 2.083 2.084h8.834c1.15 0 2.083-.933 2.083-2.084V3.583c0-1.15-.933-2.083-2.083-2.083H3.583c-1.15 0-2.083.933-2.083 2.083V4.5H3v-.917C3 3.261 3.261 3 3.583 3h8.834c.322 0 .583.261.583.583v8.835a.583.583 0 01-.583.584H3.583A.583.583 0 013 12.418V11.5H1.5v.918zm5.53-1.448L4.81 8.75H11v-1.5H4.81l2.22-2.22-1.06-1.06-3.5 3.5-.53.53.53.53 3.5 3.5 1.06-1.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M3 18a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3H6a3 3 0 00-3 3h2a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H6a1 1 0 01-1-1H3z"
    }), React.createElement("path", {
      d: "M2.586 12l5.707 5.707 1.414-1.414L6.414 13H15v-2H6.414l3.293-3.293-1.414-1.414L2.586 12z"
    }));
  })());
};
Logout.displayName = 'Logout';
Logout.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Minus = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M27 17.25H5v-2.5h22v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.5 7h11v2h-11V7z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 11h16v2H4v-2z"
    }));
  })());
};
Minus.displayName = 'Minus';
Minus.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const MinusCircle = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M26.5 16c0 5.799-4.701 10.5-10.5 10.5S5.5 21.799 5.5 16 10.201 5.5 16 5.5 26.5 10.201 26.5 16zm2.5 0c0 7.18-5.82 13-13 13S3 23.18 3 16 8.82 3 16 3s13 5.82 13 13zm-7-1.25H10v2.5h12v-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.5 8a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zM15 8A7 7 0 111 8a7 7 0 0114 0zm-4-.75H5v1.5h6v-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M17 11H7v2h10v-2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z"
    }));
  })());
};
MinusCircle.displayName = 'MinusCircle';
MinusCircle.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const NewTab = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.917 4A3.917 3.917 0 004 7.917v16.166A3.917 3.917 0 007.917 28h16.166A3.917 3.917 0 0028 24.083V19h-2.5v5.083c0 .783-.634 1.417-1.417 1.417H7.917A1.417 1.417 0 016.5 24.083V7.917c0-.783.634-1.417 1.417-1.417H13V4H7.917zM26.75 4H16v2.5h7.732L13.116 17.116l1.768 1.768L25.5 8.268V16H28V4h-1.25z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12.5 12V9H14v3a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3v1.5H4a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h8a.5.5 0 00.5-.5zm-4-8.5h2.94L6.97 7.97l1.06 1.06 4.47-4.47V7.5H14V2H8.5v1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M6 3a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3v-4h-2v4a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h4V3H6z"
    }), React.createElement("path", {
      d: "M21 3h-9v2h5.586l-8.293 8.293 1.414 1.414L19 6.414V12h2V3z"
    }));
  })());
};
NewTab.displayName = 'NewTab';
NewTab.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Office = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        d: "M7 8h5v2.5H7V8z"
      }), React.createElement("path", {
        d: "M12 13H7v2.5h5V13z"
      }), React.createElement("path", {
        d: "M7 18h5v2.5H7V18z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2 3h15v24.5h6.5v-3.65a5.252 5.252 0 01-4-5.1v-2.5a5.25 5.25 0 1110.5 0v2.5a5.252 5.252 0 01-4 5.1v3.65h4V30H2V3zm2.5 2.5h10v22h-3.75V25h-2.5v2.5H4.5v-22zm23 10.75v2.5a2.75 2.75 0 11-5.5 0v-2.5a2.75 2.75 0 115.5 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.75 13.5v-1.192h-1.5V13.5H2.5v-11h5v11H5.75zM9 1H1v14h14v-1.5h-1.75v-1.614a2.501 2.501 0 10-1.5 0V13.5H9V1zM4 5.5h2V4H4v1.5zm0 2.75h2v-1.5H4v1.5zm8.5 2.25a1 1 0 110-2 1 1 0 010 2z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M7 10h2v2H7v-2z"
    }), React.createElement("path", {
      d: "M9 6H7v2h2V6z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 22V2h10v18h3.5v-2.145a3.502 3.502 0 011-6.855 3.5 3.5 0 011 6.855V20H20v2H3zM5 4h6v16H9v-2H7v2H5V4zm12.5 12a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    }));
  })());
};
Office.displayName = 'Office';
Office.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Options = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.5 16a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm8 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm5.5 2.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm4.5 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm3 1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M16 12c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm-2 0c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm-6 0c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2z"
    }));
  })());
};
Options.displayName = 'Options';
Options.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Pen = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22.778 7.957a3.25 3.25 0 00-2.086-4.095l-1.427-.464a3.25 3.25 0 00-4.095 2.087L9.205 23.843l2.68 4.695 4.929-2.223 5.964-18.358zM19.92 6.24a.75.75 0 01.482.945l-.618 1.902-2.853-.927.618-1.902a.75.75 0 01.945-.482l1.426.464zm-3.762 4.297l2.853.927-4.232 13.027-1.848.833-1.006-1.76 4.233-13.027z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.723 3.696A1.75 1.75 0 0010.6 1.491l-.951-.309a1.75 1.75 0 00-2.205 1.124l-3.11 9.568 1.445 2.835 2.835-1.444 3.11-9.569zm-1.587-.778a.25.25 0 01.16.315l-.385 1.189-1.427-.464.386-1.189a.25.25 0 01.315-.16l.951.309zM8.02 5.385l1.427.463L7.38 12.21l-.945.482-.482-.945L8.02 5.385z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.17 5.794a2.5 2.5 0 00-1.604-3.15l-.951-.31a2.5 2.5 0 00-3.15 1.606l-4.61 14.184 1.927 3.78 3.78-1.926 4.609-14.184zm-2.222-1.248a.5.5 0 01.32.63l-.463 1.427-1.902-.618.464-1.427a.5.5 0 01.63-.32l.95.308zm-2.663 3.34l1.902.619-3.271 10.068-1.26.642-.642-1.26 3.271-10.068z"
    }));
  })());
};
Pen.displayName = 'Pen';
Pen.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const PenUnderline = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M20.267 3.795a3.25 3.25 0 012.087 4.095l-1.005 3.091-3.94 12.126-.17.526-.505.227-3.388 1.528-1.036.467-.563-.987-1.843-3.227-.274-.48.17-.526 3.94-12.126 1.005-3.091a3.25 3.25 0 014.095-2.087l1.427.464zm-.291 3.323a.75.75 0 00-.482-.946l-1.426-.463a.75.75 0 00-.945.482l-.618 1.902 2.853.927.618-1.902zm-1.39 4.28l-2.854-.928-3.383 10.411 1.005 1.76 1.849-.832 3.382-10.412zM4 28.5h24V26H4v2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.252 1.936a1.75 1.75 0 011.124 2.205l-.618 1.902-1.7 5.23-.096.296-.276.141-1.89.963-.642.327H14v1.5H2V13h4.12l-.333-.655-.963-1.89-.141-.276.096-.296 1.7-5.23.617-1.903a1.75 1.75 0 012.205-1.123l.951.309zM9.95 3.677a.25.25 0 00-.16-.315l-.951-.309a.25.25 0 00-.315.16l-.386 1.19 1.426.463.386-1.189zM9.1 6.293l-1.426-.464-1.372 4.222.482.945.945-.481L9.1 6.293z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.102 2.281a2.5 2.5 0 011.605 3.15l-.772 2.378-.003.008-3.298 9.85-.13.387-.364.186-2.52 1.284-.891.454-.454-.891-1.284-2.52-.19-.374.133-.398 3.296-9.844.77-2.374a2.5 2.5 0 013.151-1.605l.951.31zm-2.199 1.914l-.463 1.427 1.902.618.463-1.427a.5.5 0 00-.32-.63l-.952-.309a.5.5 0 00-.63.321zm-2.035 12.448l2.847-8.504-1.902-.618-2.85 8.511.64 1.256 1.265-.645zM4 22h16v-2H4v2z"
    }));
  })());
};
PenUnderline.displayName = 'PenUnderline';
PenUnderline.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const PerDiem = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.75 6.75c0-.69.56-1.25 1.25-1.25h6c.69 0 1.25.56 1.25 1.25V8.5h-8.5V6.75zM9.25 8.5V6.75A3.75 3.75 0 0113 3h6a3.75 3.75 0 013.75 3.75V8.5h2.5A3.75 3.75 0 0129 12.25v13A3.75 3.75 0 0125.25 29H6.75A3.75 3.75 0 013 25.25v-13A3.75 3.75 0 016.75 8.5h2.5zM5.5 12.25c0-.69.56-1.25 1.25-1.25h1.333v15.5H6.75c-.69 0-1.25-.56-1.25-1.25v-13zm5.083 14.25V11h10.834v15.5H10.583zm13.334 0h1.333c.69 0 1.25-.56 1.25-1.25v-13c0-.69-.56-1.25-1.25-1.25h-1.333v15.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6 3.25a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.875H6V3.25zm-1.5.875V3.25a2 2 0 012-2h3a2 2 0 012 2v.875h1.25a2 2 0 012 2v6.625a2 2 0 01-2 2h-9.5a2 2 0 01-2-2V6.125a2 2 0 012-2H4.5zm-1.75 2a.5.5 0 01.5-.5h.5v7.625h-.5a.5.5 0 01-.5-.5V6.125zm2.5 7.125V5.625h5.5v7.625h-5.5zm7 0h.5a.5.5 0 00.5-.5V6.125a.5.5 0 00-.5-.5h-.5v7.625z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9 5a1 1 0 011-1h4a1 1 0 011 1v1.5H9V5zM7 6.5H5a3 3 0 00-3 3V19a3 3 0 003 3h14a3 3 0 003-3V9.5a3 3 0 00-3-3h-2V5a3 3 0 00-3-3h-4a3 3 0 00-3 3v1.5zm-3 3a1 1 0 011-1h1V20H5a1 1 0 01-1-1V9.5zM8 20V8.5h8V20H8zm10 0h1a1 1 0 001-1V9.5a1 1 0 00-1-1h-1V20z"
    }));
  })());
};
PerDiem.displayName = 'PerDiem';
PerDiem.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Pin = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.5 14c0 4.157 2.288 8.005 8.5 12.044 6.212-4.039 8.5-7.887 8.5-12.044 0-4.673-3.576-8.5-8.5-8.5-4.924 0-8.5 3.827-8.5 8.5zm9.167 14.583L16 29l-.667-.417C8.283 24.178 5 19.54 5 14 5 8 9.643 3 16 3s11 5 11 11c0 5.54-3.284 10.178-10.333 14.583zM18.5 14a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm2.5 0a5 5 0 11-10 0 5 5 0 0110 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4 6.5c0 2.088 1.197 4.774 4 6.716 2.803-1.942 4-4.628 4-6.716 0-2.204-1.681-4-4-4-2.318 0-4 1.796-4 4zm4.334 8.291L8 15l-.334-.209C4.142 12.59 2.5 9.27 2.5 6.5 2.5 3.5 4.821 1 8 1s5.5 2.5 5.5 5.5c0 2.77-1.642 6.089-5.166 8.291zM9 6.5a1 1 0 11-2 0 1 1 0 012 0zm1.5 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.75 10.5a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zm-1.875 0a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.5 21.687L12 22l-.5-.313c-5.287-3.303-8-7.033-8-11.188A8.5 8.5 0 0112 2a8.5 8.5 0 018.5 8.499c0 4.155-2.713 7.885-8 11.188zM5.407 10.5c0 3.086 1.918 6.197 6.594 9.247 4.676-3.05 6.594-6.161 6.594-9.247A6.594 6.594 0 0012 3.906a6.594 6.594 0 00-6.594 6.593z"
    }));
  })());
};
Pin.displayName = 'Pin';
Pin.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Play = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 28l19-12L8 4v24zm2.5-19.464v14.928L22.318 16 10.5 8.536z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.5 1.5v13l10-6.5-10-6.5zM6 4.264v7.472L11.748 8 6 4.264z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 21l14-9L7 3v18zM9 6.663v10.674L17.302 12 9 6.663z"
    }));
  })());
};
Play.displayName = 'Play';
Play.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const PlayCircle = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M26.834 16c0 5.983-4.85 10.833-10.834 10.833-5.983 0-10.833-4.85-10.833-10.833 0-5.983 4.85-10.834 10.833-10.834 5.983 0 10.834 4.85 10.834 10.834zm2.5 0c0 7.364-5.97 13.333-13.334 13.333-7.363 0-13.333-5.97-13.333-13.333C2.667 8.636 8.637 2.666 16 2.666c7.364 0 13.334 5.97 13.334 13.334zm-16.001 5.333l8-5.333-8-5.334v10.667z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.166 8A5.167 5.167 0 112.833 8a5.167 5.167 0 0110.333 0zm1.5 0A6.667 6.667 0 111.333 8a6.667 6.667 0 0113.333 0zm-7.999 2.667l4-2.667-4-2.667v5.334z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M16 12l-6-4v8l6-4z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z"
    }));
  })());
};
PlayCircle.displayName = 'PlayCircle';
PlayCircle.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Plus = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15 17.5V28h2.5V17.5H28V15H17.5V4.5H15V15H4.5v2.5H15z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 7.25V2.5h1.5v4.75h4.75v1.5H8.75v4.75h-1.5V8.75H2.5v-1.5h4.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 11V4h2v7h7v2h-7v7h-2v-7H4v-2h7z"
    }));
  })());
};
Plus.displayName = 'Plus';
Plus.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const PlusCircleFilled = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 29.25c7.318 0 13.25-5.932 13.25-13.25S23.318 2.75 16 2.75 2.75 8.682 2.75 16 8.682 29.25 16 29.25zm-1.25-20v5.5h-5.5v2.5h5.5v5.5h2.5v-5.5h5.5v-2.5h-5.5v-5.5h-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 15A7 7 0 108 1a7 7 0 000 14zM7.25 4.25v3h-3v1.5h3v3h1.5v-3h3v-1.5h-3v-3h-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM11 7v4H7v2h4v4h2v-4h4v-2h-4V7h-2z"
    }));
  })());
};
PlusCircleFilled.displayName = 'PlusCircleFilled';
PlusCircleFilled.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Pocket = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M23.665 4.256a3.917 3.917 0 00-5.058-2.76L9.492 4.59a4.066 4.066 0 00-2.744 3.494H4V23.93l.637.36 10.75 6.05.613.344.613-.345 10.75-6.05.637-.358V8.083h-3.378l-.957-3.827zm-1.62 3.827l-.806-3.221a1.417 1.417 0 00-1.829-.998l-9.114 3.092a1.565 1.565 0 00-1.022 1.127h12.77zM6.5 22.47V10.583h19V22.47L16 27.816l-9.5-5.347z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.937 2.022A2.083 2.083 0 009.219.534L4.704 2.12A2.218 2.218 0 003.232 4H2v8.025l.364.218 5.25 3.15.392.235.389-.24 5.25-3.25.355-.22V4h-1.597l-.466-1.978zM10.862 4l-.385-1.634a.583.583 0 00-.761-.417L5.2 3.535A.717.717 0 004.754 4h6.108zM3.5 11.175V5.5h9v5.582l-4.506 2.79L3.5 11.175z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.794 3.153a3 3 0 00-3.86-2.119L7.109 3.31A3.104 3.104 0 004.996 6H3v11.988l9 5.006 9-5.006V6h-2.494l-.712-2.847zM16.444 6l-.59-2.362a1 1 0 00-1.287-.706L7.74 5.207c-.37.124-.638.43-.724.793h9.428zM5 16.812V8h14v8.812l-7 3.894-7-3.894z"
    }));
  })());
};
Pocket.displayName = 'Pocket';
Pocket.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Puzzle = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M20.882 6.794a5.294 5.294 0 00-10.588 0V8H5v21h22v-8h-2.794a2.5 2.5 0 010-5H27V8h-6.118V6.794zM24.5 10.5h-6.118V6.794a2.794 2.794 0 00-5.588 0V10.5H7.5v16h17v-3h-.294a5 5 0 010-10h.294v-3z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.75 3.75a3 3 0 10-6 0V4h-2.5v11h11.5v-4h-1.254a1.5 1.5 0 010-3h1.254V4h-3v-.25zm1.5 1.75h-3V3.75a1.5 1.5 0 10-3 0V5.5h-2.5v8h8.5v-1.01a3 3 0 010-5.98V5.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16 5a4 4 0 00-8 0v1H4v16h17v-6h-2a2 2 0 110-4h2V6h-5V5zm3 3h-5V5a2 2 0 10-4 0v3H6v12h13v-2a4 4 0 010-8V8z"
    }));
  })());
};
Puzzle.displayName = 'Puzzle';
Puzzle.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const RaisedHand = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17.462 2.56a4.002 4.002 0 00-6.598 1.987A3.875 3.875 0 005.5 8.125V19.5c0 6.213 5.037 11.25 11.25 11.25S28 25.713 28 19.5V9.454l-1.212-.037c-.99-.03-1.935.16-2.788.525V5.375a3.875 3.875 0 00-6.538-2.815zm4.038 9.257V5.375a1.375 1.375 0 10-2.75 0V13h-2.5V5.5a1.5 1.5 0 00-3 0v9h-2.5V8.125a1.375 1.375 0 10-2.75 0V19.5a8.75 8.75 0 1017.5 0V12.06a4.09 4.09 0 00-3 3.943v2.113l-.811.304-.255.096A4.522 4.522 0 0018.5 22.75H16a7.022 7.022 0 014-6.339v-.409c0-1.591.564-3.048 1.5-4.185z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9 1.255a2.126 2.126 0 00-3.369.884 2.125 2.125 0 00-2.88 1.986V7.5H2.75v2.375a5.875 5.875 0 0011.75 0V4.8h-.75c-.44 0-.862.081-1.25.23V2.875A2.125 2.125 0 009 1.255zm2 4.88v-3.26a.625.625 0 10-1.25 0V7h-1.5V2.875a.625.625 0 10-1.25 0V7.5H5.5V4.125a.625.625 0 10-1.25 0v5.75a4.375 4.375 0 108.75 0V6.445A2 2 0 0011.75 8.3V9.464l-.51.172-.082.027a2.07 2.07 0 00-1.408 1.962h-1.5c0-1.376.788-2.614 2-3.207V8.3c0-.817.28-1.57.75-2.165z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15 1a3 3 0 013 3v3.416A4.983 4.983 0 0120 7h1v7.5a8.5 8.5 0 01-17 0V6a3 3 0 014.105-2.79A3.001 3.001 0 0113 1.764 2.989 2.989 0 0115 1zm4 13.5a6.5 6.5 0 01-13 0V6a1 1 0 012 0v5h2V4a1 1 0 112 0v6h2V4a1 1 0 112 0v5c-.628.835-1 1.874-1 3v.312A5.163 5.163 0 0012 17h2c0-1.361.871-2.57 2.162-3l.838-.28V12c0-1.306.835-2.417 2-2.83v5.33z"
    }));
  })());
};
RaisedHand.displayName = 'RaisedHand';
RaisedHand.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Receipt = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M20.25 25.667l5.496 3.833H27V3H5v26.5h.996l5.254-3.833 4.5 3.833 4.5-3.833zm-4.5.549l4.347-3.704 4.403 3.07V5.5h-17v19.808l3.87-2.823 4.38 3.73z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.5 13l2.5 2h.5V1h-11v14H3l2.5-2L8 15l2.5-2zm-2.5.08l2.5-2 1.5 1.2V2.5H4v9.78l1.5-1.2 2.5 2z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.5 19l3.929 3H20V2H4v20h.615L8.5 19l3.5 3 3.5-3zm-3.5.366l3.427-2.938L18 18.393V4H6v14.404l2.566-1.982L12 19.366z"
    }));
  })());
};
Receipt.displayName = 'Receipt';
Receipt.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ReceiptCheck = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M25.746 29.5l-5.496-3.833-4.5 3.833-4.5-3.833L5.996 29.5H5V3h22v26.5h-1.254zm-5.649-6.988l-4.347 3.704-4.38-3.732-3.87 2.824V5.5h17v20.083l-4.403-3.07zm1.787-10.628l-1.768-1.768-5.783 5.783-2.449-2.45-1.768 1.768 4.217 4.217 7.551-7.55z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 14.2L10.5 13 8 15l-2.5-2L4 14.2 3 15h-.5V1h11v14H13l-1-.8zm-2.437-2.371L8 13.079l-1.563-1.25-.937-.75-.937.75-.563.45V2.5h8v9.78l-.563-.451-.937-.75-.937.75zM7.69 9.287l3.334-3.25-1.048-1.074-2.81 2.74-1.142-1.115-1.048 1.074 1.667 1.625.524.51.523-.51z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M11 14.414l5.707-5.707-1.414-1.414L11 11.586 9.207 9.793l-1.414 1.414L11 14.414z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19.429 22L15.5 19 12 22l-3.5-3-3.885 3H4V2h16v20h-.571zm-4.002-5.572L12 19.366l-3.434-2.944L6 18.404V4h12v14.393l-2.573-1.965z"
    }));
  })());
};
ReceiptCheck.displayName = 'ReceiptCheck';
ReceiptCheck.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ReceiptCross = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M25.746 29.5l-5.496-3.833-4.5 3.833-4.5-3.833L5.996 29.5H5V3h22v26.5h-1.254zm-5.649-6.988l-4.347 3.704-4.38-3.732-3.87 2.824V5.5h17v20.083l-4.403-3.07zM18.828 9.828L16 12.657l-2.828-2.829-1.768 1.768 2.828 2.829-2.828 2.828 1.768 1.768L16 16.192l2.828 2.829 1.768-1.768-2.828-2.828 2.828-2.829-1.768-1.768z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 14.2L10.5 13 8 15l-2.5-2L4 14.2 3 15h-.5V1h11v14H13l-1-.8zm-2.437-2.371L8 13.079l-1.563-1.25-.937-.75-.937.75-.563.45V2.5h8v9.78l-.563-.451-.937-.75-.937.75zm-.093-6.86L8 6.44 6.53 4.97 5.47 6.03 6.94 7.5 5.47 8.97l1.06 1.06L8 8.56l1.47 1.47 1.06-1.06L9.06 7.5l1.47-1.47-1.06-1.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M8.465 8.879L10.585 11l-2.12 2.121 1.414 1.415L12 12.414l2.121 2.122 1.415-1.415L13.414 11l2.122-2.121-1.415-1.415-2.12 2.122-2.122-2.122L8.465 8.88z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19.429 22L15.5 19 12 22l-3.5-3-3.885 3H4V2h16v20h-.571zm-4.002-5.572L12 19.366l-3.434-2.944L6 18.404V4h12v14.393l-2.573-1.965z"
    }));
  })());
};
ReceiptCross.displayName = 'ReceiptCross';
ReceiptCross.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ReceiptPlus = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M24.5 28.63l-4.25-2.963-4.5 3.833-4.5-3.833-3.75 2.736L5.996 29.5H5V3h22v26.5h-1.254l-1.246-.87zm-5.872-4.866l-2.878 2.452-2.88-2.452-1.5-1.28-1.594 1.163L7.5 25.308V5.5h17v20.083l-2.82-1.967-1.583-1.104-1.469 1.252zM14.884 9v4.25H10.5v2.5h4.384V20h2.5v-4.25H21.5v-2.5h-4.116V9h-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 14.2L10.5 13 8 15l-2.5-2L4 14.2 3 15h-.5V1h11v14H13l-1-.8zm-2.437-2.371L8 13.079l-1.563-1.25-.937-.75-.937.75-.563.45V2.5h8v9.78l-.563-.451-.937-.75-.937.75zM7.25 4.5v1.956h-2v1.5h2V10h1.5V7.956h2v-1.5h-2V4.5h-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M11 7v3H8v2h3v3h2v-3h3v-2h-3V7h-2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19.429 22L15.5 19 12 22l-3.5-3-3.885 3H4V2h16v20h-.571zm-4.002-5.572L12 19.366l-3.434-2.944L6 18.404V4h12v14.393l-2.573-1.965z"
    }));
  })());
};
ReceiptPlus.displayName = 'ReceiptPlus';
ReceiptPlus.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Reimburse = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.25 5.25h29.5v21.5H5.518l3.366 3.366-1.768 1.768L.732 25.5l6.384-6.384 1.768 1.768-3.366 3.366H23.75V24A4.25 4.25 0 0128 19.75h.25v-7.5H28A4.25 4.25 0 0123.75 8v-.25H8.25V8A4.25 4.25 0 014 12.25h-.25V16h-2.5V5.25zm2.5 4.5H4A1.75 1.75 0 005.75 8v-.25h-2v2zm22.5-2V8c0 .966.784 1.75 1.75 1.75h.25v-2h-2zm2 14.5H28A1.75 1.75 0 0026.25 24v.25h2v-2z"
      }), React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 10a5 5 0 00-5 5v2a5 5 0 0010 0v-2a5 5 0 00-5-5zm2.5 5a2.5 2.5 0 00-5 0v2a2.5 2.5 0 005 0v-2z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 2.25H1.408V7.5h1.5V5H4V3.75h8V5h1.25v5.25H12v1.25H3.06l1.72-1.72-1.06-1.06-3.53 3.53 3.53 3.53 1.06-1.06L3.06 13h11.69V2.25zM9.083 7.875v-.5a1.125 1.125 0 00-2.25 0v.5a1.125 1.125 0 002.25 0zM7.958 4.75a2.625 2.625 0 00-2.625 2.625v.5a2.625 2.625 0 105.25 0v-.5A2.625 2.625 0 007.958 4.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M22 4H2v8h2V8h2V6h12v2h2v8h-2v2H4.414l2.293-2.293-1.414-1.414L.586 19l4.707 4.707 1.414-1.414L4.414 20H22V4zm-8 7.5a2 2 0 10-4 0v1a2 2 0 104 0v-1zm-2-4a4 4 0 00-4 4v1a4 4 0 008 0v-1a4 4 0 00-4-4z"
    }));
  })());
};
Reimburse.displayName = 'Reimburse';
Reimburse.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Rocket = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.936 3.172L16 2.114l-.936 1.058-3.366 3.804A10.75 10.75 0 009 14.1v1.385l-5.636 5.667-.364.366V30h26v-8.518l-.366-.366L23 15.482V14.1c0-2.624-.96-5.157-2.698-7.123l-3.366-3.804zM5.5 22.548L9 19.03v8.47H5.5v-4.952zM20.5 16v11.5h-9V14.1a8.25 8.25 0 012.07-5.467L16 5.887l2.43 2.746a8.25 8.25 0 012.07 5.466V16zm6 11.5H23v-8.482l3.5 3.5V27.5zM18 14a2 2 0 11-4 0 2 2 0 014 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.573 1.516L8 .839l-.572.677-1.686 1.991A5.25 5.25 0 004.5 6.898v.792l-2.782 2.797-.218.22V15h13v-4.31l-.22-.22-2.78-2.78v-.792a5.25 5.25 0 00-1.242-3.39L8.573 1.515zM3 11.326l1.5-1.508V13.5H3v-2.174zM10 8v5.5H6V6.898c0-.887.314-1.745.887-2.422L8 3.161l1.113 1.315A3.75 3.75 0 0110 6.898V8zm2.25 5.5h-.75V9.81l1.5 1.5v2.19h-.75zM9 7a1 1 0 11-2 0 1 1 0 012 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M12 11.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 22v-5.414l4-4V9.534a7 7 0 011.622-4.48L12 1l3.378 4.053A7 7 0 0117 9.534v3.052l4 4V22H3zM9 9.534V20h6V9.534a5 5 0 00-1.159-3.2L12 4.123l-1.841 2.21A5 5 0 009 9.533zm-2 5.88V20H5v-2.586l2-2zm12 2l-2-2V20h2v-2.586z"
    }));
  })());
};
Rocket.displayName = 'Rocket';
Rocket.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Sandwich = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.25 10.5a4 4 0 118 0h2.5a6.472 6.472 0 00-1.175-3.728 3.982 3.982 0 013.187 1.084A3.985 3.985 0 0119 10.75h2.5a6.473 6.473 0 00-1.065-3.566 4 4 0 015.815 3.566v2.75h-21v-3zm4-6.5c1.132 0 2.2.29 3.128.801A6.481 6.481 0 0115 4.25c1.341 0 2.59.407 3.625 1.104A6.5 6.5 0 0128.75 10.75V16h-26v-5.5A6.5 6.5 0 019.25 4zm8.884 17.313a1.9 1.9 0 00-2.73-.196l-.564.508a4.011 4.011 0 01-5.6-.227 2.432 2.432 0 00-3.343-.183L3.56 23.202 1.94 21.297l2.338-1.987a4.932 4.932 0 016.78.372c.563.596 1.5.634 2.11.085l.564-.508a4.399 4.399 0 016.323.454 1.112 1.112 0 001.64.074l.21-.21a4.762 4.762 0 017.27.636l1.099 1.57-2.048 1.434-1.1-1.57a2.262 2.262 0 00-3.452-.303l-.211.21a3.612 3.612 0 01-5.329-.24zM26.25 27.5a2.5 2.5 0 002.5-2.5h-26a2.5 2.5 0 002.5 2.5h21z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3 5.375a1.875 1.875 0 013.75 0h1.5a3.36 3.36 0 00-.568-1.874c.458.013.874.19 1.193.476.385.345.625.843.625 1.398H11a3.36 3.36 0 00-.5-1.768A1.875 1.875 0 0113 5.375V6.5H3V5.375zM4.875 2c.489 0 .954.104 1.375.292C6.67 2.104 7.136 2 7.625 2c.64 0 1.24.179 1.75.489A3.375 3.375 0 0114.5 5.375V8h-13V5.375A3.375 3.375 0 014.875 2zm4.242 8.786a1.133 1.133 0 00-1.396-.075l-.358.25c-.83.582-1.951.521-2.714-.147a1.188 1.188 0 00-1.463-.079l-1.256.88-.86-1.23 1.256-.879a2.688 2.688 0 013.31.18.703.703 0 00.867.046l.358-.25a2.633 2.633 0 013.243.175c.242.211.6.22.85.019l.083-.066a2.75 2.75 0 013.787.336l.49.56-1.129.988-.49-.56a1.25 1.25 0 00-1.72-.153l-.084.066a2.162 2.162 0 01-2.774-.06zM14.5 12.5h-13v.167c0 .736.597 1.333 1.333 1.333h10.334c.736 0 1.333-.597 1.333-1.333V12.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.25 3.824A5 5 0 0122 8v4H2V8a5 5 0 017.25-4.466A4.979 4.979 0 0111.5 3c1.016 0 1.961.303 2.75.824zm1.455 1.47c.503.78.795 1.709.795 2.706h-2a3 3 0 00-3.472-2.963C11.638 5.867 12 6.89 12 8h-2a3 3 0 00-6 0v2h16V8a3 3 0 00-4.295-2.707zM2 18.5a2 2 0 002 2h16a2 2 0 002-2H2zm16.61-3.02a1 1 0 011.42.118l1.202 1.442 1.536-1.28-1.202-1.442a3 3 0 00-4.257-.357l-.702.602a1 1 0 01-1.276.021l-.62-.496a3 3 0 00-3.418-.23l-1.615.97a1 1 0 01-1.14-.077l-.828-.663a3 3 0 00-3.417-.23l-2.808 1.685 1.03 1.715 2.807-1.685a1 1 0 011.14.077l.827.662a3 3 0 003.418.23l1.615-.969a1 1 0 011.14.077l.62.496a3 3 0 003.826-.065l.703-.602z"
    }));
  })());
};
Sandwich.displayName = 'Sandwich';
Sandwich.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Search = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M25.5 15a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zm2.5 0c0 6.075-4.925 11-11 11-2.587 0-4.966-.893-6.844-2.388l-4.272 4.272-1.768-1.768 4.272-4.272A10.953 10.953 0 016 15C6 8.925 10.925 4 17 4s11 4.925 11 11z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12.5 7.5a4 4 0 11-8 0 4 4 0 018 0zm1.5 0a5.5 5.5 0 01-8.823 4.383L3.03 14.03l-1.06-1.06 2.147-2.147A5.5 5.5 0 1114 7.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 11a6 6 0 11-12 0 6 6 0 0112 0zm2 0a8 8 0 01-12.906 6.32l-3.387 3.387-1.414-1.414 3.387-3.387A8 8 0 1121 11z"
    }));
  })());
};
Search.displayName = 'Search';
Search.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const SendMoney = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M24.884 12.884l-1.768-1.768 3.366-3.366H8.272a4.387 4.387 0 01-4.522 4.523v7.454a4.387 4.387 0 014.522 4.523h15.456a4.387 4.387 0 014.522-4.523V16h2.5v10.75H1.25V5.25h25.232l-3.366-3.366L24.884.116 31.268 6.5l-6.384 6.384zM5.769 7.75H3.75v2.02a1.886 1.886 0 002.019-2.02zM3.75 24.25v-2.02a1.886 1.886 0 012.019 2.02H3.75zm24.5-2.02a1.886 1.886 0 00-2.019 2.02h2.019v-2.02zM16 19.5a2.5 2.5 0 01-2.5-2.5v-2a2.5 2.5 0 115 0v2a2.5 2.5 0 01-2.5 2.5zM11 17a5 5 0 1010 0v-2a5 5 0 10-10 0v2z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.22 6.22l1.06 1.06 3.53-3.53L12.28.22l-1.06 1.06L12.94 3H1.25v10.75h13.342V8.5h-1.5V11H12v1.25H4V11H2.75V5.75H4V4.5h8.939l-1.72 1.72zM6.916 8.125v.5a1.125 1.125 0 002.25 0v-.5a1.125 1.125 0 00-2.25 0zm1.125 3.125a2.625 2.625 0 002.625-2.625v-.5a2.625 2.625 0 10-5.25 0v.5a2.625 2.625 0 002.625 2.625z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19.586 6l-2.293 2.293 1.414 1.414L23.414 5 18.707.293l-1.414 1.414L19.586 4H2v16h20v-8h-2v4h-2v2H6v-2H4V8h2V6h13.586zM10 11.5v1a2 2 0 104 0v-1a2 2 0 10-4 0zm2 5a4 4 0 004-4v-1a4 4 0 00-8 0v1a4 4 0 004 4z"
    }));
  })());
};
SendMoney.displayName = 'SendMoney';
SendMoney.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Share = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M23.884 11.448L16 3.564l-7.884 7.884 1.768 1.768L14.75 8.35v12.982h2.5V8.35l4.866 4.866 1.768-1.768zm-19.8 5.884v6.667a3.917 3.917 0 003.917 3.916h16A3.917 3.917 0 0027.917 24v-6.667h-2.5v6.667c0 .782-.634 1.416-1.416 1.416H8A1.417 1.417 0 016.584 24v-6.667h-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12.03 5.634L8 1.604l-4.03 4.03 1.06 1.06 2.22-2.22v6.19h1.5v-6.19l2.22 2.22 1.06-1.06zM1.918 8.664v3.333c0 1.151.933 2.084 2.083 2.084h8c1.15 0 2.084-.933 2.084-2.084V8.664h-1.5v3.333a.583.583 0 01-.584.584h-8a.583.583 0 01-.583-.584V8.664h-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.957 8.543L12 2.586 6.043 8.543l1.414 1.414L11 6.414V16h2V6.414l3.543 3.543 1.414-1.414zM3 13v5a3 3 0 003 3h12a3 3 0 003-3v-5h-2v5a1 1 0 01-1 1H6a1 1 0 01-1-1v-5H3z"
    }));
  })());
};
Share.displayName = 'Share';
Share.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Shield = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 3.682L27.75 7.6v7.456a12.25 12.25 0 01-6.983 11.06L16 28.385l-4.767-2.27a12.25 12.25 0 01-6.983-11.06V7.599L16 3.682zm-1.25 3.052l-8 2.667v5.654a9.75 9.75 0 005.558 8.802l2.442 1.163V6.734zm2.5 18.286l2.442-1.163a9.75 9.75 0 005.558-8.802V9.4l-8-2.667V25.02z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 1.71l6 2v3.817a6.25 6.25 0 01-3.563 5.643L8 14.33l-2.437-1.16A6.25 6.25 0 012 7.527V3.71l6-2zm-.75 1.83L3.5 4.79v2.737a4.75 4.75 0 002.708 4.289l1.042.496V3.541zm1.5 8.772l1.042-.496A4.75 4.75 0 0012.5 7.527V4.791L8.75 3.54v8.771z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 6l9-3 9 3v5.704a9 9 0 01-4.63 7.868L12 22l-4.37-2.428A9 9 0 013 11.704V6zm2 5.704V7.442l6-2v13.715l-2.4-1.334A7 7 0 015 11.704zm8 7.453l2.4-1.334a7 7 0 003.6-6.119V7.442l-6-2v13.715z"
    }));
  })());
};
Shield.displayName = 'Shield';
Shield.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ShieldLock = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7 24.5h8.043V27H7a4 4 0 01-4-4V10a4 4 0 014-4h18a4 4 0 014 4v5H5.5v8A1.5 1.5 0 007 24.5zm-1.5-12h21V10A1.5 1.5 0 0025 8.5H7A1.5 1.5 0 005.5 10v2.5zM8 19h4v2.5H8V19zm16 1.214l4.5 1.902v.516a6.833 6.833 0 01-3.542 5.989l-.958.526-.958-.526a6.833 6.833 0 01-3.542-5.989v-.516l4.5-1.902zm2.162 10.598L24 32l-2.162-1.188A9.333 9.333 0 0117 22.632V20.46l7-2.959 7 2.959v2.173a9.333 9.333 0 01-4.838 8.18z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.5 12h4.022v1.5H3.5a2 2 0 01-2-2V5a2 2 0 012-2h9a2 2 0 012 2v2.5H3v4a.5.5 0 00.5.5zM3 6.25h10V5a.5.5 0 00-.5-.5h-9A.5.5 0 003 5v1.25zm1 3.375h2v1.25H4v-1.25zm8 .754l2 .845v.092c0 1.155-.63 2.219-1.642 2.775l-.358.197-.358-.197A3.166 3.166 0 0110 11.316v-.092l2-.845zm1.08 5.027L12 16l-1.08-.594a4.667 4.667 0 01-2.42-4.09V10.23L12 8.75l3.5 1.48v1.086c0 1.703-.927 3.27-2.42 4.09z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 18H5a1 1 0 01-1-1v-6h18V7a3 3 0 00-3-3H5a3 3 0 00-3 3v10a3 3 0 003 3h6v-2zM4 7v2h16V7a1 1 0 00-1-1H5a1 1 0 00-1 1z"
    }), React.createElement("path", {
      d: "M9 16H6v-2h3v2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19.499 23.135L18 24l-1.499-.865A7 7 0 0113 17.072v-1.728L18 13l5 2.344v1.728a7 7 0 01-3.501 6.063zM18 15.21l3 1.407v.456a5 5 0 01-2.501 4.33l-.499.289-.499-.288A5 5 0 0115 17.073v-.457l3-1.407z"
    }));
  })());
};
ShieldLock.displayName = 'ShieldLock';
ShieldLock.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ShoppingBag = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.5 7.75a2.5 2.5 0 015 0V11h-5V7.75zM11 13.5V17h2.5v-3.5h5V17H21v-3.5h3V25a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 018 25V13.5h3zm0-2.5V7.75a5 5 0 0110 0V11h5.5v14a4 4 0 01-4 4h-13a4 4 0 01-4-4V11H11z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7 3.5a1 1 0 012 0V5H7V3.5zm-1.5 3V8H7V6.5h2V8h1.5V6.5H12v6a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-6h1.5zm0-1.5V3.5a2.5 2.5 0 015 0V5h3v7.5a2 2 0 01-2 2h-7a2 2 0 01-2-2V5h3z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10 6a2 2 0 114 0v2h-4V6zm-2 4v2h2v-2h4v2h2v-2h2v9a1 1 0 01-1 1H7a1 1 0 01-1-1v-9h2zm0-2V6a4 4 0 118 0v2h4v11a3 3 0 01-3 3H7a3 3 0 01-3-3V8h4z"
    }));
  })());
};
ShoppingBag.displayName = 'ShoppingBag';
ShoppingBag.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Snowflake = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 14.75v-3.21L8.5 6.542l1.666-2.082 4.584 3.667V3h2.5v5.126l4.584-3.667L23.5 6.54l-6.25 5v3.209h3.21l4.999-6.25 2.082 1.666-3.667 4.584H29v2.5h-5.126l3.667 4.584-2.082 1.666-5-6.25H17.25v3.21l6.25 4.999-1.666 2.082-4.584-3.667V29h-2.5v-5.126l-4.584 3.667L8.5 25.46l6.25-5V17.25h-3.209l-5 6.25-2.082-1.666 3.667-4.584H3v-2.5h5.126l-3.667-4.584 2.082-1.665 5 6.249h3.209z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.25 3.899V2h1.5v1.899l1.77-1.475.96 1.152-2.73 2.275V7.25h1.399l2.275-2.73 1.152.96-1.475 1.77H14v1.5h-1.899l1.475 1.77-1.152.96-2.275-2.73H8.75v1.399l2.73 2.275-.96 1.152-1.77-1.475V14h-1.5v-1.899l-1.77 1.475-.96-1.152 2.73-2.275V8.75H5.851l-2.275 2.73-1.152-.96 1.475-1.77H2v-1.5h1.899L2.424 5.48l1.152-.96 2.275 2.73H7.25V5.851L4.52 3.576l.96-1.152 1.77 1.475z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M13 2h-2v3.92l-3.375-2.7-1.25 1.56L11 8.48V11H8.48L4.782 6.375l-1.562 1.25L5.92 11H2v2h3.92l-2.7 3.375 1.561 1.25L8.481 13H11v2.52l-4.625 3.7 1.25 1.56L11 18.08V22h2v-3.92l3.375 2.7 1.25-1.56L13 15.52V13h2.52l3.699 4.625 1.562-1.25L18.08 13H22v-2h-3.92l2.7-3.375-1.561-1.25-3.7 4.625H13V8.48l4.625-3.7-1.25-1.56L13 5.92V2z"
    }));
  })());
};
Snowflake.displayName = 'Snowflake';
Snowflake.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const SpeechBubble = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.333 25.333h16a4 4 0 004-4V8a4 4 0 00-4-4H6.667a4 4 0 00-4 4v21.333l6.666-4zm-4.167-.415l3.475-2.085h16.692a1.5 1.5 0 001.5-1.5V8a1.5 1.5 0 00-1.5-1.5H6.667a1.5 1.5 0 00-1.5 1.5v16.918z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.666 11.667h7a2 2 0 002-2V3a2 2 0 00-2-2H3.333a2 2 0 00-2 2v11.667l4.333-3zm-2.833.137l2.365-1.637h7.468a.5.5 0 00.5-.5V3a.5.5 0 00-.5-.5H3.333a.5.5 0 00-.5.5v8.804z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 19h12a3 3 0 003-3V6a3 3 0 00-3-3H5a3 3 0 00-3 3v16l5-3zm-3-.532L6.446 17H19a1 1 0 001-1V6a1 1 0 00-1-1H5a1 1 0 00-1 1v12.468z"
    }));
  })());
};
SpeechBubble.displayName = 'SpeechBubble';
SpeechBubble.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Spinner = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.75 8.2V3h2.5v5.2h-2.5zm2.5 15.6V29h-2.5v-5.2h2.5zm4.872-12.783l4.503-2.6 1.25 2.165-4.503 2.6-1.25-2.165zm-12.26 9.965l-4.504 2.6-1.25-2.165 4.504-2.6 1.25 2.165zm18.013.436l-4.503-2.6-1.25 2.165 4.503 2.6 1.25-2.165zM4.108 10.582l4.504 2.6 1.25-2.165-4.504-2.6-1.25 2.165z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.75 4V1h-1.5v3h1.5zm0 11v-3h-1.5v3h1.5zM2.312 3.85l2.599 1.5-.75 1.3-2.598-1.5.75-1.3zm12.126 7l-2.598-1.5-.75 1.3 2.598 1.5.75-1.3zm-12.876 0l2.599-1.5.75 1.3-2.598 1.5-.75-1.3zm12.126-7l-2.598 1.5.75 1.3 2.598-1.5-.75-1.3z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 6V2h2v4h-2zm2 12v4h-2v-4h2zm3.696-9.866l3.464-2 1 1.732-3.464 2-1-1.732zm-9.392 7.732l-3.464 2-1-1.732 3.464-2 1 1.732zm13.857.268l-3.464-2-1 1.732 3.464 2 1-1.732zM2.84 7.866l3.463 2 1-1.732-3.464-2-1 1.732z"
    }));
  })());
};
Spinner.displayName = 'Spinner';
Spinner.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Spreadsheet = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.81 5.5H10A1.5 1.5 0 008.5 7v18a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5V12.457L16.81 5.5zM17.876 3H10a4 4 0 00-4 4v18a4 4 0 004 4h12a4 4 0 004-4V11.45L17.875 3zM17 16.25h4v2.5h-4v-2.5zm-2 0h-4v2.5h4v-2.5zM17 21h4v2.5h-4V21zm-2 0h-4v2.5h4V21z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.355 2.5H4.5A.5.5 0 004 3v10a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V6.348L8.355 2.5zM9 1H4.5a2 2 0 00-2 2v10a2 2 0 002 2h7a2 2 0 002-2V5.75L9 1zm-.25 6.75h2v1.5h-2v-1.5zm2 3h-2v1.5h2v-1.5zm-5.5-3h2v1.5h-2v-1.5zm2 3h-2v1.5h2v-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M8 12h3v2H8v-2z"
    }), React.createElement("path", {
      d: "M16 12h-3v2h3v-2z"
    }), React.createElement("path", {
      d: "M8 16h3v2H8v-2z"
    }), React.createElement("path", {
      d: "M16 16h-3v2h3v-2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.5 2H7a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3V8.5L13.5 2zm-.828 2H7a1 1 0 00-1 1v14a1 1 0 001 1h10a1 1 0 001-1V9.328L12.672 4z"
    }));
  })());
};
Spreadsheet.displayName = 'Spreadsheet';
Spreadsheet.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Store = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.736 4h20.528l4.236 6.635V16.5h-2v8.083a3.917 3.917 0 01-3.917 3.917H7.417A3.917 3.917 0 013.5 24.583V16.5h-2v-5.865L5.736 4zM6 16.5h20v8.083c0 .783-.634 1.417-1.417 1.417h-3.208v-2.96c0-2.9-2.35-5.25-5.25-5.25h-.25a5.25 5.25 0 00-5.25 5.25V26H7.417A1.417 1.417 0 016 24.583V16.5zm1.106-10L4 11.365V14h3.5v-3.246L9.239 6.5H7.106zm4.834 0L10 11.246V14h4.75V6.5h-2.81zm8.12 0h-2.81V14H22v-2.754L20.06 6.5zm2.701 0l1.739 4.254V14H28v-2.635L24.894 6.5h-2.133zM18.875 26v-2.96a2.75 2.75 0 00-2.75-2.75h-.25a2.75 2.75 0 00-2.75 2.75V26h5.75z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.92 2h10.16L15 5.032v3.051h-.917v3.834C14.083 13.067 13.15 14 12 14H4a2.083 2.083 0 01-2.083-2.083V8.083H1v-3.05L2.92 2zm.497 6.083h9.166v3.834A.583.583 0 0112 12.5h-1.25v-1a2.75 2.75 0 10-5.5 0v1H4a.583.583 0 01-.583-.583V8.083zm.33-4.583L2.5 5.468v1.115h1.417V5.091L4.623 3.5h-.878zm2.518 0l-.848 1.91v1.173H7.25V3.5h-.985zm3.47 0H8.75v3.083h1.833V5.41L9.735 3.5zm1.641 0l.707 1.59v1.493H13.5V5.468L12.254 3.5h-.878zm-2.126 9v-1a1.25 1.25 0 10-2.5 0v1h2.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.446 3h15.108L22.5 7.713V12H21v6a3 3 0 01-3 3H6a3 3 0 01-3-3v-6H1.5V7.713L4.446 3zM5 12h14v6a1 1 0 01-1 1h-2v-2a4 4 0 00-8 0v2H6a1 1 0 01-1-1v-6zm.554-7L3.5 8.287V10H6V7.795L7.198 5H5.554zm3.82 0L8 8.205V10h3V5H9.374zm5.252 0H13v5h3V8.205L14.626 5zm2.176 0L18 7.795V10h2.5V8.287L18.446 5h-1.644zM14 17a2 2 0 10-4 0v2h4v-2z"
    }));
  })());
};
Store.displayName = 'Store';
Store.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Swap = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M19.058 3.888l4.404 4.362H5v2.5h18.462l-4.404 4.362 1.759 1.776 7.46-7.388-7.46-7.388-1.76 1.776zm-5.836 24.204L8.637 23.75H27v-2.5H8.637l4.585-4.343-1.719-1.815-7.82 7.408 7.82 7.407 1.72-1.815z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.47 1.864l2.052 2.053H2.666v1.5h8.856L9.47 7.47l1.061 1.06 3.333-3.333.53-.53-.53-.53L10.53.802l-1.06 1.06zM6.996 14.136l-2.053-2.052h8.39v-1.5h-8.39L6.997 8.53l-1.06-1.06-3.334 3.333-.53.53.53.53 3.333 3.334 1.061-1.06z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M14.293 2.707L17.586 6H4v2h13.586l-3.293 3.293 1.414 1.414L21.414 7l-5.707-5.707-1.414 1.414z"
    }), React.createElement("path", {
      d: "M7.121 18l3.293 3.293L9 22.707 3.293 17 9 11.293l1.414 1.414L7.121 16h12.88v2H7.12z"
    }));
  })());
};
Swap.displayName = 'Swap';
Swap.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Tag = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 6.5h7.343a1.5 1.5 0 011.06.44l1.769-1.768-1.768 1.767 9 9a1.5 1.5 0 010 2.122l-7.343 7.343a1.5 1.5 0 01-2.122 0l-9-9a1.5 1.5 0 01-.439-1.06V8A1.5 1.5 0 018 6.5zm19.172 7.672l-9-9A4 4 0 0015.343 4H8a4 4 0 00-4 4v7.343a4 4 0 001.172 2.829l9 9a4 4 0 005.656 0l7.344-7.344a4 4 0 000-5.656zM11 13a2 2 0 100-4 2 2 0 000 4z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.25 2.5h4.318a.75.75 0 01.53.22l1.06-1.061-1.06 1.06 5.25 5.25a.75.75 0 010 1.06L9.03 13.349a.75.75 0 01-1.06 0l-5.25-5.25a.75.75 0 01-.22-.53V3.25a.75.75 0 01.75-.75zm11.158 4.409l-5.25-5.25A2.25 2.25 0 007.569 1H3.25A2.25 2.25 0 001 3.25v4.318c0 .596.237 1.169.659 1.59l5.25 5.25a2.25 2.25 0 003.182 0l4.317-4.317a2.25 2.25 0 000-3.182zM5.125 6.25a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M8.5 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 11.757a3 3 0 00.879 2.122l7 7a3 3 0 004.242 0l5.758-5.758a3 3 0 000-4.242l-7-7A3 3 0 0011.757 3H6a3 3 0 00-3 3v5.757zM6 5h5.757a1 1 0 01.707.293l7 7a1 1 0 010 1.414l-5.757 5.758a1 1 0 01-1.414 0l-7-7A1 1 0 015 11.756V6a1 1 0 011-1z"
    }));
  })());
};
Tag.displayName = 'Tag';
Tag.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Team = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.25 6.5a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM4 9.25a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm-.25 14.666c0-3.236 2.678-5.416 5.375-5.416s5.375 2.18 5.375 5.417V24H3.75v-.084zM9.125 16c-3.928 0-7.875 3.154-7.875 7.916V26.5H17v-2.583C17 19.154 13.053 16 9.125 16zM30.5 26.5H19.75V24H28v-.083c0-3.237-2.678-5.417-5.375-5.417-1.316 0-2.628.519-3.626 1.427a10.304 10.304 0 00-1.228-2.23C19.168 16.609 20.898 16 22.625 16c3.928 0 7.875 3.154 7.875 7.917V26.5zM20 9.25a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zM22.75 4a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.75 3.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM2 4.75a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zM7.499 12H2.25c.046-1.484 1.316-2.5 2.624-2.5s2.578 1.016 2.624 2.5zM4.875 8C2.832 8 .75 9.613.75 12.083V13.5H9v-1.417C9 9.613 6.918 8 4.875 8zM15.5 13.5h-5.25v-1.417V12h3.749c-.046-1.484-1.316-2.5-2.624-2.5-.55 0-1.093.18-1.54.498-.202-.472-.468-.9-.784-1.28A4.212 4.212 0 0111.375 8c2.043 0 4.125 1.613 4.125 4.083V13.5zm-5.25-8.75a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM11.5 2a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 11a4 4 0 100-8 4 4 0 000 8zm0-2a2 2 0 100-4 2 2 0 000 4z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 14.682A6 6 0 001 18v2h12v-2a5.973 5.973 0 00-1-3.318zM7 14a4 4 0 014 4H3a4 4 0 014-4z"
    }), React.createElement("path", {
      d: "M23 18v2h-8v-2h6a4 4 0 00-6.598-3.041 7.992 7.992 0 00-1.002-1.76A6 6 0 0123 18z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M21 7a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
    }));
  })());
};
Team.displayName = 'Team';
Team.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ThumbDown = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M21.049 28.583h-2.383a5.25 5.25 0 01-5.25-5.25V21.25H8.803c-3.553 0-6.08-3.456-5.002-6.842l2.12-6.667a5.25 5.25 0 015.004-3.658h16.991V17.25H23.05l-2 11.333zm-2.098-2.5l2-11.333h4.465V6.583H10.925A2.75 2.75 0 008.305 8.5l-2.122 6.667a2.75 2.75 0 002.62 3.584h7.113v4.583a2.75 2.75 0 002.75 2.75h.285z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.128 15.25H9.785a3.235 3.235 0 01-3.235-3.235v-1.098H3.914a3.235 3.235 0 01-3.078-4.23L2.03 2.99A3.235 3.235 0 015.11.75H15.25v7.803h-2.922l-1.2 6.697zm-1.256-1.5l1.2-6.697h2.678V2.25H5.109c-.753 0-1.42.485-1.651 1.202L2.263 7.148a1.735 1.735 0 001.651 2.269H8.05v2.598c0 .958.777 1.735 1.735 1.735h.087z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.839 21.5H14a4 4 0 01-4-4V16H6.603c-2.707 0-4.633-2.633-3.812-5.213l1.59-5A4 4 0 018.195 3H21v10h-3.661l-1.5 8.5zm-1.678-2l1.5-8.5H19V5H8.194a2 2 0 00-1.906 1.394l-1.591 5A2 2 0 006.603 14H12v3.5a2 2 0 002 2h.161z"
    }));
  })());
};
ThumbDown.displayName = 'ThumbDown';
ThumbDown.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const ThumbUp = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.951 3.417h2.383c2.899 0 5.25 2.35 5.25 5.25v2.083h4.613c3.553 0 6.08 3.456 5.002 6.842l-2.12 6.667a5.25 5.25 0 01-5.004 3.658H4.084V14.75H8.95l2-11.333zm2.098 2.5l-2 11.333H6.584v8.167h14.491a2.75 2.75 0 002.62-1.916l2.122-6.667a2.75 2.75 0 00-2.62-3.584h-7.113V8.667a2.75 2.75 0 00-2.75-2.75h-.285z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.372.25h1.343A3.235 3.235 0 019.95 3.485v1.098h2.636a3.235 3.235 0 013.078 4.23L14.47 12.51a3.235 3.235 0 01-3.079 2.24H1.25V6.947h2.922L5.372.25zm1.256 1.5l-1.2 6.697H2.75v4.803h8.642c.752 0 1.419-.485 1.65-1.201l1.195-3.697a1.735 1.735 0 00-1.651-2.269H8.45V3.485c0-.958-.777-1.735-1.735-1.735h-.087z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.161 2.5H10a4 4 0 014 4V8h3.397c2.708 0 4.633 2.633 3.812 5.213l-1.591 5A4 4 0 0115.806 21H3V11h3.661l1.5-8.5zm1.678 2l-1.5 8.5H5v6h10.806a2 2 0 001.906-1.394l1.591-5A2 2 0 0017.397 10H12V6.5a2 2 0 00-2-2h-.161z"
    }));
  })());
};
ThumbUp.displayName = 'ThumbUp';
ThumbUp.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Trash = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 5.5h8V3h-8v2.5zm16 4h-3V24a4 4 0 01-4 4H11a4 4 0 01-4-4V9.5H4V7h24v2.5zm-18.5 0h13V24a1.5 1.5 0 01-1.5 1.5H11A1.5 1.5 0 019.5 24V9.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.5 1h5v1.5h-5V1zm-1 4v7a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V5h-7zM3 5v7a2 2 0 002 2h6a2 2 0 002-2V5h1V3.5H2V5h1z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15 2H9v2h6V2zm6 5h-2v11a3 3 0 01-3 3H8a3 3 0 01-3-3V7H3V5h18v2zM7 7h10v11a1 1 0 01-1 1H8a1 1 0 01-1-1V7z"
    }));
  })());
};
Trash.displayName = 'Trash';
Trash.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const TrashClock = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 5.5h8V3h-8v2.5zm16 4h-3v4.25h-2.5V9.5h-13V24a1.5 1.5 0 001.5 1.5h3.5V28H11a4 4 0 01-4-4V9.5H4V7h24v2.5zm-3.75 9.25a5 5 0 100 10 5 5 0 000-10zm-7.5 5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zM25.5 20v2.85l2.895.964-.79 2.372L23 24.65V20h2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.5 1h5v1.5h-5V1zm-1 4v7a.5.5 0 00.5.5h2V14H5a2 2 0 01-2-2V5H2V3.5h12V5h-1v2h-1.5V5h-7zm7.75 4.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8.5 12a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zm3.1-1.5h1.3v.942l1.1.458-.5 1.2-1.9-.792V10.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9 2h6v2H9V2zM5 7H3V5h18v2h-2v3h-2V7H7v11a1 1 0 001 1h2v2H8a3 3 0 01-3-3V7zm9 11a4 4 0 118 0 4 4 0 01-8 0zm4-6a6 6 0 100 12 6 6 0 000-12zm1 3v2.28l2.316.771-.632 1.898L17 18.72V15h2z"
    }));
  })());
};
TrashClock.displayName = 'TrashClock';
TrashClock.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Truck = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.25 6H3v19h3.876a4.002 4.002 0 007.748 0h4.252a4.002 4.002 0 007.748 0H31V14.874a5.25 5.25 0 00-1.356-3.521l-3.276-3.624A5.25 5.25 0 0022.474 6H4.25zm10.21 16.5h4.58a4.001 4.001 0 017.42 0h2.04V18h-3.083a3.917 3.917 0 01-3.917-3.917V8.5h-16v14h1.54a4.001 4.001 0 017.42 0zm14.04-7v-.626a2.75 2.75 0 00-.71-1.845l-3.276-3.623A2.749 2.749 0 0024 8.962v5.121c0 .783.634 1.417 1.417 1.417H28.5zm-5.75 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM12.25 24a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.75 2H1v10h1.514a2.25 2.25 0 004.472 0h1.528a2.25 2.25 0 004.472 0H15V6.733c0-.65-.23-1.28-.65-1.777l-1.678-1.982a2.75 2.75 0 00-2.1-.974H1.75zm4.871 8.5H8.88a2.248 2.248 0 013.742 0h.879v-2h-1.334a2.083 2.083 0 01-2.083-2.083V3.5H2.5v7h.379a2.248 2.248 0 013.742 0zM13.5 7v-.267a1.25 1.25 0 00-.296-.808l-1.62-1.915v2.407c0 .322.26.583.582.583H13.5zm-2.75 4a.75.75 0 100 1.5.75.75 0 000-1.5zM4 11.75a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 4H1v15h4.17a3.001 3.001 0 005.66 0h3.34a3.001 3.001 0 005.66 0H23v-7.948a4 4 0 00-.877-2.498L19.683 5.5A4 4 0 0016.558 4H2zm8.83 13h3.34a3.001 3.001 0 015.66 0H21v-3h-2a3 3 0 01-3-3V6H3v11h2.17a3.001 3.001 0 015.66 0zM21 12v-.948a2 2 0 00-.438-1.25L18.12 6.752a2.019 2.019 0 00-.12-.137V11a1 1 0 001 1h2zm-4 5a1 1 0 100 2 1 1 0 000-2zM7 18a1 1 0 112 0 1 1 0 01-2 0z"
    }));
  })());
};
Truck.displayName = 'Truck';
Truck.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Upload = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M28 14.497a7.986 7.986 0 01-3 6.246v2.99a10.498 10.498 0 005.5-9.236c0-5.799-4.701-10.5-10.5-10.5-4.384 0-8.14 2.687-9.712 6.503H8.875A7.375 7.375 0 007 25.01v-2.634A4.876 4.876 0 018.875 13H11.961l.638-1.548A8.003 8.003 0 0128 14.497zm-11.116.62L16 14.231l-.884.884-5.25 5.25 1.768 1.768 3.116-3.116V28h2.5v-8.982l3.116 3.116 1.768-1.768-5.25-5.25z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14 7.375c0 1.46-.807 2.731-2 3.392v1.647A5.377 5.377 0 0010.125 2a5.377 5.377 0 00-5.039 3.5H4.5a3.75 3.75 0 00-.5 7.467v-1.523A2.25 2.25 0 014.5 7H6.128l.364-.977A3.877 3.877 0 0114 7.375zm-5.478.087L8 6.955l-.522.507-2.75 2.666 1.044 1.077L7.25 9.772V14h1.5V9.772l1.478 1.433 1.044-1.077-2.75-2.666z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.93 10H6.5A3.5 3.5 0 005 16.663v2.13A5.5 5.5 0 016.5 8h1.082A8.003 8.003 0 0123 11a7.997 7.997 0 01-4 6.93v-2.458A6 6 0 109.435 8.75L8.93 10zm3.743 1.327L12 10.653l-.673.674-4 4 1.346 1.346 2.375-2.374V21h1.904v-6.7l2.375 2.373 1.346-1.346-4-4z"
    }));
  })());
};
Upload.displayName = 'Upload';
Upload.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const User = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 5.25C10.063 5.25 5.25 10.063 5.25 16S10.063 26.75 16 26.75 26.75 21.937 26.75 16 21.937 5.25 16 5.25zM2.75 16C2.75 8.682 8.682 2.75 16 2.75S29.25 8.682 29.25 16 23.318 29.25 16 29.25 2.75 23.318 2.75 16zm13.25.5V10h-2v8.5h4.333v-2H16zm0 5.041a5.544 5.544 0 01-5.17-3.54l-2.098.812a7.795 7.795 0 0014.504.081l-2.09-.835A5.544 5.544 0 0116 21.54zm7.25-8.916a1.625 1.625 0 11-3.25 0 1.625 1.625 0 013.25 0zM10.291 14.25a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8 2.667a5.333 5.333 0 100 10.666A5.333 5.333 0 008 2.667zM1.333 8a6.667 6.667 0 1113.334 0A6.667 6.667 0 011.334 8zm6.792.125V5h-1.25v4.375H9.25v-1.25H8.125zM8 10.875a2.626 2.626 0 01-2.509-1.851l-1.164.464a3.877 3.877 0 007.346 0l-1.16-.477A2.626 2.626 0 018 10.875zm3.75-4.5a.875.875 0 11-1.75 0 .875.875 0 011.75 0zm-6.625.875a.875.875 0 100-1.75.875.875 0 000 1.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 4a8 8 0 100 16 8 8 0 000-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm10.125.125V7.5h-1.75v6.375h3.375v-1.75h-1.625zm-.125 4a4.127 4.127 0 01-3.848-2.636l-1.632.632a5.877 5.877 0 0010.936.061l-1.625-.65A4.126 4.126 0 0112 16.125zM17.5 9.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm-9.75 1.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }));
  })());
};
User.displayName = 'User';
User.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const UserCog = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.553 16.896a5.47 5.47 0 003.78 3.284v2.38a7.803 7.803 0 01-5.957-4.821l2.177-.843zM4.25 15c0 5.37 3.938 9.82 9.083 10.622v2.524C6.803 27.326 1.75 21.753 1.75 15 1.75 7.682 7.682 1.75 15 1.75c5.8 0 10.73 3.727 12.525 8.917h-2.684A10.752 10.752 0 0015 4.25C9.063 4.25 4.25 9.063 4.25 15zm4.875-2a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zm3.458-4.25h2.334v8.5h-2.334v-8.5zM20.291 13a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zm6.209 9.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-8 3.077v-6.404l5.5-3.265 5.5 3.265v6.404L24 28.842l-5.5-3.265zM16 17.75L24 13l8 4.75V27l-8 4.75L16 27v-9.25z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.49 8.024c.3.97 1.145 1.7 2.177 1.83v1.257a3.878 3.878 0 01-3.34-2.623l1.164-.464zM1.75 7a5.25 5.25 0 004.917 5.24v1.502a6.75 6.75 0 116.876-8.409H11.98A5.252 5.252 0 001.75 7zm2.375-.75a.875.875 0 100-1.75.875.875 0 000 1.75zm6.625-.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0zM6.208 4.333V8.6h1.25V4.333h-1.25zm7.042 6.917a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM9.5 12.771V9.73L12 8.244l2.5 1.485v3.042L12 14.255l-2.5-1.484zM8 8.875L12 6.5l4 2.375v4.75L12 16l-4-2.375v-4.75z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.152 12.49A4.134 4.134 0 0010 15.002v1.787a5.884 5.884 0 01-4.48-3.669l1.632-.632zM3 11a8.001 8.001 0 007 7.938v2.013C4.947 20.449 1 16.185 1 11 1 5.477 5.477 1 11 1c4.478 0 8.268 2.943 9.542 7H18.42A8.003 8.003 0 003 11zm3.75-1.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm2.625 3.15V6.5h1.75v6.4h-1.75zm5.875-3.15a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM20 17a2 2 0 11-4 0 2 2 0 014 0zm-6 2.351V14.65l4-2.334 4 2.334v4.702l-4 2.334-4-2.334zM12 13.5l6-3.5 6 3.5v7L18 24l-6-3.5v-7z"
    }));
  })());
};
UserCog.displayName = 'UserCog';
UserCog.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const UserPlus = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15 4.25C9.063 4.25 4.25 9.063 4.25 15S9.063 25.75 15 25.75c1.414 0 2.764-.273 4-.769v2.654c-1.262.4-2.606.615-4 .615-7.318 0-13.25-5.932-13.25-13.25S7.682 1.75 15 1.75 28.25 7.682 28.25 15c0 1.394-.215 2.738-.614 4H24.98c.496-1.236.769-2.586.769-4 0-5.937-4.813-10.75-10.75-10.75zM12.875 9v8.125H17.5v-2.25h-2.375V9h-2.25zM11 11.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zm9.75 1.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm-10.477 8.108a8.125 8.125 0 01-2.908-3.83l2.114-.769a5.875 5.875 0 0010.957.22l2.081.854a8.125 8.125 0 01-12.244 3.526zM25.75 22v3.75H22v2.5h3.75V32h2.5v-3.75H32v-2.5h-3.75V22h-2.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.5 2.25a5.25 5.25 0 102 10.106v1.593A6.75 6.75 0 1113.949 9.5h-1.593A5.25 5.25 0 007.5 2.25zM6.625 4.5v4.125H9v-1.25H7.875V4.5h-1.25zM5.5 5.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0zm4.875.875a.875.875 0 100-1.75.875.875 0 000 1.75zm-5.262 4.114a4.125 4.125 0 01-1.49-1.953l1.175-.428a2.875 2.875 0 005.379.066l1.164.456a4.125 4.125 0 01-6.228 1.86zM12.75 11v1.75H11v1.5h1.75V16h1.5v-1.75H16v-1.5h-1.75V11h-1.5z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M21 11c0 1.045-.16 2.053-.458 3H18.42c.375-.926.581-1.94.581-3a8 8 0 10-5 7.419v2.123A9.997 9.997 0 0111 21C5.477 21 1 16.523 1 11S5.477 1 11 1s10 4.477 10 10z"
    }), React.createElement("path", {
      d: "M9.375 6.5h1.75v4.625h1.625v1.75H9.375V6.5z"
    }), React.createElement("path", {
      d: "M8.658 14.395a4.125 4.125 0 01-1.504-1.903l-1.631.632a5.875 5.875 0 0010.924.077l-1.622-.656a4.124 4.124 0 01-6.167 1.85z"
    }), React.createElement("path", {
      d: "M8 8.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M15.25 9.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }), React.createElement("path", {
      d: "M19 16v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z"
    }));
  })());
};
UserPlus.displayName = 'UserPlus';
UserPlus.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const UserPlusAlt = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17.5 9.75a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zm2.75-5.25a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM5.5 12V8H8v4h4v2.5H8v4H5.5v-4h-4V12h4zm14.75 7c-2.782 0-5.5 2.2-5.5 5.416V24.5h11V24.417c0-3.216-2.718-5.417-5.5-5.417zm-8 5.416c0-4.784 4.032-7.916 8-7.916s8 3.132 8 7.917V27h-16V24.417z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9 4a1.25 1.25 0 112.5 0A1.25 1.25 0 019 4zm1.25-2.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM3 6V4.25h1.5V6h1.75v1.5H4.5v1.75H3V7.5H1.25V6H3zm4.501 5.5H13C12.95 10.039 11.645 9 10.25 9s-2.7 1.039-2.749 2.5zM6 11.583C6 9.088 8.171 7.5 10.25 7.5s4.25 1.589 4.25 4.083V13H6v-1.416z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.25 7a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 100 8 4 4 0 000-8zM4 8.75v-3h2v3h3v2H6v3H4v-3H1v-2h3zM9.31 18h9.88c-.091-.73-.303-1.523-.75-2.2-.614-.93-1.777-1.8-4.19-1.8-2.413 0-3.576.87-4.19 1.8-.447.677-.66 1.47-.75 2.2zm-.92-3.301C9.426 13.129 11.263 12 14.25 12s4.824 1.13 5.86 2.7c.986 1.494 1.14 3.231 1.14 4.3v1h-14v-1c0-1.07.154-2.807 1.14-4.301z"
    }));
  })());
};
UserPlusAlt.displayName = 'UserPlusAlt';
UserPlusAlt.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const UserSecurity = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15 4.25C9.063 4.25 4.25 9.063 4.25 15c0 5.826 4.634 10.569 10.416 10.745v2.5C7.503 28.07 1.75 22.207 1.75 15 1.75 7.682 7.682 1.75 15 1.75c7.206 0 13.069 5.753 13.246 12.917h-2.501C25.569 8.884 20.825 4.25 15 4.25zm8.725 24.695a7.936 7.936 0 01-3.932-7.148L24 20.178l4.207 1.619a7.936 7.936 0 01-3.932 7.148l-.275.16-.275-.16zM17.5 20l6.5-2.5 6.5 2.5c.897 4.383-1.1 8.85-4.965 11.105L24 32l-1.535-.895A10.435 10.435 0 0117.5 20zm-4.625-4V9h2.25v5.875H17.5v2.25h-4.625V16zM9.25 13.5a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm13.25-1.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM10.653 21.864c-1.3-.823-2.34-1.999-2.998-3.39l2.034-.962A5.875 5.875 0 0015 20.875v2.25a8.125 8.125 0 01-4.347-1.26z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7.5 2.25a5.25 5.25 0 00-.167 10.497v1.501a6.75 6.75 0 116.915-6.915h-1.5A5.25 5.25 0 007.5 2.25zm4.489 12.007a3.718 3.718 0 01-1.845-3.186L12 10.357l1.856.714a3.718 3.718 0 01-1.845 3.186l-.011.006-.011-.006zM8.75 10L12 8.75 15.25 10a5.218 5.218 0 01-2.483 5.552L12 16l-.767-.448A5.218 5.218 0 018.75 10zM6.375 8V4.5h1.25v2.875H8.75v1.25H6.375V8zm-1.75-1.25a.875.875 0 100-1.75.875.875 0 000 1.75zm6.625-.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0zm-5.957 5.11a4.125 4.125 0 01-1.522-1.721l1.13-.535A2.875 2.875 0 007.5 10.375v1.25a4.125 4.125 0 01-2.207-.64z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M10 18.938A8.001 8.001 0 0111 3a8 8 0 018 8h2c0-5.523-4.477-10-10-10S1 5.477 1 11c0 5.185 3.947 9.449 9 9.95v-2.012z"
    }), React.createElement("path", {
      d: "M10 15.002a4.123 4.123 0 01-2.846-2.51l-1.631.632A5.875 5.875 0 0010 16.79v-1.787z"
    }), React.createElement("path", {
      d: "M8 8.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
    }), React.createElement("path", {
      d: "M9.375 6.5v6.4h1.75V6.5h-1.75z"
    }), React.createElement("path", {
      d: "M15.25 9.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19.499 23.135L18 24l-1.499-.865A7 7 0 0113 17.072v-1.728L18 13l5 2.344v1.728a7 7 0 01-3.501 6.063zM18 15.21l3 1.407v.456a5 5 0 01-2.501 4.33l-.499.289-.499-.288A5 5 0 0115 17.073v-.457l3-1.407z"
    }));
  })());
};
UserSecurity.displayName = 'UserSecurity';
UserSecurity.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Wallet = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2 8a4 4 0 014-4h20a4 4 0 014 4v16a4 4 0 01-4 4H6a4 4 0 01-4-4V8zm4-1.5A1.5 1.5 0 004.5 8v1h23V8A1.5 1.5 0 0026 6.5H6zm21.5 5h-23V14H13v1.5a3 3 0 106 0V14h8.5v-2.5zm0 5h-6.09a5.502 5.502 0 01-10.82 0H4.5V24A1.5 1.5 0 006 25.5h20a1.5 1.5 0 001.5-1.5v-7.5z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1 4.5a2 2 0 012-2h10a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2v-7zM3 4a.5.5 0 00-.5.5v1h4.25v1.25a1.25 1.25 0 102.5 0V5.5h4.25v-1A.5.5 0 0013 4H3zm10.5 3h-2.761A2.75 2.75 0 015.26 7H2.5v4.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V7z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 5.5H5a1 1 0 00-1 1V7h16v-.5a1 1 0 00-1-1zm-15 5V9h16v1.5h-6v1a2 2 0 11-4 0v-1H4zm0 2v5a1 1 0 001 1h14a1 1 0 001-1v-5h-4.126a4.002 4.002 0 01-7.748 0H4zm1-9a3 3 0 00-3 3v11a3 3 0 003 3h14a3 3 0 003-3v-11a3 3 0 00-3-3H5z"
    }));
  })());
};
Wallet.displayName = 'Wallet';
Wallet.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Wand = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22.25 3.85V0h-2.5v3.85h2.5zm5.417 1.412l2.62-2.75L28.475.788l-2.62 2.75 1.811 1.724zm.666 6.988H32v-2.5h-3.667v2.5zm-5.899.08l-3.174 3.037-2.701-2.7 3.118-3.122 2.757 2.786zm-4.954 4.793l-2.691-2.691L4.53 24.634l2.78 2.808 10.169-10.32zm-3.573-5.34L1 24.62l4.556 4.602L7.315 31l1.755-1.78L20.125 18l4.068-3.892L26 12.379l-1.759-1.777-2.797-2.825L19.685 6l-1.767 1.769-4.01 4.014z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.75 2.333V0h-1.5v2.333h1.5zm3.447.864l1.667-1.667L13.803.47l-1.667 1.666 1.061 1.061zm.47 4.22H16v-1.5h-2.333v1.5zm-4.864.659l-.879-.879 1.41-1.409.878.879-1.409 1.409zm-.53-3.349l-1.41 1.41-.53.53-.53.53-4.409 4.41-1.06 1.06 1.06 1.06.879.88 1.06 1.06 1.061-1.06 4.41-4.41.53-.53.53-.53 1.409-1.41 1.06-1.06-1.06-1.06-.879-.88-1.06-1.06-1.061 1.06zm-1.41 3.53l-4.408 4.41.878.878 4.41-4.409-.88-.878z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("g", {
      clipPath: "url(#clip0_3780_7916)"
    }, React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.5 3.5V0h-2v3.5h2zm5.207 1.207l2.5-2.5L20.793.793l-2.5 2.5 1.414 1.414zM20.5 11H24V9h-3.5v2zm-6.707.879l-1.672-1.672L14 8.328 15.672 10l-1.88 1.879zm-1.207-4.965l-1.879 1.879L10 9.5l-.707.707-7.379 7.379L.5 19l1.414 1.414 1.672 1.672L5 23.5l1.414-1.414 7.379-7.379L14.5 14l.707-.707 1.879-1.879L18.5 10l-1.414-1.414-1.672-1.672L14 5.5l-1.414 1.414zm-1.879 4.707L3.328 19 5 20.672l7.379-7.38-1.672-1.67z"
    })), React.createElement("defs", null, React.createElement("clipPath", {
      id: "clip0_3780_7916"
    }, React.createElement("rect", {
      width: "24",
      height: "24"
    }))));
  })());
};
Wand.displayName = 'Wand';
Wand.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Warning = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.5 28h29l-1.436-2.5L17.442 5.26 16 2.75l-1.441 2.51L2.936 25.5 1.5 28zm4.319-2.5h20.363L16 7.77 5.819 25.5zm8.931-6.167v-6h2.5v6h-2.5zM16 24a1.667 1.667 0 100-3.333A1.667 1.667 0 0016 24z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.655 1.172L8 0l-.655 1.172-6.65 11.9-.623 1.115H15.928l-.623-1.115-6.65-11.9zM2.628 12.687L8 3.075l5.372 9.612H2.628zM7.25 9.59V6.037h1.5V9.59h-1.5zM9 11.09a1 1 0 11-2 0 1 1 0 012 0z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M11 10h2v4.5h-2V10z"
    }), React.createElement("path", {
      d: "M12 18a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M23 21L12 2 1 21h22zM4.469 19H19.53L12 5.992 4.469 19z"
    }));
  })());
};
Warning.displayName = 'Warning';
Warning.defaultProps = {
  color: 'currentColor',
  size: 24
};

// Generated file: DO NOT EDIT MANUALLY.
const Wine = ({
  color,
  size,
  ...props
}) => {
  let viewBox;
  if (size === 16) {
    viewBox = '0 0 16 16';
  } else if (size === 32) {
    viewBox = '0 0 32 32';
  } else {
    viewBox = '0 0 24 24';
  }
  return React.createElement(Icon, {
    width: size,
    height: size,
    viewBox: viewBox,
    fill: color,
    role: "presentation",
    ...props,
    "data-telescope": "icon"
  }, (() => {
    if (size === 32) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17 3H8v6.333c0 2.183-.627 3.375-1.355 4.749l-.005.01C5.869 15.549 5 17.189 5 20v9.5h8V27H7.5v-7c0-2.183.627-3.375 1.355-4.748l.005-.01c.771-1.458 1.64-3.098 1.64-5.909V5.5h4v3.833H17V3zm11.25 9H15v4c0 3.26 2.194 5.834 5.25 6.389V27h-4v2.5H27V27h-4.25v-4.61c3.057-.547 5.5-3.068 5.5-6.39v-4zM17.5 16v-1.5h8.25V16c0 2.235-1.863 4-4.25 4-2.31 0-4-1.69-4-4z"
      }));
    } else if (size === 16) {
      return React.createElement(React.Fragment, null, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.75 1H4v3.667c0 1.06-.301 1.633-.663 2.315l-.003.006C2.946 7.721 2.5 8.564 2.5 10v5h4.167v-1.5H4V10c0-1.06.301-1.633.663-2.316l.003-.006c.388-.732.834-1.575.834-3.011V2.5h2v2.167H9V1H4.75zm3 5H7v2c0 1.68 1.207 3.008 2.75 3.338V13.5H8V15h5v-1.5h-1.75v-2.162C12.793 11.008 14 9.68 14 8V6H7.75zm.75 2v-.5h4V8c0 1.056-.884 1.917-2 1.917s-2-.86-2-1.917z"
      }));
    }
    return React.createElement(React.Fragment, null, React.createElement("path", {
      d: "M13 2H6v5.111a7 7 0 01-.739 3.131l-.31.622A9 9 0 004 14.888V22h6v-2H6v-5.111a7 7 0 01.739-3.131l.31-.622A9 9 0 008 7.111V4h3v3h2V2z"
    }), React.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M21 12a5.002 5.002 0 01-4 4.9V20h3v2h-8v-2h3v-3.1a5.002 5.002 0 01-4-4.9V9h10v3zm-8-1v1a3 3 0 106 0v-1h-6z"
    }));
  })());
};
Wine.displayName = 'Wine';
Wine.defaultProps = {
  color: 'currentColor',
  size: 24
};

export { AddApp, Archive, ArrowCircleLeft, ArrowCircleRight, ArrowDiagonalDown, ArrowDiagonalUp, ArrowDown, ArrowLeft, ArrowLeftRight, ArrowRight, ArrowUp, ArrowUpDown, Attention, Bag, Bank, BankPlus, Bars, Bell, BellOff, BikeCourier, Bold, Box, BurgerMenu, Calculator, Calendar, CalendarClock, CalendarRange, CalendarToday, Camera, CameraPlus, Car, CashSingle, CategoryEntertainment, CategoryEquipment, CategoryIDonTKnow, CategoryMarketing, CategoryMealsAndDrinks, CategoryNone, CategoryOffice, CategoryOther, CategoryPhoneInternet, CategoryService, CategorySoftware, CategorySupplies, CategoryTravel, Check, CheckCircleFilled, CheckSmall, ChevronDown, ChevronLeft, ChevronRight, ChevronTripleRight, ChevronUp, CircleArrowDown, CircleArrowLeft, CircleArrowRight, CircleArrowUp, CircleCheck, CircleCheckInner, CircleClose, CircleCloseInner, CircleEmpty, CirclePlus, Clock, Close, Coffee, CoffeeAlt, Cog, Connect, Copy, CreditCard, CreditCardPlus, Delete, DiamondEmpty, DiamondFull, DiamondHalf, Download, Email, EmotionDisappointed, EmotionExpressionless, EmotionHushed, EmotionSlightlySmiling, EmotionSmiley, Expenses, Export, Eye, EyeClosed, FaceId, FastFood, File, Filter, Fingerprint, Flag, FlashCircle, FlashOff, FlashOn, Folder, Gallery, GalleryAlt, Heart, Help, HighFive, History, Home, HomeFilled, Icon, Inbox, Info, Italic, Key, Lightbulb, Link, LinkSlash, List, ListNumbered, Lock, LockOpen, LockReset, Login, Logout, Minus, MinusCircle, NewTab, Office, Options, Pen, PenUnderline, PerDiem, Pin, Play, PlayCircle, Plus, PlusCircleFilled, Pocket, Puzzle, RaisedHand, Receipt, ReceiptCheck, ReceiptCross, ReceiptPlus, Reimburse, Rocket, Sandwich, Search, SendMoney, Share, Shield, ShieldLock, ShoppingBag, Snowflake, SpeechBubble, Spinner, Spreadsheet, Store, Swap, Tag, Team, ThumbDown, ThumbUp, Trash, TrashClock, Truck, Upload, User, UserCog, UserPlus, UserPlusAlt, UserSecurity, Wallet, Wand, Warning, Wine };
