import {createGlobalStyle} from 'styled-components'

import tokens from '@pleo-io/telescope-tokens'
import {__classNameColorSchemeDark} from '@pleo-io/telescope-tokens'

export type CustomColorSchemeToken = {light: string | number; dark: string | number}

export const customColorSchemeTokens = {
    colorBackgroundActiveIndicator: 'var(--colorBackgroundActiveIndicator)',
    colorBackgroundInactiveIndicator: 'var(--colorBackgroundInactiveIndicator)',
    colorBackgroundModalSplitIllustrationPink: 'var(--colorBackgroundModalSplitIllustrationPink)',
    colorBackgroundLoading: 'var(--colorBackgroundLoading)',
    colorBackgroundProgressBarShade300: 'var(--colorBackgroundProgressBarShade300)',
    colorContentProgressBarShade900: 'var(--colorContentProgressBarShade900)',
    colorContentProgressBarPink300: 'var(--colorContentProgressBarPink300)',
    colorContentTextShade600: 'var(--colorContentTextShade600)',
    colorContentTextShade700: 'var(--colorContentTextShade700)',
    colorContentTextShade800: 'var(--colorContentTextShade800)',
    colorBackgroundDatePickerActiveTile: 'var(--colorBackgroundDatePickerActiveTile)',
    colorBackgroundSwitchTrackOn: 'var(--colorBackgroundSwitchTrackOn)',
    colorBackgroundSwitchTrackOff: 'var(--colorBackgroundSwitchTrackOff)',
    colorBackgroundSwitchTrackDisabled: 'var(--colorBackgroundSwitchTrackDisabled)',
    colorBackgroundSwitchThumbDisabled: 'var(--colorBackgroundSwitchThumbDisabled)',
    colorContentSwitchThumbDisabled: 'var(--colorContentSwitchThumbDisabled)',
    colorBackgroundSkeletonFrom: 'var(--colorBackgroundSkeletonFrom)',
    colorBackgroundSkeletonTo: 'var(--colorBackgroundSkeletonTo)',
    colorBackgroundSkeletonFromLoud: 'var(--colorBackgroundSkeletonFromLoud)',
    colorBackgroundSkeletonToLoud: 'var(--colorBackgroundSkeletonToLoud)',
    colorBackgroundModalSplitCloseHover: 'var(--colorBackgroundModalSplitCloseHover)'
} as const

type Data = Record<keyof typeof customColorSchemeTokens, CustomColorSchemeToken>
const customColorSchemeTokensData: Data = {
    colorBackgroundActiveIndicator: {light: tokens.pink500, dark: tokens.pink500},
    colorBackgroundInactiveIndicator: {light: tokens.shade300, dark: tokens.shade300},
    colorBackgroundModalSplitIllustrationPink: {light: tokens.pink200, dark: tokens.pink800},
    colorBackgroundLoading: {light: tokens.pink500, dark: tokens.pink800},
    colorBackgroundProgressBarShade300: {light: tokens.shade300, dark: tokens.shade700},
    colorContentProgressBarShade900: {light: tokens.shade900, dark: tokens.shade000},
    colorContentProgressBarPink300: {light: tokens.pink300, dark: tokens.pink800},
    colorContentTextShade600: {light: tokens.shade600, dark: tokens.shade500},
    colorContentTextShade700: {light: tokens.shade700, dark: tokens.shade200},
    colorContentTextShade800: {light: tokens.shade800, dark: tokens.shade100},
    colorBackgroundDatePickerActiveTile: {light: tokens.shade200, dark: tokens.shade700},
    colorBackgroundSwitchTrackOn: {light: tokens.green800, dark: tokens.green600},
    colorBackgroundSwitchTrackOff: {light: tokens.shade600, dark: tokens.shade300},
    colorBackgroundSwitchTrackDisabled: {light: tokens.shade300, dark: tokens.shade700},
    colorContentSwitchThumbDisabled: {light: tokens.shade000, dark: tokens.shade900},
    colorBackgroundSwitchThumbDisabled: {light: tokens.shade500, dark: tokens.shade600},
    colorBackgroundSkeletonFrom: {light: tokens.shade200, dark: tokens.shade700},
    colorBackgroundSkeletonTo: {light: tokens.shade300, dark: tokens.shade600},
    colorBackgroundSkeletonFromLoud: {light: tokens.shade400, dark: tokens.shade600},
    colorBackgroundSkeletonToLoud: {light: tokens.shade500, dark: tokens.shade500},
    colorBackgroundModalSplitCloseHover: {light: tokens.shade000, dark: tokens.shade900}
} as const

export const createCustomColorSchemeTokensGlobalStyle = (
    data: Record<string, CustomColorSchemeToken>
) => createGlobalStyle`
:root {
    ${Object.keys(data).map((name) => `--${name}: ${data[name].light};`)}

    .${__classNameColorSchemeDark} {
        ${Object.keys(data).map((name) => `--${name}: ${data[name].dark};`)}
    }
}
`

export const CustomColorSchemeTokensGlobalStyle = createCustomColorSchemeTokensGlobalStyle(
    customColorSchemeTokensData
)
