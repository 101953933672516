import type {MDXProviderComponentsProp} from '@mdx-js/react'
import {MDXProvider} from '@mdx-js/react'
import React from 'react'
import styled, {ThemeProvider} from 'styled-components'

import {
    TelescopeProvider,
    __dummyTelescopeProviderTranslations,
    theme,
    tokens,
    Text
} from '@pleo-io/telescope'
import {GlobalStyle} from '@pleo-io/telescope-global-style'

import favicon from '@/assets/favicon.png'
import {CustomColorSchemeTokensGlobalStyle, paddingContentSmall} from '@/tokens'

import 'focus-visible'

import '@reach/skip-nav/styles.css'

import {GlobalDocsStyles} from './global-styles'
import {TelescopeMDXComponents} from './mdx-components'
import type {PageContext} from './types'

import {Helmet} from '../../utilities/helmet'
import {FeatureTogglerProvider} from '../feature-toggler/feature-toggler'
import {ThemeSwitcherProvider} from '../theme-switcher/theme-switcher'

const Providers = ({
    children,
    components
}: {
    children: React.ReactNode
    components: MDXProviderComponentsProp
}) => (
    <TelescopeProvider translations={__dummyTelescopeProviderTranslations}>
        <FeatureTogglerProvider>
            <ThemeSwitcherProvider>
                <ThemeProvider theme={theme}>
                    <MDXProvider components={components}>{children}</MDXProvider>
                </ThemeProvider>
            </ThemeSwitcherProvider>
        </FeatureTogglerProvider>
    </TelescopeProvider>
)

const PageWrapper = styled.div`
    background-color: ${tokens.colorBackgroundStaticPage};
    margin: 0 auto;
    padding: ${paddingContentSmall};
`

export const FigmaLayout = ({
    children,
    pageContext,
    components = TelescopeMDXComponents
}: {
    children: React.ReactNode
    pageContext: PageContext
    components?: MDXProviderComponentsProp
}) => {
    const title = pageContext?.frontmatter?.title || ''

    return (
        <>
            <Helmet>
                <title>Telescope Design System</title>
                <link rel="icon" href={favicon} type="image/x-icon" />
                <html lang="en-GB" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <GlobalStyle />
            <CustomColorSchemeTokensGlobalStyle />
            <GlobalDocsStyles />
            <Providers components={components}>
                <PageWrapper>
                    <Text variant="4xlarge-accent" as="h1" weight="bold">
                        {title}
                    </Text>
                    {children}
                </PageWrapper>
            </Providers>
        </>
    )
}
